import { Configuration, RedirectRequest } from "@azure/msal-browser";

/**
 * Global MSAL configuration
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: DetermineClientId(),
    authority: "https://login.microsoftonline.com/20316ca8-6512-41f7-9734-485fb756949b",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: true
  }
};

/**
 * Get's the corresponding api url based on the environment
 * @constructor
 */
export function DetermineApiUri(): string {
  // Local development uses it's own app registration for local return url's
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_BACKEND_URL ?? "https://localhost:5003";
  }

  const hostname = window.location.hostname;
  if (hostname.includes("test"))
    return "https://oiv-api-test.vrmwb-services.nl";
  if (hostname.includes("acc") )
    return "https://oiv-api-acc.vrmwb-services.nl";
  else if (hostname.includes("staging"))
    return "https://oiv-api-staging.vrmwb-services.nl";

  // Default the client id of the test environment will be used
  return "https://oiv-api.vrmwb-services.nl";
}

// Determine the client id for authentication.
export function DetermineClientId(): string {
  // Local development uses it's own app registration for local return url's
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || window.location.hostname.includes("localhost")) {
    return process.env.REACT_APP_CLIENT_ID ?? "68e7d87e-e4eb-411d-ab5d-be46781da1f4";
  }

  const hostname = window.location.hostname;
  if (hostname.includes("test"))
    return "68e7d87e-e4eb-411d-ab5d-be46781da1f4";
  if (hostname.includes("acc") )
    return "d0991b90-8272-47ef-b181-361b01e248a4";
  else if (hostname.includes("staging"))
    return "c18f99b9-38e3-462a-95c6-ca1a527bf5ad";

  return "962b4543-b4f0-4bac-9561-7cc8bcd3e899";
}

export function DetermineApplicationInsightsConnectionString(): string{
  // Local development uses it's own app registration for local return url's
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || window.location.hostname.includes("localhost")) {
    return "InstrumentationKey=33e0435b-2004-49a5-b951-6daff204f4ad;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/";
  }

  const hostname = window.location.hostname;
  if (hostname.includes("test"))
    return "InstrumentationKey=33e0435b-2004-49a5-b951-6daff204f4ad;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/";
  if (hostname.includes("acc") )
    return "InstrumentationKey=75ce29eb-1ce5-45e4-b4cf-e488f51c97f1;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/";
  else if (hostname.includes("staging"))
    return "InstrumentationKey=93019023-6d96-4323-9f43-29d3ea23ce7b;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/";

  return "InstrumentationKey=68ce5356-9563-4f9b-9310-473a82efeedc;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/";
}
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["user.read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const backendRequest = {
  scopes: ["api://596753b8-20cc-4e9e-a084-412cb4d79f12/access_as_user"]
};