import React from "react";
import { SvgIcon } from "@mui/material";
import SvgClearDay from "./Icons/ClearDay";
import SvgPartlyCloudyDay from "./Icons/PartlyCloudyDay";
import SvgThermometerCelsius from "./Icons/ThermometerCelsius";
import SvgHazeDay from "./Icons/HazeDay";
import SvgOvercast from "./Icons/Overcast";
import SvgRain from "./Icons/Rain";
import SvgCloudy from "./Icons/Cloudy";
import SvgCompass from "./Icons/Compass";
import SvgWindBeaufort0 from "./Icons/WindBeaufort0";
import SvgWindBeaufort1 from "./Icons/WindBeaufort1";
import SvgWindBeaufort2 from "./Icons/WindBeaufort2";
import SvgWindBeaufort3 from "./Icons/WindBeaufort3";
import SvgWindBeaufort4 from "./Icons/WindBeaufort4";
import SvgWindBeaufort5 from "./Icons/WindBeaufort5";
import SvgWindBeaufort6 from "./Icons/WindBeaufort6";
import SvgWindBeaufort7 from "./Icons/WindBeaufort7";
import SvgWindBeaufort8 from "./Icons/WindBeaufort8";
import SvgWindBeaufort9 from "./Icons/WindBeaufort9";
import SvgWindBeaufort10 from "./Icons/WindBeaufort10";
import SvgWindBeaufort11 from "./Icons/WindBeaufort11";
import SvgWindBeaufort12 from "./Icons/WindBeaufort12";
import SvgFog from "./Icons/Fog";
import SvgDrizzle from "./Icons/Drizzle";
import SvgPartlyCloudyDayDrizzle from "./Icons/PartlyCloudyDayDrizzle";
import SvgThunderstorms from "./Icons/Thunderstorms";
import SvgThunderstormsDay from "./Icons/ThunderstormsDay";
import SvgSnow from "./Icons/Snow";
import SvgPartlyCloudyDaySnow from "./Icons/PartlyCloudyDaySnow";
import SvgHail from "./Icons/Hail";
import SvgSleet from "./Icons/Sleet";
import SvgClearNight from "./Icons/ClearNight";
import SvgPartlyCloudyNight from "./Icons/PartlyCloudyNight";
import SvgHazeNight from "./Icons/HazeNight";
import SvgPartlyCloudyNightDrizzle from "./Icons/PartlyCloudyNightDrizzle";
import SvgThunderstormsNight from "./Icons/ThunderstormsNight";
import SvgOvercastDaySleet from "./Icons/OvercastDaySleet";
import SvgPartlyCloudyNightSleet from "./Icons/PartlyCloudyNightSleet";
import SvgPartlyCloudyNightSnow from "./Icons/PartlyCloudyNightSnow";
import SvgPartlyCloudyDayRain from "./Icons/PartlyCloudyDayRain";
import SvgPartlyCloudyNightRain from "./Icons/PartlyCloudyNightRain";
import SvgUvIndex from "./Icons/UvIndex";
import SvgUvIndex1 from "./Icons/UvIndex1";
import SvgUvIndex2 from "./Icons/UvIndex2";
import SvgUvIndex3 from "./Icons/UvIndex3";
import SvgUvIndex4 from "./Icons/UvIndex4";
import SvgUvIndex5 from "./Icons/UvIndex5";
import SvgUvIndex6 from "./Icons/UvIndex6";
import SvgUvIndex7 from "./Icons/UvIndex7";
import SvgUvIndex8 from "./Icons/UvIndex8";
import SvgUvIndex9 from "./Icons/UvIndex9";
import SvgUvIndex10 from "./Icons/UvIndex10";
import SvgUvIndex11 from "./Icons/UvIndex11";
import { SxProps } from "@mui/system";
import { NavigationOutlined } from "@mui/icons-material";

const style = {
  width: "65px",
  height: "auto"
};

export const ClearDayIcon = () => (
  <SvgIcon sx={style}>
    <SvgClearDay/>
  </SvgIcon>
);

export const PartlySunnyIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyDay/>
  </SvgIcon>
);

export const ThermoMeterIcon = () => (
  <SvgIcon sx={style}>
    <SvgThermometerCelsius/>
  </SvgIcon>
);

export const HazySunshineIcon = () => (
  <SvgIcon sx={style}>
    <SvgHazeDay/>
  </SvgIcon>
);

export const OvercastIcon = () => (
  <SvgIcon sx={style}>
    <SvgOvercast/>
  </SvgIcon>
);

export const RainIcon = () => (
  <SvgIcon sx={style}>
    <SvgRain/>
  </SvgIcon>
);

export const CloudyIcon = () => (
  <SvgIcon sx={style}>
    <SvgCloudy/>
  </SvgIcon>
);

export const FogIcon = () => (
  <SvgIcon sx={style}>
    <SvgFog/>
  </SvgIcon>
);

export const DrizzleIcon = () => (
  <SvgIcon sx={style}>
    <SvgDrizzle/>
  </SvgIcon>
);

export const DrizzleDayIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyDayDrizzle/>
  </SvgIcon>
);

export const RainDayIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyDayRain/>
  </SvgIcon>
);

export const ThunderIcon = () => (
  <SvgIcon sx={style}>
    <SvgThunderstorms/>
  </SvgIcon>
);

export const ThunderDayIcon = () => (
  <SvgIcon sx={style}>
    <SvgThunderstormsDay/>
  </SvgIcon>
);

export const SnowIcon = () => (
  <SvgIcon sx={style}>
    <SvgSnow/>
  </SvgIcon>
);

export const SnowDayIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyDaySnow/>
  </SvgIcon>
);

export const SleetDayIcon = () => (
  <SvgIcon sx={style}>
    <SvgOvercastDaySleet/>
  </SvgIcon>
);

export const HailIcon = () => (
  <SvgIcon sx={style}>
    <SvgHail/>
  </SvgIcon>
);

export const SleetIcon = () => (
  <SvgIcon sx={style}>
    <SvgSleet/>
  </SvgIcon>
);

export const ClearNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgClearNight/>
  </SvgIcon>
);

export const CloudyNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyNight/>
  </SvgIcon>
);

export const HazeNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgHazeNight/>
  </SvgIcon>
);

export const DrizzleNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyNightDrizzle/>
  </SvgIcon>
);

export const RainNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyNightRain/>
  </SvgIcon>
);

export const ThunderNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgThunderstormsNight/>
  </SvgIcon>
);

export const SleetNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyNightSleet/>
  </SvgIcon>
);

export const SnowNightIcon = () => (
  <SvgIcon sx={style}>
    <SvgPartlyCloudyNightSnow/>
  </SvgIcon>
);

interface CompassProps {
  direction: number,
  customStyle?: SxProps
}

export const CompassIcon = ({ direction, customStyle } : CompassProps) => (
  <SvgIcon sx={{transform: `rotate(${direction}deg)`, ...style, ...customStyle}}>
    <SvgCompass/>
  </SvgIcon>
);

interface NavigationProps {
  direction: number,
  customStyle?: SxProps
}

export const NavigationIcon = ({ direction, customStyle } : NavigationProps) => (
  <SvgIcon sx={{transform: `rotate(${direction}deg)`, ...style, ...customStyle}}>
    <NavigationOutlined/>
  </SvgIcon>
);


interface UvIndexProps {
  uvIndex: number
}

export const UvIndexIcon = ({ uvIndex}: UvIndexProps) => 
  <SvgIcon sx={style}>
    {UvIndexToIcon(uvIndex)}
  </SvgIcon>;

function UvIndexToIcon(uvIndex: number){
  switch(uvIndex){
    case 1:
      return <SvgUvIndex1/>;
    case 2:
      return <SvgUvIndex2/>;
    case 3:
      return <SvgUvIndex3/>;
    case 4:
      return <SvgUvIndex4/>;
    case 5:
      return <SvgUvIndex5/>;
    case 6:
      return <SvgUvIndex6/>;
    case 7:
      return <SvgUvIndex7/>;
    case 8:
      return <SvgUvIndex8/>;
    case 9:
      return <SvgUvIndex9/>;
    case 10:
      return <SvgUvIndex10/>;
    case 11:
      return <SvgUvIndex11/>;

    default:
      return <SvgUvIndex/>;
  }
}


interface WindIntesityProps {
  speed: number
}

export const WindIntensityIcon = ({ speed }: WindIntesityProps) => 
  <SvgIcon sx={style}>
    {WindSpeedToIcon(speed)}
  </SvgIcon>;

function WindSpeedToIcon(speed: number){
  switch(true) {
    case speed < 1 :
      return <SvgWindBeaufort0 />;
    case speed < 5:
      return <SvgWindBeaufort1 />;
    case speed < 11:
      return <SvgWindBeaufort2 />;
    case speed < 19:
      return <SvgWindBeaufort3 />;
    case speed < 28:
      return <SvgWindBeaufort4 />;
    case speed < 38:
      return <SvgWindBeaufort5 />;
    case speed < 49:
      return <SvgWindBeaufort6 />;
    case speed < 61:
      return <SvgWindBeaufort7 />;
    case speed < 74:
      return <SvgWindBeaufort8 />;
    case speed < 88:
      return <SvgWindBeaufort9 />;
    case speed < 102:
      return <SvgWindBeaufort10 />;
    case speed < 117:
      return <SvgWindBeaufort11 />;
    case speed < 132:
      return <SvgWindBeaufort12 />;
    default:
      return <SvgWindBeaufort12 />;      
  }
}

interface WeatherIconProps {
  iconCode: number
}

export const WeatherIcon = ({ iconCode } : WeatherIconProps) =>
  <SvgIcon sx={style}>
    {IconCodeToWeatherIcon(iconCode)}
  </SvgIcon>;

//Icon codes are documented 
//https://docs.microsoft.com/en-us/azure/azure-maps/weather-services-concepts
function IconCodeToWeatherIcon(iconCode: number) {
  switch (iconCode) {
    case 1:
      return <ClearDayIcon/>;
    case 2:
    case 3:
    case 4:
    case 6:
      return <PartlySunnyIcon/>;
    case 5:
      return <HazySunshineIcon/>;
    case 7:
      return <CloudyIcon/>;
    case 8:
      return <OvercastIcon/>;
    case 11:
      return <FogIcon/>;
    case 12:
      return <DrizzleIcon/>;
    case 13:
      return <DrizzleDayIcon/>;
    case 14:
      return <RainDayIcon/>;
    case 15:
      return <ThunderIcon/>;
    case 16:
    case 17:
      return <ThunderDayIcon/>;
    case 18:
      return <RainIcon/>;
    case 19:
    case 25:
    case 26:
    case 29:
      return <SleetIcon/>;
    case 20:
    case 21:
      return <SleetDayIcon/>;
    case 22:
      return <SnowIcon/>;
    case 23:
      return <SnowDayIcon/>;
    case 24:
      return <HailIcon/>;
    case 33:
      return <ClearNightIcon/>;
    case 34:
    case 35:
    case 36:
    case 38:
      return <CloudyNightIcon/>;
    case 37:
      return <HazeNightIcon/>;
    case 39:
      return <DrizzleNightIcon/>;
    case 40:
      return <RainNightIcon/>;
    case 41:
    case 42:
      return <ThunderNightIcon/>;
    case 43:
      return <SleetNightIcon/>;
    case 44:
      return <SnowNightIcon/>;

    default:
      return <ClearDayIcon/>;
  }
}