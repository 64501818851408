interface Props {
    readonly data: Record<string, string>[];
}

export function DuikenPopupTemplate({ data}: Props){
  return(
    <div className="duiken-popup">
      <table>
        <tr><td>{data[0]["typevoorzi"]}</td></tr>
      </table>
    </div>
  );
}