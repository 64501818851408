import {useMap} from "../../contexts/mapContext";
import mapboxgl, {GeoJSONSource} from "mapbox-gl";

export const searchResultLayerSourceId = "search-result";

/**
 * Map layer for adding search markers
 * @constructor
 */
export function SearchResultLayer() {
  useMap(map => {
    AddLayer(map);

    map.on("style.load", () => {
      AddLayer(map);
    });
  },[]);

  return <></>;

  /**
   * Add source to the map
   * @param map
   * @constructor
   */
  function AddSource(map: mapboxgl.Map){
    if(hasSource(map, "search-result"))
      return;

    if(map.getSource("search-result"))
      return;

    map.addSource("search-result", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: true,
      clusterRadius: 40
    });

    const source = map.getSource("search-result") as GeoJSONSource;
    source.setData({
      type: "FeatureCollection",
      features: []
    });
  }

  /**
   * Add icon to the map
   * @param map
   * @constructor
   */
  function AddIcon(map: mapboxgl.Map) {
    if(map.hasImage("search-result-marker"))
      return;

    map.loadImage("/assets/images/icons/search-result-marker.png", (error, image) => {
      if (error) throw error;

      if (!image) return;

      // Load vehicle image
      if (!map.hasImage("search-result-marker")){
        map.addImage("search-result-marker", image);
      }
    });
  }

  /**
   * Add layer to the map
   * @param map
   * @constructor
   */
  function AddLayer(map: mapboxgl.Map) {

    AddIcon(map);
    AddSource(map);

    if(map.getLayer("search-result"))
      return;

    map.addLayer({
      id: "search-result",
      type: "symbol",
      source: "search-result",
      layout: {
        "icon-image": "search-result-marker",
        "icon-size": 1,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
      }
    });
  }
}

/**
 * Checks if the source is found within mapbox
 * @param map Map instance of mapbox
 * @param sourceId sourceId
 */
function hasSource(map: mapboxgl.Map, sourceId: string) : boolean {
  try{
    if(map.getSource(sourceId) == undefined)
      return false;

    return map.isSourceLoaded(sourceId);
  } catch (e: unknown){
    // isSourceLoaded throws exceptions whenever the source is not found or isn't loaded.
    return false;
  }
}