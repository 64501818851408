import * as React from "react";
import { SVGProps } from "react";

const SvgClearNight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="clear-night_svg__a" viewBox="0 0 279 279">
        <path
          fill="none"
          stroke="#72b9d5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M256.8 173.1A133.3 133.3 0 0 1 122.4 40.7 130.5 130.5 0 0 1 127 7.5 133 133 0 0 0 7.5 139.1c0 73.1 60 132.4 134.2 132.4 62.5 0 114.8-42.2 129.8-99.2a135.6 135.6 0 0 1-14.8.8Z"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#clear-night_svg__a"
      width={279}
      height={279}
      transform="translate(116.5 116.5)"
    />
  </svg>
);

export default SvgClearNight;
