import { useIncident } from "app/hooks/useIncidents";
import { useRef, useEffect } from "react";
import { IncidentHeader } from "./components/IncidentHeader";
import { IncidentEenheden } from "./components/IncidentEenheden";
import { IncidentKarakteristieken } from "./components/IncidentKarakteristieken";
import { IncidentGerelateerd } from "./components/IncidentGerelateerd";
import PermissionsGate, { POLICY } from "app/components/PermissionsGate";
import { IncidentKladblokInput } from "./components/IncidentKladblokInput";
import { IncidentTimeLine } from "app/components/IncidentPanel/components/IncidentDetailView/components/IncidentTimeLine";
import { WazeButton } from "app/components/WazeButton";
import style from "./style.module.scss";
import { IncidentShortcuts } from "./components/IncidentShortcuts";
import { LoadingComponent } from "app/components/LoadingComponent";
import EventDispatcher, { CustomEvents } from "eventDispatcher";
import { CustomCyclomediaEvents } from "app/components/Cyclomedia/models/CustomCyclomediaEvents";
import { EenheidStatus } from "app/models/eenheidStatus";

type Props = {
  incidentId: number | undefined,
  onClose: () => void,
  toggleMinimized: () => void,
  isMinimized: boolean
};

/**
 * Component that displays the details of an incident
 */
export function IncidentDetailView({ incidentId, onClose, toggleMinimized, isMinimized }: Readonly<Props>) {
  const { incident, eenheden } = useIncident({ incidentId, keepRefreshing: true });
  const scrollToELement = useRef<HTMLElement | null>(null);
  const coordinates: [number, number] | undefined = (incident?.lat && incident?.lon) ? [incident.lat, incident.lon] : undefined;


  useEffect(() => {
    if (coordinates) {
      EventDispatcher.emit(CustomCyclomediaEvents.OpenLocation, { coordinate: { lat: coordinates[0], lng: coordinates[1] }, openFullscreen: false, mode: "floating" });
    }

    if (incident)
      EventDispatcher.emit(CustomEvents.SetTimer, DetermineStart(), incident.endDate > incident.startDate ? incident.endDate : undefined);

  }, [coordinates, incident]);

  function onCloseButtonPressed() {
    EventDispatcher.emit(CustomCyclomediaEvents.CloseLocation);
    EventDispatcher.emit(CustomEvents.StopTimer);
    onClose();
  }

  /**
   * Determine the start date of the incident.
   * @remarks start date is determined by the following rules:
   * - if the incident has a "brw" start date, use that.
   * - if the incident does not have a "brw" start date, use general start date.
   */
  function DetermineStart(): Date | undefined {
    if (!eenheden || eenheden.length == 0)
      return incident?.startDate;

    return eenheden.reduce((earliest, current) =>
      earliest.tDtgKoppel < current.tDtgKoppel ? earliest : current, {} as EenheidStatus).tDtgKoppel;
  }

  return (
    <div id={style.incidentDetailView}>
      {!incident && <div className={style.loadingOverlay}><LoadingComponent /></div>}
      <IncidentHeader incident={incident} onClose={onCloseButtonPressed} />
      <IncidentShortcuts leidendIncidentId={incident?.leidendIncidentId} />
      <IncidentEenheden incident={incident} eenheden={eenheden} />
      <IncidentKarakteristieken karakteristieken={incident?.karakteristieken} />
      {incident && incident?.relatedIncidents?.length > 0 &&
        <IncidentGerelateerd incidents={incident.relatedIncidents} />
      }
      <PermissionsGate policy={POLICY.retrieveKladblokRegels}>
        <>
          <p className={style.timelineTitle} ref={scrollToELement as React.RefObject<HTMLParagraphElement>}>Tijdlijn</p>
          {incident?.incidentId && <IncidentKladblokInput incidentId={incident?.incidentId} />}
          <IncidentTimeLine incident={incident} isMinimized={false} scrollToElement={scrollToELement} toggleMinimized={() => toggleMinimized()} />
        </>
      </PermissionsGate>
      <hr />
      {incident && <WazeButton className={style.wazeButton} lat={incident.lat} lon={incident.lon} />}
    </div>
  );
}