import {TextField} from "@mui/material";
import {ChangeEventHandler} from "react";
import {BaseFormField} from "./BaseFormField";
import "./style.scss";

/**
 * Class for passing data to the TextFormField
 */
export class TextFormFieldData extends BaseFormField {
  onValueChanged: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;

  /**
   *
   * @param displayName Name that will be displayed in the label
   * @param fieldName Name that is used for the FormData
   * @param defaultValue Default value
   * @param onValueChanged Function triggered when the value has been changed
   */
  constructor(displayName: string, fieldName: string, defaultValue: string, onValueChanged: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined) {
    super(displayName, fieldName, defaultValue);
    this.onValueChanged = onValueChanged;
  }
}

type Props = {
  field: TextFormFieldData,
  multiline: boolean,
  className?: string
};

/**
 * Text field
 * @param field
 * @param multiline
 * @param className
 * @constructor
 */
export function TextFormField({field, multiline, className}: Readonly<Props>) {
  return (
    <div className={`text-field form-field ${className}`}>
      <label className="input-label">{field.displayName}</label>
      <TextField

        value={field.value}
        onChange={field.onValueChanged}
        error={field.errors != undefined && field.errors.trim().length > 0}
        helperText={field.errors != undefined && field.errors.trim().length > 0 ? field.errors : null}
        fullWidth
        required={true}
        multiline={multiline}
        rows={multiline ? 7 : 1}
        variant="outlined"/>
    </div>
  );
}
