/**
 * Component for displaying booster pump telemetry information.
 * @component
 */
export function TelemetryDrukTransmitter({ ingaandedruk, uitgaandedruk }: Readonly<{ ingaandedruk: number | undefined, uitgaandedruk: number | undefined }>) {
  return (
    <div className="telemetry-pomp">
      <div className="column">
        <div className={"header"}>Waterdruk:</div>
      </div>
      <div className={"column"}>        
        <span><b>In:</b> {ingaandedruk ?? 0} bar</span>
        <span><b>Uit:</b> {uitgaandedruk ?? 0} bar</span>
      </div>
    </div>
  );
}
