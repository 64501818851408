import {SearchMode, SearchProvider, SearchResult} from "./interfaces/searchProvider";
import {FeatureCollection} from "../models/mapbox/featureCollection";
import mapboxgl, {GeoJSONSource, LngLat} from "mapbox-gl";
import { searchResultLayerSourceId } from "../components/Layers/SearchResultLayer";

/**
 * Interface for search result of the mapbox provider
 */
export interface MapboxResult extends SearchResult {
  pos: number[]
}

/**
 * Mapbox search provider
 */
export default class MapboxProvider implements SearchProvider<MapboxResult> {
  label = "Kaart";
  mode = "places" as SearchMode;
  #accessToken = "pk.eyJ1IjoiZGltaXRyeS1yZWJlbGl0IiwiYSI6ImNsOW8zaG9qeTBlZXczcG56enhwN3oyN2IifQ.i6CaM2N0E4ur_GtTE_KQiA";
  #map: mapboxgl.Map | null;

  /**
   * Default constructor
   * @param map Map instance that is used for placing the marker.
   */
  constructor(map: mapboxgl.Map | null) {
    this.#map = map;
  }

  /** @inheritdoc */
  onResultTapped(result: MapboxResult): void {
    if (!this.#map) {
      throw new Error("No map found");
    }

    const source = this.#map.getSource(searchResultLayerSourceId) as GeoJSONSource;
    if(!source)
      return;

    source.setData({
      type: "FeatureCollection",
      features: [{
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: result.pos
        },
        properties: {}
      }]
    });

    this.#map.flyTo({
      center: new LngLat(result.pos[0], result.pos[1]),
      zoom: 15
    });
  }

  /** @inheritdoc */
  async search(searchTerm: string): Promise<MapboxResult[]> {
    const response = await fetch( `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json?country=nl&language=nl-NL&access_token=${this.#accessToken}&`, {
      method: "GET",
    });

    const collection: FeatureCollection = await response.json();
    const result = collection.features.map(x => ({
      id: x.id,
      label: x.place_name,
      pos: x.center
    } as unknown as MapboxResult));

    return result;
  }

  /** @inheritdoc */
  onCancel(): void {
    if (!this.#map)
      return;

    const source = this.#map.getSource(searchResultLayerSourceId) as GeoJSONSource;
    if(!source)
      return;

    source.setData({
      type: "FeatureCollection",
      features: []
    });
  }
}