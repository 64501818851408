import {MachineTelemetry} from "../../../../models/machineTelemetry";
import {TelemetryAccu} from "../components/TelemetryAccu";
import {TelemetryTanks} from "../components/TelemetryTanks";
import {TelemetryUtil} from "../../../../helpers/telemetryUtil";

const MeasurementKeys = {
  AccuSpanning: "value.Application.Chassis.AccuSpanning",
  NiveauWatertank: "value.Application.Opbouw.NiveauWatertank",
  NiveauSchuimtank: "value.Application.Opbouw.Schuimtankniveautank",
  NiveauBrandstof: "value.Application.Chassis.Brandstofniveau",
  AdblueChassis: "value.Application.Chassis.NiveauAdBlue",
  AdblueOpbouw: "value.Application.Opbouw.NiveauAdBlue",
};

export function Schuimblusvoertuig({telemetry} : Readonly<{telemetry: MachineTelemetry}>) {
  const accuSpanning = TelemetryUtil.extractValue(telemetry, MeasurementKeys.AccuSpanning);
  const niveauWatertank = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauWatertank, 0);
  const niveauSchuimtank = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauSchuimtank,0);
  const niveauBrandstof = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauBrandstof,0);
  const niveauAdblueChassis = TelemetryUtil.extractValue(telemetry, MeasurementKeys.AdblueChassis, 0);
  const niveauAdblueOpbouw = TelemetryUtil.extractValue(telemetry, MeasurementKeys.AdblueOpbouw, 0);

  return(
    <>
      <TelemetryAccu accuSpanning={accuSpanning} />
      <TelemetryTanks
        watertankNiveau={niveauWatertank}
        schuimtankNiveau={niveauSchuimtank}
        brandstoftankNiveau={niveauBrandstof}
        adblueChassisNiveau={niveauAdblueChassis}
        adblueOpbouwNiveau={niveauAdblueOpbouw}
      />
    </>
  );

}