import {Button, createTheme, InputLabel, ThemeProvider } from "@mui/material";
import React, {useCallback, useRef, useState} from "react";
import Webcam from "react-webcam";
import {Send, Camera, Delete} from "@mui/icons-material";
import {toBase64, toBlob} from "../../../../services/base64FileHandling";
import {FileUploadButton} from "../../../../components/FileUploadButton/FileUploadButton";

export function CameraFeed(Props: { onCapture: any }) {
  const hasEnvironmentCameraSupport = navigator.mediaDevices.getSupportedConstraints().facingMode;
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string>();
  const [fileUploadError, setFileUploadError] = useState("");

  function handleRetry() {
    setImgSrc(undefined);
  }

  function handleConfirm() {
    setIsBusy(true);
    if (imgSrc) {
      toBlob(imgSrc)
        .then((blob) => {
          Props.onCapture(blob);
        });
    }
  }

  function handleFile(files: File[], invalidMessage: string) {
    if(invalidMessage){
      setFileUploadError(invalidMessage);
      return;
    }

    if(fileUploadError){
      setFileUploadError("");
    }

    toBase64(files[0]).then(base64 => setImgSrc(base64));
  }

  const capture = useCallback(
    () => {
      if (webcamRef.current) {
        setIsBusy(true);
        const imageSrc = webcamRef.current.getScreenshot();

        if (imageSrc)
          setImgSrc(imageSrc);

        setIsBusy(false);
      }
    },
    [webcamRef]
  );

  const errorLabelTheme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            display: "inline !important"
          }
        },
      },
    },
  });

  return (
    <>
      <Webcam
        style={{display: imgSrc ? "none" : "block"}}
        audio={false}
        className="webcam-view"
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: hasEnvironmentCameraSupport ? "environment" : "user"
        }}/>
      {imgSrc && (
        <img
          style={{maxWidth: "100%", maxHeight: "480px", marginLeft: "auto", marginRight: "auto", display: "block"}}
          src={imgSrc}
        />
      )}
      {fileUploadError ? <ThemeProvider theme={errorLabelTheme}><InputLabel size="small" error={true}>{fileUploadError}</InputLabel></ThemeProvider>  : "" }      

      <div className="control-container" style={{display: "flex", gap: "30px", marginTop: "10px"}}>
        {!imgSrc && (
          <>
            <FileUploadButton
              isBusy={isBusy}
              text="Kies foto uit Album"
              allowMultipleFiles={false}
              handleFile={handleFile}
              compactStyling={false}
            />
            <Button
              startIcon={<Camera/>}
              sx={{flex: 2, height: 60}}
              variant="contained"
              disabled={isBusy}
              onClick={capture}>
              {"Foto maken"}
            </Button>
          </>
        )}
        {imgSrc && (
          <>
            <Button
              startIcon={<Delete/>}
              sx={{flex: 2, height: 60}}
              variant="contained"
              disabled={isBusy}
              onClick={handleRetry}>
              {"Verwijderen"}
            </Button>
            <Button
              sx={{flex: 2, height: 60, color: "white"}}
              endIcon={<Send/>}
              variant="contained"
              disabled={isBusy}
              color="success"
              onClick={handleConfirm}>
              {"Toevoegen"}
            </Button>
          </>
        )}
      </div>
    </>);
}