import { Button } from "../Button";
import { faWaze } from "@fortawesome/free-brands-svg-icons";

/**
 * Button that triggers navigation to Waze
 * @param number
 * @param number
 * @constructor
 */
export function WazeButton({ className, lat, lon }: Readonly<{ className?: string, lat: number, lon: number }>) {
  function openWaze() {
    if (window.confirm("Wilt u navigeren naar het incident?")) {
      window.open(`https://waze.com/ul?ll=${lat},${lon}&navigate=yes&z=10`, "_blank");
    }
  }

  return (
    <Button className={className} variant="outlined" icon={faWaze} onClick={openWaze} label="Navigeer met Waze" />
  );
}
