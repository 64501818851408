import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, AuthenticationResult } from "@azure/msal-common";
import { logger } from "app/services/logger";
import { backendRequest } from "config";
import { RequestError } from "../models/errors/RequestError";

export function useResourceFetch() {
  const { instance } = useMsal();

  return async (input: RequestInfo, init?: RequestInit) => {
    const authResult = await instance.acquireTokenSilent(backendRequest).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return await instance.acquireTokenRedirect(backendRequest).catch(error => {
          logger.error("MSAL Encoutered an error", error);
        });
      }

      logger.error("MSAL Encoutered an error", error);
      location.reload();
    }) as AuthenticationResult;

    init = {
      ...init,
      headers: {
        ...init?.headers,
        "Authorization": `Bearer ${authResult.accessToken}`
      }
    };

    const response = await fetch(input, init);

    if (!response.ok) {
      let errorMessage = "Er is iets fout gegaan op de backend... Probeer het later nog een keer of neem contact op met de beheerder.";

      if (response.status == 400) {
        const badRequestMessage = await response.text();

        if (badRequestMessage) {
          errorMessage = badRequestMessage;
        }
      }

      throw new RequestError(errorMessage, response.status);
    }
    return response;
  };
}

export function useResourceFetchJson() {
  const resourceFetch = useResourceFetch();

  return async (input: RequestInfo, init?: RequestInit) => (
    (await resourceFetch(input, init)).json()
  );
}

export function useResourceFetchBlob() {
  const resourceFetch = useResourceFetch();

  return async (input: RequestInfo, init?: RequestInit) => (
    (await resourceFetch(input, init)).blob()
  );
}

export function useResourceFetchBlobUrl() {
  const resourceFetch = useResourceFetchBlob();

  return async (input: RequestInfo, init?: RequestInit) => (
    window.URL.createObjectURL(await resourceFetch(input, init))
  );
}
