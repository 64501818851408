import * as turf from "@turf/turf";

/**
 *
 * Ensures the distance div is alway positioned at the midpoint of the line and oriented in parallel to the line
 *
 * @param coords Coordinates of the line, ensure that the line has at least 2 coordinates
 * @param map The map object is required for both projection and bearing calculation
 * @returns
 */
export function CalculatePixelPosition(coords: any, map: any) {
  const from = turf.point(coords[0]);
  const to = turf.point(coords[1]);
  const distance = turf.distance(from, to, { units: "meters" });
  const midpoint = turf.midpoint(from, to);

  // Projection to position the div at midpoint
  const pixelPos = map.project(midpoint.geometry.coordinates);
  const mapBearing = map.getBearing();
  const lineBearing = turf.bearing(from, to);
  let bearing = (lineBearing - mapBearing) % 360;
  bearing -= 90;

  if (bearing < -90) {
    bearing = bearing + 180;
    const x = 20 * Math.cos((bearing - 90) * Math.PI / 180);
    const y = 20 * Math.sin((bearing - 90) * Math.PI / 180);
    return {
      x: pixelPos.x + x,
      y: pixelPos.y + y,
      bearing: bearing,
      distance: distance
    };
  }
  else {
    const x = 20 * Math.cos((bearing - 90) * Math.PI / 180);
    const y = 20 * Math.sin((bearing - 90) * Math.PI / 180);
    return {
      x: pixelPos.x + x,
      y: pixelPos.y + y,
      bearing: bearing,
      distance: distance
    };
  }
}
