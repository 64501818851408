import * as React from "react";
import { SVGProps } from "react";

const SvgOvercastDaySleet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="overcast-day-sleet_svg__e" viewBox="0 0 193 193">
        <circle
          cx={96.5}
          cy={96.5}
          r={40}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={9}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={9}
          d="M96.5 29.9V4.5m0 184v-25.4m47.1-113.7 18-18M31.4 161.6l18-18m0-94.2-18-18m130.2 130.2-18-18M4.5 96.5h25.4m158.6 0h-25.4"
        />
      </symbol>
      <symbol id="overcast-day-sleet_svg__b" viewBox="0 0 214.3 140.1">
        <path
          fill="none"
          stroke="#94a3b8"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M7.5 100.2a32.4 32.4 0 0 0 32.4 32.4h129.8v-.1l2.3.1a34.8 34.8 0 0 0 6.5-68.9 32.4 32.4 0 0 0-48.5-33 48.6 48.6 0 0 0-88.6 37.1h-1.5a32.4 32.4 0 0 0-32.4 32.4Z"
        />
      </symbol>
      <symbol id="overcast-day-sleet_svg__c" viewBox="0 0 359 231">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2 56 56 0 0 0-84.6 48.1 56.6 56.6 0 0 0 .8 9 60 60 0 0 0 11.2 119"
        />
      </symbol>
      <symbol id="overcast-day-sleet_svg__f" viewBox="0 0 447.7 371.5">
        <g clipPath="url(#overcast-day-sleet_svg__a)">
          <use
            xlinkHref="#overcast-day-sleet_svg__b"
            width={214.3}
            height={140.1}
            transform="translate(195.51 165.01)"
          />
        </g>
        <use
          xlinkHref="#overcast-day-sleet_svg__c"
          width={359}
          height={231}
          transform="translate(0 140.5)"
        />
      </symbol>
      <symbol id="overcast-day-sleet_svg__h" viewBox="0 0 512 371.5">
        <g clipPath="url(#overcast-day-sleet_svg__d)">
          <use
            xlinkHref="#overcast-day-sleet_svg__e"
            width={193}
            height={193}
            transform="translate(57.5 110.5)"
          />
        </g>
        <use
          xlinkHref="#overcast-day-sleet_svg__f"
          width={447.7}
          height={371.5}
          transform="translate(64.34)"
        />
      </symbol>
      <symbol id="overcast-day-sleet_svg__g" viewBox="0 0 155.2 138">
        <path
          fill="#86c3db"
          d="m41.2 30.5-5.8-3.3a13.7 13.7 0 0 0 0-6.4l5.8-3.3a4 4 0 0 0 1.5-5.5 4 4 0 0 0-5.6-1.5l-5.8 3.3a13.6 13.6 0 0 0-2.6-2 13.8 13.8 0 0 0-3-1.2V4a4 4 0 0 0-8.1 0v6.6a14.3 14.3 0 0 0-5.7 3.2l-5.8-3.3A4 4 0 0 0 .5 12 4 4 0 0 0 2 17.5l5.8 3.3a13.7 13.7 0 0 0 0 6.4L2 30.5A4 4 0 0 0 .5 36a4 4 0 0 0 3.6 2 4 4 0 0 0 2-.5l5.8-3.3a13.6 13.6 0 0 0 2.6 2 13.8 13.8 0 0 0 3 1.2V44a4 4 0 0 0 8 0v-6.6a14.2 14.2 0 0 0 5.8-3.2l5.8 3.3a4 4 0 0 0 2 .5 4 4 0 0 0 3.5-2 4 4 0 0 0-1.4-5.5Zm-22.6-1.3a6 6 0 0 1-2.3-8.2 6.1 6.1 0 0 1 5.3-3 6.2 6.2 0 0 1 3 .8A6 6 0 0 1 27 27a6.1 6.1 0 0 1-8.3 2.2Zm78.6 91.3-5.8-3.3a13.7 13.7 0 0 0 0-6.4l5.8-3.3a4 4 0 0 0 1.5-5.5 4 4 0 0 0-5.6-1.5l-5.8 3.3a13.6 13.6 0 0 0-2.6-2 13.8 13.8 0 0 0-3-1.2V94a4 4 0 0 0-8.1 0v6.6a14.3 14.3 0 0 0-5.7 3.2l-5.8-3.3a4 4 0 0 0-5.6 1.5 4 4 0 0 0 1.5 5.5l5.8 3.3a13.7 13.7 0 0 0 0 6.4l-5.8 3.3a4 4 0 0 0-1.5 5.5 4 4 0 0 0 3.6 2 4 4 0 0 0 2-.5l5.8-3.3a13.6 13.6 0 0 0 2.6 2 13.8 13.8 0 0 0 3 1.2v6.6a4 4 0 0 0 8 0v-6.6a14.2 14.2 0 0 0 5.8-3.2l5.8 3.3a4 4 0 0 0 2 .5 4 4 0 0 0 3.5-2 4 4 0 0 0-1.4-5.5Zm-22.6-1.3a6 6 0 0 1-2.3-8.2 6.1 6.1 0 0 1 5.3-3 6.2 6.2 0 0 1 3 .8 6 6 0 0 1 2.2 8.2 6.1 6.1 0 0 1-8.2 2.2Zm78.6-48.7-5.8-3.3a13.7 13.7 0 0 0 0-6.4l5.8-3.3a4 4 0 0 0 1.5-5.5 4 4 0 0 0-5.6-1.5l-5.8 3.3a13.6 13.6 0 0 0-2.6-2 13.8 13.8 0 0 0-3-1.2V44a4 4 0 0 0-8.1 0v6.6a14.3 14.3 0 0 0-5.7 3.2l-5.8-3.3a4 4 0 0 0-5.6 1.5 4 4 0 0 0 1.5 5.5l5.8 3.3a13.7 13.7 0 0 0 0 6.4l-5.8 3.3a4 4 0 0 0-1.5 5.5 4 4 0 0 0 3.6 2 4 4 0 0 0 2-.5l5.8-3.3a13.6 13.6 0 0 0 2.6 2 13.8 13.8 0 0 0 3 1.2V84a4 4 0 0 0 8 0v-6.6a14.2 14.2 0 0 0 5.8-3.2l5.8 3.3a4 4 0 0 0 2 .5 4 4 0 0 0 3.5-2 4 4 0 0 0-1.4-5.5Zm-22.6-1.3a6 6 0 0 1-2.3-8.2 6.1 6.1 0 0 1 5.3-3 6.2 6.2 0 0 1 3 .8 6 6 0 0 1 2.2 8.2 6.1 6.1 0 0 1-8.2 2.2Z"
        />
      </symbol>
      <symbol id="overcast-day-sleet_svg__i" viewBox="0 0 155.2 138">
        <use
          xlinkHref="#overcast-day-sleet_svg__g"
          width={155.2}
          height={138}
        />
        <path
          fill="#0a5ad4"
          d="M21.6 98a8 8 0 0 1-8-8V78a8 8 0 0 1 16 0v12a8 8 0 0 1-8 8Zm56-50a8 8 0 0 1-8-8V28a8 8 0 0 1 16 0v12a8 8 0 0 1-8 8Zm56 90a8 8 0 0 1-8-8v-12a8 8 0 0 1 16 0v12a8 8 0 0 1-8 8Z"
        />
      </symbol>
      <clipPath id="overcast-day-sleet_svg__a">
        <path
          fill="none"
          d="M351.5 308a56 56 0 0 0-56-56l-2.5.1A83.7 83.7 0 0 0 211.5 148V0h236.2v308Z"
        />
      </clipPath>
      <clipPath id="overcast-day-sleet_svg__d">
        <path
          fill="none"
          d="M276 148a83.8 83.8 0 0 0-71.4 40 56 56 0 0 0-84.6 48 56.6 56.6 0 0 0 .8 9A60 60 0 0 0 72 304H0V0h276Z"
        />
      </clipPath>
    </defs>
    <use xlinkHref="#overcast-day-sleet_svg__h" width={512} height={371.5} />
    <use
      xlinkHref="#overcast-day-sleet_svg__i"
      width={155.2}
      height={138}
      transform="translate(178.4 338)"
    />
  </svg>
);

export default SvgOvercastDaySleet;
