import {useEffect, useState} from "react";

/**
 * Hook to detect if the user uses a screen that has a mobile width
 */
export function useMobileDetection() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {isMobile};
}