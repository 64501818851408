import * as React from "react";
import { SVGProps } from "react";

const SvgHazeNight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="haze-night_svg__b" viewBox="0 0 279 279">
        <path
          fill="none"
          stroke="#72b9d5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M256.8 173.1A133.3 133.3 0 0 1 122.4 40.7 130.5 130.5 0 0 1 127 7.5 133 133 0 0 0 7.5 139.1c0 73.1 60 132.4 134.2 132.4 62.5 0 114.8-42.2 129.8-99.2a135.6 135.6 0 0 1-14.8.8Z"
        />
      </symbol>
      <symbol id="haze-night_svg__c" viewBox="0 0 258 66">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M9 57h30"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeDasharray="60 60"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M99 57h90"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M219 57h30M9 9h30"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeDasharray="60 60"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M99 9h90"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M219 9h30"
        />
      </symbol>
      <clipPath id="haze-night_svg__a">
        <path fill="none" d="M0 0h512v306H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#haze-night_svg__a)">
      <use
        xlinkHref="#haze-night_svg__b"
        width={279}
        height={279}
        transform="translate(116.5 116.5)"
      />
    </g>
    <use
      xlinkHref="#haze-night_svg__c"
      width={258}
      height={66}
      transform="translate(127 339)"
    />
  </svg>
);

export default SvgHazeNight;
