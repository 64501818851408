import { MutableRefObject, useContext, useState } from "react";
import { Button, Modal } from "@mui/material";
import {
  Brightness6,
  Clear,
  Feedback,
  GpsFixed,
  GpsOff,
  Layers,
  SatelliteAlt,
  ThreeDRotation,
  DesignServices
} from "@mui/icons-material";
import { SharedMapConfigurationContext } from "app/contexts/mapContext";
import SettingsIcon from "@mui/icons-material/Settings";
import "./style.scss";
import { FeedbackForm } from "./components/FeedbackForm";
import { VisualMode } from "app/models/mapConfiguration";
import { CompassIcon } from "app/components/WeatherWidget/Icons";
import PermissionsGate, { POLICY } from "../../components/PermissionsGate";
import { useMapBearing } from "app/hooks/useMapBearing";
import { MeasurementDrawControl } from "./components/MeasurementComponent";
import StreetViewSelect from "app/components/ManualStreetViewSelector";
import { SearchComponent } from "../../components/Search";
import { UserMenu } from "../../components/UserMenu";
import EventDispatcher, { CustomEvents } from "../../../eventDispatcher";
import { VehicleMarker } from "app/models/vehicleMarker";

type Prop = {
  vehicleMarkers: MutableRefObject<VehicleMarker[]>;
}

export function RightPanel({ vehicleMarkers }: Readonly<Prop>) {
  const [settingOpen, setSettingOpen] = useState(false);
  const { bearing } = useMapBearing();
  const { sharedMapConfiguration, setSharedMapConfiguration } = useContext(SharedMapConfigurationContext);
  const [feedbackModelOpen, setFeedbackModelOpen] = useState(false);
  const [aerialEnabled, setAerialEnabled] = useState(false);
  const [userPositionEnabled, setUserPositionEnabled] = useState(false);
  const [darkModeEnabled, setDarkModeEnabled] = useState(false);
  const [building3DEnabled, setBuilding3DEnabled] = useState(false);
  const [measurementDrawEnabled, setMeasurementDrawEnabled] = useState(false);

  EventDispatcher.on(CustomEvents.CloseAllTools, () => {
    setSettingOpen(false);
  });

  function toggleAerial() {
    setSharedMapConfiguration(old => {
      return { ...old, satelliteMode: !old.satelliteMode };
    });
    setAerialEnabled(enabled => !enabled);
  }

  function toggleUserPosition() {
    setSharedMapConfiguration(old => {
      return { ...old, trackGeoLocation: !old.trackGeoLocation };
    });
    setUserPositionEnabled(enabled => !enabled);
  }

  function toggleDarkMode() {
    setSharedMapConfiguration(old => {
      return { ...old, visualMode: !darkModeEnabled ? VisualMode.Dark : VisualMode.Light };
    });
    setDarkModeEnabled(enabled => !enabled);
  }

  function toggleBuilding3d() {
    setSharedMapConfiguration(old => {
      return { ...old, building3DMode: !old.building3DMode };
    });
    setBuilding3DEnabled(enabled => !enabled);
  }

  function resetBearing() {
    setSharedMapConfiguration(old => {
      return { ...old, resetBearing: true };
    });
  }

  function toggleLayerModal() {
    EventDispatcher.emit(CustomEvents.OpenModal, "GeolayersSelection");
    setSettingOpen(prev => !prev);
  }

  function toggleMeasurementDraw() {
    setSharedMapConfiguration(old => {
      return { ...old, measurementDrawMode: !old.measurementDrawMode };
    });
    setMeasurementDrawEnabled(enabled => !enabled);
  }

  return (
    <div className="right-panel">
      <div id="menu-overlay-top">
        <div className="menu-button-wrapper">
          {/*<Search/>*/}
          <SearchComponent vehicleMarkers={vehicleMarkers} />
        </div>
        <div className="menu-button-wrapper">
          <div className={settingOpen ? "menu-button settings open" : "menu-button settings"}>
            <button onClick={() => setSettingOpen(prev => !prev)}>
              <SettingsIcon />
            </button>
            <div className="subMenu">
              <Button color="primary" size="large" variant="outlined" onClick={toggleMeasurementDraw}>
                {measurementDrawEnabled ? <Clear fontSize="large" /> : <DesignServices fontSize="large" />}
              </Button>
              <PermissionsGate policy={POLICY.selectMapLayers}>
                <Button color="primary" size="large" variant="outlined" onClick={toggleLayerModal}>
                  <Layers fontSize="large" />
                </Button>
              </PermissionsGate>
              <Button color="primary" size="large" variant="outlined" onClick={toggleAerial}>
                {aerialEnabled ? <Clear fontSize="large" /> : <SatelliteAlt fontSize="large" />}
              </Button>
              <Button color="primary" size="large" variant="outlined" onClick={toggleDarkMode}>
                <Brightness6 fontSize="large" />
              </Button>
              <Button color="primary" size="large" variant="outlined" onClick={toggleUserPosition}>
                {userPositionEnabled ? <GpsOff fontSize="large" /> : <GpsFixed fontSize="large" />}
              </Button>
              <Button color="primary" size="large" variant="outlined" onClick={toggleBuilding3d}>
                {building3DEnabled ? <Clear fontSize="large" /> : <ThreeDRotation fontSize="large" />}
              </Button>
            </div>
          </div>
        </div>
        <StreetViewSelect />
      </div>
      <div id="menu-overlay-bottom">
        <div className="menu-button-wrapper draw">
          <MeasurementDrawControl />
        </div>
        <div className="menu-button-wrapper compass">
          <button onClick={resetBearing}>
            <div className="rotation-wrapper">
              <CompassIcon direction={bearing - (bearing * 2)} />
            </div>
          </button>
        </div>
        <div className="menu-button-wrapper">
          <button onClick={() => setFeedbackModelOpen(true)}>
            <Feedback />
          </button>
        </div>
        <UserMenu />
        <Modal
          open={feedbackModelOpen}
          className={sharedMapConfiguration.visualMode == VisualMode.Light ? "light" : "dark"}
          onClose={() => setFeedbackModelOpen(false)}
        >
          <div>
            <FeedbackForm onClose={() => setFeedbackModelOpen(false)} />
          </div>
        </Modal>
      </div>
    </div>
  );
}
