import {MachineTelemetry} from "../models/machineTelemetry";

export class TelemetryUtil {

  /**
   * Extracts the value from the telemetry data
   * @param data
   * @param key
   * @param fractionDigits
   * @returns string value
   */
  static extractValue(data: Readonly<MachineTelemetry>, key: string, fractionDigits?: number | undefined): number | undefined{
    if (fractionDigits != undefined)
      return data.latestSamples[key]
        ? parseFloat(data.latestSamples[key].toFixed(fractionDigits))
        : undefined;

    return data.latestSamples[key]
      ? data.latestSamples[key]
      : undefined;
  }
}