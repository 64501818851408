import { useState } from "react";
import { ObjectgegevensPopupTemplate } from "./Templates/ObjectgegevensPopupTemplate";
import { ToegangsleutelPopupTemplate } from "./Templates/ToegangsleutelPopupTemplate";
import { MelvinPopupTemplate } from "./Templates/MelvinPopupTemplate";
import { VoorzieningPopupTemplate } from "./Templates/VoorzieningPopupTemplate";
import { DefaultPopupTemplate } from "./Templates/DefaultPopupTemplate";
import { DuikenPopupTemplate } from "./Templates/DuikPopupTemplate";
import "./style.scss";
import { PopupContent } from "../../models/popupContent";
import { WaterwinningPopupTemplate } from "./Templates/WaterwinningPopupTemplate";

interface Props {
  content: PopupContent[];
}

export enum Templates {
  Default = 0,
  Objectgegevens = 1,
  Toegangsleutel = 2,
  MelvinBelemmering = 3,
  Voorziening = 4,
  Duiken = 5,
  Waterwinning = 6
}

/**
 * Template manager for the popup
 * @param content
 * @constructor
 */
export default function TemplateManager({ content }: Readonly<Props>) {
  const [openedTab, setOpenedTab] = useState<string>(content[0].id);

  if (content.length == 0 || !content)
    return <div className="layer-data-popup"> </div>;

  return (
    <div className={`layer-data-popup ${openedTab}`}>
      <div className="tab-header">
        <div className="tab-container">
          {content.map((x) => <div aria-hidden="true" onClick={() => {
            setOpenedTab(x.id);
          }} className={openedTab == x.id ? "active tab" : "tab"} key={`${x.id}_tab`}><span>{x.tabName}</span></div>)}
        </div>
      </div>
      {content.map((x) => {
        return (
          <div key={`${x.id}_content`} className={openedTab == x.id ? "active tab-content" : "tab-content"} id={x.id}>
            {x.data && x.template == Templates.Objectgegevens &&
              <ObjectgegevensPopupTemplate data={x.data} />
            }
            {x.data && x.template == Templates.Toegangsleutel &&
              <ToegangsleutelPopupTemplate data={x.data} />
            }
            {x.data && x.template == Templates.MelvinBelemmering &&
              <MelvinPopupTemplate data={x.data} />
            }
            {x.data && x.template == Templates.Voorziening &&
              <VoorzieningPopupTemplate data={x.data} />
            }
            {x.data && x.template == Templates.Default &&
              <DefaultPopupTemplate data={x.data} />
            }
            {
              x.data && x.template == Templates.Duiken &&
              <DuikenPopupTemplate data={x.data} />
            }
            {
              x.data && x.template == Templates.Waterwinning &&
              <WaterwinningPopupTemplate data={x.data} />
            }
          </div>
        );
      })}
    </div>
  );
}