import CloseIcon from "@mui/icons-material/Close";
import styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Incident } from "app/models/incident";
import { useMobileDetection } from "app/hooks/useMobileDetection";
import { OefenincidentIndicator } from "app/components/IncidentPanel/components/IncidentDetailView/components/IncidentHeader/components/OefenincidentIndicator";
import { IncidentProperties } from "../IncidentProperties";

/**
 * Header of an incident
 * @param incident
 * @param onClose function that is triggered whenever the close button is clicked
 * @constructor
 */
export function IncidentHeader({ incident, onClose }: Readonly<{ incident?: Incident | null, onClose: () => void }>) {
  const { isMobile } = useMobileDetection();

  return (
    <div id={styles.incidentHeader} className={isMobile ? styles.mobile : ""} >
      <button className={styles.closeButton} onClick={onClose}><CloseIcon /></button>
      <div className={styles.propertiesWrapper}>
        <div className={styles.streetSmartPlaceHolder}></div>
        {incident && <IncidentProperties incident={incident} />}
      </div>
      {incident && <OefenincidentIndicator incident={incident} size={"large"} />}
      <div className={styles.title}>
        <h2>{incident?.description} {incident?.location}, {incident?.plaatsNaam}</h2>
        <p id={styles.incidentDate}>{incident?.startDateShort}</p>
      </div>
      {(incident?.relatedIncidents?.length ?? 0) > 0 && <div className={styles.linkedIndicator}><FontAwesomeIcon icon={faLink} /></div>}
    </div>
  );
}
