import { useState } from "react";
import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

/**
 * Generic Accordion component
 * @param children
 * @param title
 * @param titleStyle
 * @constructor
 */
export function Accordion({ children, title, titleStyle, isOpenInitially, className }: Readonly<{ children: React.ReactNode, title: string, titleStyle?: "ACTIVE" | "INACTIVE", isOpenInitially?: boolean, className?: string }>) {
  const [isOpen, setIsOpen] = useState(isOpenInitially ?? true);

  return (
    <div className={`${style.accordion} ${className}`}>
      <div className={style.header} onClick={() => setIsOpen(!isOpen)}>
        <div className={titleStyle && titleStyle == "INACTIVE" ? style.titleInActive : style.title}>
          {title}
        </div>
        <div className={`${style.icon} ${isOpen ? style.open : style.closed}`} >
          <FontAwesomeIcon icon={faChevronUp} />
        </div>
      </div>
      <div className={`${style.content} ${isOpen ? style.open : style.closed}`}>{children}</div>
    </div>
  );
}
