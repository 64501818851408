import { Incident } from "app/models/incident";
import style from "./style.module.scss";

type Props = {
  incident: Incident;
  size: "small" | "large";
}

/**
 * OefenincidentIndicator
 * @param incident Incident to check if it is an oefenincident.
 * @param size Size of the indicator, large will be a fullwidth indicator and small a tag.
 * @constructor
 */
export function OefenincidentIndicator({ incident, size }: Readonly<Props>) {
  if (incident.incidentNr.toString().startsWith("999")) {
    return (
      <div className={`${style.oefenincidentIndicator} ${size == "small" ? style.small : ""}`}>
        <div className="content-wrapper">
          <h2> Oefenincident</h2>
        </div>
      </div>
    );
  }
  return null;
}
