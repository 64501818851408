import * as React from "react";
import { SVGProps } from "react";

const SvgUvIndex4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <clipPath id="uv-index-4_svg__a">
        <path fill="none" d="M64 64h384v192H328a72 72 0 0 0-72 72v120H64Z" />
      </clipPath>
      <symbol id="uv-index-4_svg__b" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
    </defs>
    <g clipPath="url(#uv-index-4_svg__a)">
      <use
        xlinkHref="#uv-index-4_svg__b"
        width={375}
        height={375}
        transform="translate(68.5 68.5)"
      />
      <path
        fill="none"
        stroke="#fbbf24"
        strokeMiterlimit={10}
        strokeWidth={15}
        d="M254 338v-10a74 74 0 0 1 74-74h10"
      />
    </g>
    <rect width={144} height={144} x={280} y={280} fill="#ffb800" rx={48} />
    <path
      fill="#fff"
      d="M372.2 316v42.5l10.5-.3v14.3l-10.6-.3V388h-17.6v-15.8l-33.2.3V356l32.5-40.1Zm-36.6 42.5h19v-22.8h-.3Z"
    />
  </svg>
);

export default SvgUvIndex4;
