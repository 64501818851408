import * as React from "react";
import { SVGProps } from "react";

const SvgHail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="hail_svg__a" viewBox="0 0 359 231">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2 56 56 0 0 0-84.6 48.1 56.6 56.6 0 0 0 .8 9 60 60 0 0 0 11.2 119"
        />
      </symbol>
      <symbol id="hail_svg__b" viewBox="0 0 136 114">
        <path
          fill="#86c3db"
          d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0Zm56 90a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm56-60a12 12 0 1 0 12 12 12 12 0 0 0-12-12Z"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#hail_svg__a"
      width={359}
      height={231}
      transform="translate(76.5 140.5)"
    />
    <use
      xlinkHref="#hail_svg__b"
      width={136}
      height={114}
      transform="translate(188 350)"
    />
  </svg>
);

export default SvgHail;
