import {useEnrichText} from "../../../hooks/useEnrichText";

interface Props {
  data: Record<string,string>[];
}

export function ObjectgegevensPopupTemplate({ data }: Readonly<Props>) {
  return(
    <>
      <div className="popup-category">
        <table>
          <tr><td><span className={"label"}>Naam:</span></td><td>{data[0]["formelenaam"]}</td></tr>
          <tr><td><span className={"label"}>Type:</span></td><td>{data[0]["typeobject"]}</td></tr>
          <tr><td><span className={"label"}>Bodemgesteldheid</span></td><td>{data[0]["bodemgesteldheid"] ?? "-"}</td></tr>
          <tr><td><span className={"label"}>Gebruiksfuncties</span></td><td>{data[0]["gebruiksfuncties"] ?? "-"}</td></tr>
        </table>
      </div>
      <div className="popup-category">
        <span className="category-header">Bijzonderheden</span>
        <table>
          <tr><td>{useEnrichText(data[0]["bijzonderheden"])}</td></tr>
        </table>
      </div>
    </>
  );
}