import style from "./style.module.scss";
import { User } from "../../models/User";
import { AccountCircle } from "@mui/icons-material";

/**
 * UserProfile component
 * @param user
 * @constructor
 */
export function UserProfile({ user, hideRole }: Readonly<{ user: User, hideRole?: boolean }>) {
  return (
    <div id={style.userProfile}>
      <div id={style.details}>
        <p id={style.name}>{user.displayName}</p>
        {!hideRole && <p id={style.job}>{user.jobTitle}</p>}
      </div>
      {user.profilePhoto.length == 0 && <div id={style.profilePhoto} style={{ backgroundColor: "var(--default-primary-color)" }}><AccountCircle /></div>}
      {user.profilePhoto.length > 0 && <img id={style.profilePhoto} alt="Profile picture" src={`data:image/png;base64, ${user.profilePhoto}`} />}
    </div>
  );
}
