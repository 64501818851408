import { useEffect, useMemo, useRef, useState } from "react";
import { SWRConfig } from "swr";
import { RightPanel } from "./panels/rightpanel";
import { useResourceFetchJson } from "./hooks/useResourceFetch";
import { UserProvider } from "./contexts/userContext";
import { MapContext, SharedMapConfigurationContext } from "./contexts/mapContext";
import { Map } from "mapbox-gl";
import { useAppConfiguration } from "./hooks/useAppConfiguration";
import "./style.scss";
import { InitMapConfiguration, MapConfiguration, VisualMode } from "./models/mapConfiguration";
import { MemoizedMapboxMap } from "./components/MapboxMap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMobileDetection } from "./hooks/useMobileDetection";
import { MemoizedScreensaver } from "./Screensaver";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/ErrorFallback";
import { logger } from "./services/logger";
import { LayerManager } from "./components/Layers/LayerManager";
import { useNoSleep } from "./hooks/useNoSleep";
import { useMsal } from "@azure/msal-react";
import { VehicleMarker } from "./models/vehicleMarker";
import { ModalManager } from "./components/Modals/ModalManager";
import VersionProvider, { useVersion } from "./contexts/versionContext";
import NotificationBar from "./components/NotificationBar";
import { useNetworkStatus } from "./hooks/useNetworkStatus";
import EventDispatcher, { CustomEvents } from "eventDispatcher";
import { NewVersionReadyNotification } from "./components/NotificationBar/models/NewVersionReadyNotification";
import { DisconnectNotification } from "./components/NotificationBar/models/DisconnectNotification";
import { ReconnectNotification } from "./components/NotificationBar/models/ReconnectNotification";
import { IncidentPanel } from "./components/IncidentPanel";
import { CyclomediaStreetSmart } from "./components/Cyclomedia/StreetSmart";
const ErrorFallbackComponent = () => (<ErrorFallback />);

export function App() {
  const resourceFetchJson = useResourceFetchJson();
  const appConfiguration = useAppConfiguration();

  logger.level = "verbose";

  if (appConfiguration.environment != "Production") {
    document.title = `OIV-Viewer (${appConfiguration.environment}) | Veiligheidsregio Midden- en West-Brabant`;
    const favicon = `favicon-${appConfiguration.environment.toLowerCase()}.ico`;
    const faviconElement = document.getElementById("favicon") as HTMLLinkElement;
    faviconElement.href = favicon;
  }

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return (
      <SWRConfig value={{
        fetcher: resourceFetchJson,
      }}>
        <Main />
      </SWRConfig>
    );
  }

  return (
    <ErrorBoundary fallbackRender={ErrorFallbackComponent}>
      <SWRConfig value={{
        fetcher: resourceFetchJson,
      }}>
        <Main />
      </SWRConfig>
    </ErrorBoundary>
  );
}


function Main() {
  const [map, setMap] = useState<Map | null>(null);
  const [sharedMapConfiguration, setSharedMapConfiguration] = useState<MapConfiguration>(InitMapConfiguration);
  const [isNetworkStatusNotificationOpen, setIsNetworkStatusNotificationOpen] = useState<boolean>(false);
  const { isMobile } = useMobileDetection();
  const { instance } = useMsal();
  const { hasNewVersion } = useVersion();
  const { isOnline } = useNetworkStatus();
  const userName = instance.getActiveAccount()?.username;
  const appConfiguration = useAppConfiguration();
  const vehicleMarkers = useRef<VehicleMarker[]>([]); // lifting state up to prevent race conditions
  useNoSleep(map);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (map) {
        map.resize();
      }
    }, 300000);

    webglSafeGuard();

    return () => clearTimeout(timer);
  }, [map]);

  useEffect(() => {
    if (hasNewVersion)
      EventDispatcher.emit(CustomEvents.OpenNotificationBar, NewVersionReadyNotification);

  }, [hasNewVersion]);

  useEffect(() => {
    if (!isOnline) {
      EventDispatcher.emit(CustomEvents.OpenNotificationBar, DisconnectNotification);
      setIsNetworkStatusNotificationOpen(true);
    } else if (isNetworkStatusNotificationOpen) {
      setIsNetworkStatusNotificationOpen(false);
      EventDispatcher.emit(CustomEvents.OpenNotificationBar, ReconnectNotification);
    }
  }, [isOnline]);

  function webglSafeGuard() {
    setInterval(() => {
      const glContext = (document?.querySelector(".mapboxgl-canvas") as HTMLCanvasElement)?.getContext("webgl2");
      if (!glContext)
        return;

      if (!glContext.isContextLost())
        return;

      logger.error("WebGL context lost on .mapboxgl-canvas", new Error("WebGL context lost on .mapboxgl-canvas"), { userName: userName, environment: appConfiguration.environment });
      window.location.reload();
    }, 500);
  }

  const mobileThemeClassName = isMobile ? "mobile" : "";
  const visualModeClassName = sharedMapConfiguration.visualMode == VisualMode.Light ? "App" : "App dark";
  const sharedMapContext = useMemo(() => ({ sharedMapConfiguration, setSharedMapConfiguration }), [sharedMapConfiguration, setSharedMapConfiguration]);

  return (
    <VersionProvider>
      <>
        <MemoizedScreensaver />
        <NotificationBar />
        <CyclomediaStreetSmart />
        <SharedMapConfigurationContext.Provider value={sharedMapContext}>
          <MapContext.Provider value={map}>
            <div className={`${visualModeClassName} ${mobileThemeClassName}`}>
              <MemoizedMapboxMap setMap={setMap} containerId="map-viewer-container" />
              <UserProvider>
                <>
                  <LayerManager vehicleMarkers={vehicleMarkers} />
                  <IncidentPanel />
                  {!isMobile && <RightPanel vehicleMarkers={vehicleMarkers} />}
                  <ModalManager />
                  <div id="streetSmartContainer" />
                </>
              </UserProvider>
            </div>
          </MapContext.Provider>
        </SharedMapConfigurationContext.Provider >
        <ToastContainer />
      </>
    </VersionProvider>
  );
}
