import { useUser } from "app/hooks/useUser";
import style from "../style.module.scss";
import { EenheidProfile } from "app/components/EenheidProfile";
import { VehicleStatus } from "app/components/Popups/VehiclePopup/components/VehicleStatus";
import { useMachine } from "app/hooks/useMachines";
import { useVersion } from "app/contexts/versionContext";

/**
 *  Overview of the logged in "eenheid"
 * @returns 
 */
export function EenheidOverview() {
  const { user } = useUser();
  const machine = useMachine({ assetNumber: user?.displayName.replace("-", "") ?? "" });
  const { version } = useVersion();

  if (!user)
    return <></>;

  return (
    <>
      <div className={style.overviewHeader}>&nbsp;</div>
      <div className={style.profile}>
        <EenheidProfile roepnaamEenheid={user.displayName} hideStatus={true} />
      </div>
      <div className={style.row}>
        <span className={style.label}>Account-type:</span>
        <span className={style.value}>Voertuig/Eenheid</span>
      </div>
      <div className={style.row}>
        <span className={style.label}>Huidige status:</span>
        <span className={style.value}>
          <VehicleStatus showLabel={true} statusCode={machine?.status?.tActStatusCodeExtBrw} statusCodeAfkr={machine?.status?.tActStatusAfkBrw ?? ""} />
        </span>
      </div>
      <div className={style.row}>
        <span className={style.label}>Voertuig type:</span>
        <span className={style.value}>{machine?.status?.omsVoertuigSoort}</span>
      </div>
      <div className={style.footer}>
        <div className={style.logo}><img alt="VRMWB-Logo" src="assets/images/vrmwb-logo.png"></img></div>
        <span className={style.version}>Versie: {version}</span>
      </div>
    </>
  );

}