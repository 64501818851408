import { Vehicle } from "../../../models/vehicleLocation";
import LoadingComponent from "./LoadingComponent";
import ErrorComponent from "./ErrorComponent";
import { VehicleStatus } from "./components/VehicleStatus";
import { VehicleBijzEenheid } from "./components/VehicleBijzEenheid";
import { useMachine } from "../../../hooks/useMachines";
import { useEffect, useState } from "react";
import { Tankautospuit } from "./templates/Tankautospuit";
import { Schuimblusvoertuig } from "./templates/Schuimblusvoertuig";
import { Kleinwatertransport } from "./templates/Kleinwatertransport";
import { Grootmiddelwatertransport } from "./templates/Grootmiddelwatertransport";
import "./style.scss";

interface Props {
  readonly machine: Vehicle;
}

/**
 * VehiclePopup shows the popup for a vehicle
 * @component
 * @param vehicle The vehicle to show the popup for
 * @returns {JSX.Element}
 */
export function VehiclePopup({ machine }: Props) {
  const [internalMachine, setInternalMachine] = useState<Vehicle>(machine);
  const data = useMachine({ assetNumber: machine.roepnaamEenheid });

  useEffect(() => {
    if (data)
      setInternalMachine(data);
  }, [data]);

  return (
    <div className={"vehicle-popup"}>
      <div className={"vehicle-popup-header"}>
        <div className={"label"}>{internalMachine.roepnaamEenheid}</div>
        <div className={"status"}>
          <div className={"label"}>Status:</div>
          <VehicleStatus statusCode={internalMachine?.status?.tActStatusCodeExtBrw} statusCodeAfkr={internalMachine?.status?.tActStatusAfkBrw ?? ""} showLabel={true} />
        </div>
        <div className={"codeVoertuigSoort"}>
          <div className={"label"}>Type:</div>
          <span>{internalMachine.status?.omsVoertuigSoort}</span>
          <span>({internalMachine.status?.codeVoertuigSoort})</span>
        </div>
        <VehicleBijzEenheid vehicle={internalMachine} />
      </div>
      <div className={"vehicle-telemetry"}>
        <VehicleTelemetry vehicle={internalMachine} />
      </div>
    </div>
  );
}

export interface VehicleTelemetryProps {
  vehicle: Vehicle
}

/**
 * VehicleTelemetry shows the telemetry for a vehicle
 * @param vehicle
 * @component
 */
function VehicleTelemetry({ vehicle }: Readonly<VehicleTelemetryProps>) {
  if (!vehicle?.telemetry?.latestSamples)
    return <LoadingComponent />;

  if (!vehicle || Object.keys(vehicle.telemetry.latestSamples).length == 0)
    return <ErrorComponent />;

  switch (vehicle.status?.codeVoertuigSoort) {
    case "SB":
      return <Schuimblusvoertuig telemetry={vehicle.telemetry} />;
    case "TS":
      return <Tankautospuit telemetry={vehicle.telemetry} />;
    case "KW":
      return <Kleinwatertransport telemetry={vehicle.telemetry} />;
    case "DPH-GWG":
    case "DPH-GWM":
    case "DPH-TPB":
    case "BU-DPGW":
    case "BUH-DPM":
    case "BUH-DPG":
      return <Grootmiddelwatertransport telemetry={vehicle.telemetry} />;
    default:
      return <Tankautospuit telemetry={vehicle.telemetry} />;
  }
}
