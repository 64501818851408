import { Incident } from "app/models/incident";

export function Incidentgroep({ incident }: Readonly<{ incident: Incident }>) {
  const incidentgroepSplit = incident.incidentgroep?.split("-");

  return (
    <>
      <p className="property-label"><b>INCIDENTGROEP</b></p>
      {incident.incidentgroep ?
        (
          <p>{incidentgroepSplit[0]}-{incidentgroepSplit[incidentgroepSplit.length - 1]}</p>
        ) :
        (<p> - </p>)}
    </>
  );
}