import { memo, useEffect, useRef, useState } from "react";
import { LoadingComponent } from "../LoadingComponent";
import style from "./style.module.scss";
import { ProjectionHelper } from "./helpers/ProjectionHelper";
import { faClose, faExpand, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Button";
import { useStreetSmartAPI } from "./hooks/useStreetSmartAPI";
import EventDispatcher from "eventDispatcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OpenLocationProps } from "./models/openLocationProps";
import usePrevious from "app/hooks/usePrevious";
import { CustomCyclomediaEvents } from "./models/CustomCyclomediaEvents";

type StreetSmartState = {
  isFullscreen: boolean;
  isVisible: boolean;
  currentMode: "manual" | "floating" | "fullscreen";
  currentCoordinate: [number, number] | null;
};

/**
 * Component that displays a Cyclomedia Street Smart viewer.
 * @remarks This component is memoized to prevent unnecessary re-renders, which can cause crashes with the Cyclomedia Street Smart API.
 *          It also works with event based location opening, because the StreetSmart API is designed to work with resizing, rerendering or reinitializing the viewer.
 */
function StreetSmart() {
  const cyclomediaContainer = useRef<HTMLDivElement>(null);
  const streetSmartContainer = useRef<HTMLDivElement>(null);
  const { isLoading, isInitialized, hasError, openViewer, closeViewer } = useStreetSmartAPI(cyclomediaContainer);
  const [state, setState] = useState<StreetSmartState>({ isFullscreen: false, isVisible: false, currentMode: "floating", currentCoordinate: null });
  const previousState = usePrevious(state);

  /**
   * Open a location in the viewer.
   */
  async function openLocation({ coordinate, openFullscreen, mode }: OpenLocationProps) {
    const rd_new_position = ProjectionHelper.convertWGStoRD(coordinate.lat, coordinate.lng);

    if (state.currentCoordinate == rd_new_position)
      return;

    setState({ isFullscreen: openFullscreen ?? false, isVisible: true, currentMode: mode ?? "floating", currentCoordinate: rd_new_position });
    if (openFullscreen) {
      openViewer(["oblique", "panorama"], [...rd_new_position, 0], mode !== "manual");
    } else {
      openViewer(["panorama"], [...rd_new_position, 0]);
    }
  }

  /**
   * Close the location in the viewer.
   */
  async function closeLocation() {
    setState({ isFullscreen: false, isVisible: false, currentMode: "floating", currentCoordinate: null });
    await closeViewer(["oblique", "panorama"]);
  }

  async function maximize() {
    setState({ ...state, isFullscreen: true });
    openViewer(["oblique", "panorama"], state.currentCoordinate ?? [0, 0], false);
  }

  /**
   * Minimize the viewer.
   * @remarks If the viewer is not in floating mode, all viewers will be closed.
   */
  async function minimize() {
    if (state.currentMode === "manual") {
      setState({ ...state, isFullscreen: false, isVisible: false, currentMode: "floating", currentCoordinate: null });

      if (previousState?.currentCoordinate) {
        closeViewer(["oblique"]);
        openViewer(["panorama"], [...previousState.currentCoordinate, 0]);
        setState(previousState);
      }
    } else {
      setState({ ...state, isFullscreen: false });
      closeViewer(["oblique"]);
    }
  }

  async function move({ up, left }: { up: number, left: number }) {
    if (cyclomediaContainer.current) {
      cyclomediaContainer.current.style.top = `${(-up)}px`;
      cyclomediaContainer.current.style.left = `${(-left)}px`;
    }
  }

  /**
   * Listen for OpenLocation events and open the location in the viewer.
   */
  useEffect(() => {
    EventDispatcher.on(CustomCyclomediaEvents.OpenLocation, openLocation);
    EventDispatcher.on(CustomCyclomediaEvents.CloseLocation, closeLocation);
    EventDispatcher.on(CustomCyclomediaEvents.MinimizeViewer, minimize);
    EventDispatcher.on(CustomCyclomediaEvents.MaximizeViewer, maximize);
    EventDispatcher.on(CustomCyclomediaEvents.MoveStreetSmart, move);
  }, []);

  return (
    <div ref={streetSmartContainer} id={style.streetSmartContainer} className={`${state.isFullscreen ? style.fullscreen : ""} ${state.isVisible ? "" : style.hidden}`}>
      {(!isInitialized() || isLoading) && <div id={style.loadingOverlay}><LoadingComponent /></div>}
      <div ref={cyclomediaContainer} id={style.panoramaWrapper}>
        {(!isLoading && hasError) && <div id={style.errorOverlay} className={state.isFullscreen ? style.fullscreen : ""}><FontAwesomeIcon icon={faTriangleExclamation} /><span>Er zijn geen beelden gevonden van de locatie in Cyclomedia</span></div>}
        {state.isFullscreen &&
          <div id={style.expandIcon} className={state.isFullscreen ? style.fullscreen : ""}>
            <Button icon={state.isFullscreen ? faClose : faExpand} label="Terug naar de OIV-viewer" onClick={minimize} variant={"contained"} />
          </div>
        }
        {(!state.isFullscreen && !isLoading && !hasError) &&
          <div id={style.expandIcon} className={state.isFullscreen ? style.fullscreen : ""}>
            <Button icon={state.isFullscreen ? faClose : faExpand} onClick={maximize} variant={"contained"} />
          </div>
        }
      </div >
    </div>
  );
}

/**
 * Component that displays a Cyclomedia Street Smart viewer.
 * @remarks This component is memoized to prevent unnecessary re-renders, which can cause crashes with the Cyclomedia Street Smart API.
 */
export const CyclomediaStreetSmart = memo(StreetSmart);