import { MutableRefObject } from "react";
import { SearchMode, SearchProvider, SearchResult } from "./interfaces/searchProvider";
import { VehicleMarker } from "app/models/vehicleMarker";

export default class VehicleProvider implements SearchProvider<SearchResult> {
  #vehicleMarkers: MutableRefObject<VehicleMarker[]>;

  label = "Voertuigen";
  mode = "vehicles" as SearchMode;

  constructor(vehicleMarkers: MutableRefObject<VehicleMarker[]>) {
    this.#vehicleMarkers = vehicleMarkers;
  }

  async search(searchTerm: string): Promise<SearchResult[]> {
    if (searchTerm.length < 2) {
      return [];
    }
    const st = searchTerm.toLowerCase();
    const results = this.#vehicleMarkers.current
      .filter((marker) => marker.vehicleName().toLowerCase().includes(st))
      .slice(0, 5)
      .map((marker, i) => {
        const name = marker.vehicleName();
        return {
          id: name,
          label: name,
        };
      });
    return results;
  }

  onResultTapped(result: SearchResult): void {
    const marker = this.#vehicleMarkers.current.filter((marker) => marker.vehicleName() === result.id)[0];
    marker.focusOnMap();
  }

  onCancel(): void {
    // no-op
  }
}
