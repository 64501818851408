import { useUser } from "app/hooks/useUser";
import style from "./style.module.scss";
import { UserOverview } from "./components/UserOverview";
import { useState } from "react";
import { EenheidOverview } from "./components/EenheidOverview";
import { LoadingComponent } from "app/components/LoadingComponent";
import { SettingsOverview } from "./components/SettingsOverview";

type ContentState = "Overview" | "Settings";

/**
 * Modal for managing user setting
 */
export function UserSettingsModal({ onClose }: Readonly<{ onClose: () => void }>) {
  const { user, isLoading } = useUser();
  const [contentState, setContentState] = useState<ContentState>("Overview");

  /**
   * Render the correct content based on the current state
   * @returns  The correct content based on the current state
   */
  function renderSwitch(): JSX.Element {
    if (isLoading)
      return <LoadingComponent />;

    switch (contentState) {
      case "Overview":
        return user?.isEenheid ? <EenheidOverview /> : <UserOverview />;
      case "Settings":
        return <SettingsOverview />;
    }
  }

  return (
    <div className={style.modal}>
      <div className={style.menu}>
        <ul>
          <li onClick={() => setContentState("Overview")} className={contentState == "Overview" ? style.active : ""}>Overzicht</li>
          <li onClick={() => setContentState("Settings")} className={contentState == "Settings" ? style.active : ""}>Instellingen</li>
          <li onClick={onClose}>Sluiten</li>
        </ul>
      </div>
      <div className={style.content}>
        {renderSwitch()}
      </div>
    </div>
  );
}