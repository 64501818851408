import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis Gasfles layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/1800}
 */
export const GasflesDefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.gasfles,
  layers: [
    {
      layerId: 1800,
      layerDefinitions: [
        {
          "id": LayerLabels.gasfles,
          "type": "circle",
          "paint": {
            "circle-radius": 4,
            "circle-color": "rgba(0,0,0,0)",
            "circle-stroke-width": 1,
            "circle-stroke-color": "rgba(0,0,0,255)"
          },
          "filter": ["==", ["get", "VRIJ_VELD1"], "Gasfles"]
        }
      ]
    },
  ],
  isToggledByKermisInformatieLayer: true
};