import MapboxDraw, { DrawCustomMode, DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";
import DragLineMode from "app/components/MapboxDrawControl/custom-modes/DragLine";
import { useMap } from "app/contexts/mapContext";
import { CustomDrawMode } from "app/components/MapboxDrawControl/models/CustomDrawingMode";
import { useState } from "react";


const customModes: { [modeKey: string]: DrawCustomMode<any, any> } = {
  drag_line: DragLineMode,
  // Add more custom modes here if needed
};


const defaultDraw = new MapboxDraw({
  modes: {
    ...customModes,
    ...MapboxDraw.modes
  },
  userProperties: true,
  styles: [
    // Custom styles dont work well with default styles
    // Once we have a full spec of styles we should use them instead
    // measurementLine,
    // measurementText,
    ...MapboxDraw.lib.theme
  ],
  touchEnabled: true,
  touchBuffer: 5,
  displayControlsDefault: false,

});

/**
 * Hook to access the draw controls
  * @returns draw controls
 */
export function useDraw() {
  const [draw, setDraw] = useState<MapboxDraw | null>(null);
  const [drawMode, setDrawMode] = useState<MapboxDraw.DrawMode | CustomDrawMode>("simple_select");
  // draw control interferes with propagation of touch events, this a workaround
  // this should be fixed in the future by mapbox
  // issue can be tracked, also there is a open PR
  // https://github.com/mapbox/mapbox-gl-draw/issues/617
  // https://github.com/mapbox/mapbox-gl-draw/pull/869
  // This is a very tedious issue, and it is not clear if it will be fixed in the near future
  const [active, setActive] = useState(false);

  useMap(map => {
    if (draw && !active) {
      map.removeControl(draw);
      setDraw(null);
    }

    if (!draw && active) {
      map.addControl(defaultDraw);
      setDraw(defaultDraw);
      map.on("draw.modechange", (e: DrawModeChangeEvent) => {
        setDrawMode(e.mode);
      });
      setDrawMode("simple_select");
    }

    if (draw && active)
      return;

    if (!draw && !active)
      return;

  }, [active]);

  const toggleDraw = function toggle(explicitValue?: boolean) {
    if (explicitValue !== undefined)
      setActive(explicitValue);
    else
      setActive(!active);
  };

  return { draw, toggleDraw, drawMode, setDrawMode, active };
}