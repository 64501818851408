import { CustomCyclomediaEvents } from "app/components/Cyclomedia/models/CustomCyclomediaEvents";
import { logger } from "./app/services/logger";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventHandler = (...args: any[]) => void;

export enum CustomEvents {
  UpdateAvailable = "updateAvailable",
  PopupClosed = "popupClosed",
  PopupOpened = "popupOpened",
  CloseAllTools = "closeAllTools",
  OpenUserSettings = "openUserSettings",
  OpenModal = "openModal",
  OpenNotificationBar = "openNotificationBar",
  OpenIncident = "openIncident",
  SetIncidentMarker = "setIncidentMarker",
  ClearIncidentMark = "clearIncidentMarker",
  SetTimer = "setTimer",
  StopTimer = "stopTimer"
}

/**
 * Custom Event Dispatcher
 * @remarks Used to trigger custom events from the service worker
 */
class EventDispatcher {
  #events: { [eventName: string]: EventHandler[] } = {};

  /**
   * Subscribe to an event
   * @param eventName custom event to subscribe to
   * @param handler function that will be called whenever event has been triggered
   */
  on(eventName: CustomEvents | CustomCyclomediaEvents, handler: EventHandler) {
    if (!this.#events[eventName]) {
      this.#events[eventName] = [];
    }

    logger.verbose(`[EventDispatcher] Subscribed to event: ${eventName}`);
    this.#events[eventName].push(handler);
  }

  /**
   * Unsubscribe from an event
   * @param eventName custom event to unsubscribe from
   * @param handler function that is subscribed to the event
   */
  off(eventName: string, handler: EventHandler) {
    if (!this.#events[eventName]) {
      return;
    }

    logger.verbose("[EventDispatcher] Unsubscribed from event: " + eventName);
    this.#events[eventName] = this.#events[eventName].filter((eventHandler) => eventHandler !== handler);
  }

  /**
   * Trigger an event
   * @param eventName custom event to trigger
   * @param args arguments to pass to the event handler
   */
  emit(eventName: CustomEvents | CustomCyclomediaEvents, ...args: unknown[]) {
    if (!this.#events[eventName]) {
      logger.verbose("[EventDispatcher] No subscribers for event: " + eventName, this.#events);
      return;
    }

    logger.verbose("[EventDispatcher] Emitting event: " + eventName, { args: args });
    this.#events[eventName].forEach((eventHandler) => eventHandler(...args));
  }
}


export default new EventDispatcher();
