import { AccessAlarms } from "@mui/icons-material";
import style from "./style.module.scss";
import { Accordion } from "app/components/Accordion";
import TimerComponent from "app/components/Timer";
import { EenheidStatus } from "app/models/eenheidStatus";
import { Incident } from "app/models/incident";
import { LoadingComponent } from "app/components/LoadingComponent";

export function IncidentEenheden({ incident, eenheden }: Readonly<{ eenheden: EenheidStatus[] | undefined, incident: Incident | undefined }>) {
  const mappedEenheden = incident?.incidentNr.toString().startsWith("999") ? incident.eenheden.map(x => {
    return {
      roepnaamEenheid: x.roepnaamEenheid,
      tActStatusAfkBrw: undefined,
      codeVoertuigSoort: x.codeVoertuigsoort,
      kazNaam: x.kazNaam
    } as EenheidStatus;
  }) : eenheden;


  if (!mappedEenheden || !eenheden)
    return <LoadingComponent />;

  const activeStatusses = ["ut", "tp", "ov"];
  const activeEenheden = mappedEenheden.filter(eenheid => activeStatusses.includes(eenheid.tActStatusAfkBrw ?? ""));

  const otherEenheden = [
    ...mappedEenheden.filter(eenheid => !activeStatusses.includes(eenheid.tActStatusAfkBrw ?? "")),
    ...(incident?.eenheden
      .filter(eenheid => !mappedEenheden.some(mappedEenheid => mappedEenheid.roepnaamEenheid === eenheid.roepnaamEenheid))
      .map(eenheid => ({
        roepnaamEenheid: eenheid.roepnaamEenheid,
        tActStatusAfkBrw: undefined,
        codeVoertuigSoort: eenheid.codeVoertuigsoort,
        kazNaam: eenheid.kazNaam
      }) as EenheidStatus) || [])
  ];
  const isIncidentClosed = incident?.endDate ? incident.endDate > incident.startDate : false;

  return (
    <>
      <Accordion className={style.activeEenheden} title={"Eenheden"} titleStyle={"ACTIVE"}>
        <table>
          <tbody>
            {
              activeEenheden.map((v) => (
                <tr key={crypto.randomUUID()} className={style.activeEenheid}>
                  <td>{v.roepnaamEenheid}</td>
                  <td>{v.tActStatusAfkBrw && <>({v.tActStatusAfkBrw})</>}</td>
                  <td>{v.codeVoertuigSoort}</td>
                  <td>{v.kazNaam?.replace(/\d/g, "").trim()}</td>
                  {!isIncidentClosed && v.tDtgKoppel && <td><div className={style.timer}><TimerComponent startTime={v.tDtgKoppel} /><AccessAlarms /></div></td>}
                </tr>
              ))
            }
            {activeEenheden.length == 0 &&
              <tr className={style.activeEenheid}>
                <td> Er zijn geen eenheden gekoppeld</td>
              </tr>
            }
          </tbody>
        </table>
      </Accordion>
      <Accordion className={style.otherEenheden} title={"Retour"} titleStyle={"INACTIVE"}>
        <table>
          <tbody>
            {
              otherEenheden.map((v) => (
                <tr key={crypto.randomUUID()} className={style.inActiveEenheid}>
                  <td>{v.roepnaamEenheid}</td>
                  <td>{v.codeVoertuigSoort}</td>
                  <td>{v.kazNaam?.replace(/\d/g, "").trim()}</td>
                </tr>
              ))
            }
            {otherEenheden.length == 0 &&
              <tr className={style.inActiveEenheid}>
                <td> Er zijn geen eenheden gekoppeld</td>
              </tr>
            }
          </tbody>
        </table>
      </Accordion>
    </>
  );
}