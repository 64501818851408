import {BaseFormField} from "./BaseFormField";
import {MenuItem, Select} from "@mui/material";

/**
 * Item displayed within the select
 */
export type Item = {
  value: string;
  name: string;
}

/**
 * Data class for the SelectFormField component
 */
export class SelectFormFieldData extends BaseFormField{
  items : Item[];
  onSelectChanged: any;

  /**
   * Default constructor
   * @param displayName Name that will be displayed in the label
   * @param fieldName Name that is used for the FormData
   * @param defaultValue Default value
   * @param items Items that are selectable
   * @param onSelectChanged Function triggered when selection has changed
   */
  constructor(displayName: string, fieldName: string, defaultValue: string, items: Item[], onSelectChanged: any) {
    super(displayName, fieldName, defaultValue);
    this.onSelectChanged = onSelectChanged;
    this.items = items;
  }
}

type Props = {
  field: SelectFormFieldData,
};

/**
 * Dropdown selection field
 * @param field
 * @constructor
 */
export function SelectFormField({field}: Props) {
  return (
    <div className="select-field from-field">
      <label className="input-label">{field.displayName}</label>
      <Select value={field.value} onChange={field.onSelectChanged} className="dropdown">
        {field.items.map((x) =>
          <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem>
        )}
      </Select>
    </div>
  );
}