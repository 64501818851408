import {createContext, Dispatch, useMemo, useReducer} from "react";
import {PopupContent} from "../models/popupContent";

/**
 * State of the popup
 */
interface MapPopupState {

  /**
   * Current position of the popup
   */
  position: [number, number] | undefined;

  /**
   * Current popup content
   */
  content: PopupContent[];
}

export const MapPopupContext = createContext<MapPopupContextCompound>({} as MapPopupContextCompound);

type ActionType
  = { type: "ADD_CONTENT" | "RESET_CONTENT", payload: PopupContent | undefined };

type MapPopupContextCompound = MapPopupState & {
  mapPopupDispatch: Dispatch<ActionType>;
}

function mapPopupContextReducer(state: MapPopupState, action: ActionType): MapPopupState {
  if (action.type === "ADD_CONTENT") {
    console.log("[MapPopupContext] Adding content", action.payload);
    if(!action.payload)
      throw new Error("Payload is undefined");

    return {...state, content: [...state.content, action.payload]};
  }else if(action.type === "RESET_CONTENT") {
    return {...state, content: []};
  }

  throw new Error("Unhandled action type");
}

type Props = {
  children?: JSX.Element;
};

export function MapPopupProvider({ children }: Readonly<Props>) {
  const [mapPopupContext, mapPopupDispatch] = useReducer(mapPopupContextReducer, {position: undefined, content: []});
  const providerValue = useMemo(() => ({ ...mapPopupContext, mapPopupDispatch }), []);
  return (
    <MapPopupContext.Provider value={providerValue}>
      {children}
    </MapPopupContext.Provider>
  );
}