import {Box, CircularProgress, SelectChangeEvent, Typography} from "@mui/material";
import "./style.scss";
import {useState} from "react";
import {SelectFormField, SelectFormFieldData} from "app/components/FormFields/SelectFormField";
import {TextFormField, TextFormFieldData} from "app/components/FormFields/TextFormField";
import {FileFormField, FileFormFieldData} from "app/components/FormFields/FileFormField";
import {useResourceFetch} from "app/hooks/useResourceFetch";
import {toBlob} from "app/services/base64FileHandling";
import {DetermineApiUri} from "../../../../../config";
import { toast } from "react-toastify";

type Props = {
  onClose: () => void,
};

export function FeedbackForm({onClose}: Props) {
  const protectedFetch = useResourceFetch();
  const [loading, setLoading] = useState<boolean>(false);
  const [feedbackType, setFeedbackType] = useState<SelectFormFieldData>(new SelectFormFieldData(
    "Feedback Type",
    "type",
    "Opmerking",
    [
      {value: "Opmerking", name: "Opmerking"},
      {value: "Storing", name: "Storing"},
      {value: "Tips", name: "Tips"},
      {value: "Ideëen", name: "Ideëen"}
    ],
    (event: SelectChangeEvent) => setFeedbackType({...feedbackType, value: event.target.value})
  ));
  const [feedbackNaam, setFeedbackNaam] = useState<TextFormFieldData>(new TextFormFieldData(
    "Naam",
    "name",
    "",
    (event: React.ChangeEvent<HTMLInputElement>) => setFeedbackNaam({...feedbackNaam, value: event.target.value})));
  const [feedbackOnderwerp, setFeedbackOnderwerp] = useState<TextFormFieldData>(new TextFormFieldData(
    "Onderwerp",
    "subject",
    "",
    (event: React.ChangeEvent<HTMLInputElement>) => setFeedbackOnderwerp({
      ...feedbackOnderwerp,
      value: event.target.value
    })));
  const [feedbackInhoud, setFeedbackInhoud] = useState<TextFormFieldData>(new TextFormFieldData(
    "Inhoud",
    "content",
    "",
    (event: React.ChangeEvent<HTMLInputElement>) => setFeedbackInhoud({...feedbackInhoud, value: event.target.value})));
  const [attachments, setAttachments] = useState<FileFormFieldData>(new FileFormFieldData(
    "Bijlage",
    "attachments",
    "",
    true,
    true,
    (newValue: string[]) => setAttachments({...attachments, selectedFiles: newValue})
  ));

  function validate() : boolean {
    let valid = true;
    if(feedbackNaam.value.trim().length <= 0){
      setFeedbackNaam({...feedbackNaam, errors: `${feedbackNaam.displayName} is een verplicht veld`});
      valid = false;
    }
    if(feedbackOnderwerp.value.trim().length <= 0){
      setFeedbackOnderwerp({...feedbackOnderwerp, errors: `${feedbackOnderwerp.displayName} is een verplicht veld`});
      valid = false;
    }
    if(feedbackInhoud.value.trim().length <= 0){
      setFeedbackInhoud({...feedbackInhoud, errors: `${feedbackInhoud.displayName} is een verplicht veld`});
      valid = false;
    }
    return valid;
  }

  async function onSubmit() {
    setLoading(true);
    const valid = validate();
    if(!valid){
      setLoading(false);
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < attachments.selectedFiles.length; i++) {
      const blob = await toBlob(attachments.selectedFiles[i]);
      const file = new File([blob], `${feedbackType.value.toLowerCase().trim()}-${feedbackNaam.value.toLowerCase().trim()}-${Date.now()}-{i}.png`);
      formData.append(attachments.fieldName, file);
    }

    formData.append(feedbackNaam.fieldName, feedbackNaam.value);
    formData.append(feedbackType.fieldName, feedbackType.value);
    formData.append(feedbackOnderwerp.fieldName, feedbackOnderwerp.value);
    formData.append(feedbackInhoud.fieldName, feedbackInhoud.value);

    try {
      const response = await protectedFetch(`${DetermineApiUri()}/api/feedback`, {
        method: "POST",
        body: formData
      });
    } catch (error) {
      toast.info(`Er is iets mis gegaan met het uploaden van de afbeelding. Foutmelding: ${error}`, {
        position: "top-center",
        hideProgressBar: true,
        theme: "colored",
        closeOnClick: true,
        toastId: "on-image-upload-error"
      });

      setLoading(false);
      return;
    }
    
    setLoading(false);
    onClose();
  }


  return (
    <div id="feedback-box-wrapper">
      <Box sx={{boxShadow: 24}} id="feedback-box">
        <div className="row header">
          <Typography variant="h4">{feedbackType.displayName}</Typography>
        </div>
        <div className="row">
          <SelectFormField field={feedbackType}/>
        </div>
        <div className="row">
          <TextFormField field={feedbackNaam} multiline={false}/>
        </div>
        <div className="row">
          <TextFormField field={feedbackOnderwerp} multiline={false}/>
        </div>
        <div className="row">
          <TextFormField field={feedbackInhoud} multiline={true}/>
        </div>
        <div className="row attachment-wrapper">
          <FileFormField field={attachments} />
        </div>
        <div className="row button-row">
          <button onClick={onClose}>Sluiten</button>
          <Box sx={{ width: "50%", position: "relative" }}>
            <button style={{width: "100%", opacity: loading ? "0.5" : "1"}} onClick={onSubmit} className="full">Versturen</button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </div>
      </Box>
    </div>
  );
}