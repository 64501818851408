import style from "./style.module.scss";

export function LoadingComponent(){
  return (
    <div className={style.loadingComponent}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
