import { Karakteristiek } from "app/models/incident";
import style from "./style.module.scss";
import { Accordion } from "../../../../../Accordion";
import { LoadingComponent } from "app/components/LoadingComponent";

/**
 * Component showing the characteristics of an incident
 */
export function IncidentKarakteristieken({ karakteristieken }: Readonly<{ karakteristieken: Karakteristiek[] | undefined }>) {
  if (!karakteristieken)
    return <LoadingComponent />;

  return (
    <Accordion className={style.karakteristiekAccordion} title="Karakteristieken" titleStyle="ACTIVE" isOpenInitially={false}>
      {karakteristieken.length === 0 && <div>Er zijn geen karakteristieken ingevuld</div>}
      <table>
        <tbody>
          {
            karakteristieken.map((karakteristiek) => (
              <tr key={karakteristiek.karakteristiekId} className={style.karakteristiek}>
                <td className={style.label}>{karakteristiek.naamKarakteristiek}:</td>
                <td className={style.value}>{`${karakteristiek.actueleKarWaarde.charAt(0).toUpperCase()}${karakteristiek.actueleKarWaarde.slice(1)}`}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Accordion>
  );
}