import {createRoot} from "react-dom/client";
import {PublicClientApplication} from "@azure/msal-browser";
import {App} from "./app/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {DetermineApplicationInsightsConnectionString, msalConfig} from "./config";
import {AppConfigurationProvider} from "app/hooks/useAppConfiguration";
import {MsalProvider} from "@azure/msal-react";
import {createTheme, ThemeProvider} from "@mui/material";
import {createBrowserHistory} from "history";
import "./index.css";
import {AppInsightsContext, ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {AuthGuard} from "./app/components/AuthGuard";

// Setup MSAL
export const msalInstance = new PublicClientApplication(msalConfig);

// Setup Application Insights
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: DetermineApplicationInsightsConnectionString(),
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    // autoTrackPageVisitTime: true,
    // enableAutoRouteTracking: true,
    // enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
  }
});

appInsights.loadAppInsights();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
globalThis.applicationInsights = appInsights;

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#c4314b",
    },
    secondary: {
      main: "#ffffff",
    }
  }
});

const container = document.getElementById("root");
if (!container) {
  throw new Error("container must not be undefined");
}
const root = createRoot(container);

root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <AuthGuard>
          <AppConfigurationProvider>
            <App/>
          </AppConfigurationProvider>
        </AuthGuard>
      </ThemeProvider>
    </MsalProvider>
  </AppInsightsContext.Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
