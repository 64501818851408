interface Props{
  active: number | undefined;
  druk: number | undefined;
}

/**
 * Component for displaying lagedruk pomp telemetry information
 * @param data
 * @component
 */
export function TelemetryLagedrukPomp({active, druk}: Readonly<Props>) {
  return(
    <div className="telemetry-pomp">
      <div className="column">
        <div className={"header"}>Lagedrukpomp:</div>
      </div>
      <div className={"column"}>
        <span>{active ? "Actief" : "Inactief"}</span>
        <span>{druk ?? 0} bar</span>
      </div>
    </div>
  );
}