import * as React from "react";
import { SVGProps } from "react";

const SvgDrizzle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="drizzle_svg__a" viewBox="0 0 359 231">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2 56 56 0 0 0-84.6 48.1 56.6 56.6 0 0 0 .8 9 60 60 0 0 0 11.2 119"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#drizzle_svg__a"
      width={359}
      height={231}
      transform="translate(76.5 140.5)"
    />
    <path
      fill="#0a5ad4"
      d="M200 376a8 8 0 0 1-8-8v-12a8 8 0 0 1 16 0v12a8 8 0 0 1-8 8Zm56 80a8 8 0 0 1-8-8v-12a8 8 0 0 1 16 0v12a8 8 0 0 1-8 8Zm56-50a8 8 0 0 1-8-8v-12a8 8 0 0 1 16 0v12a8 8 0 0 1-8 8Z"
    />
  </svg>
);

export default SvgDrizzle;
