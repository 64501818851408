import { SharedMapConfigurationContext, useMap } from "app/contexts/mapContext";
import { useContext } from "react";
import { VisualMode } from "app/models/mapConfiguration";
import mapboxgl from "mapbox-gl";

export function ReplaceBuildingNumberLabelLayer() {
  const { sharedMapConfiguration } = useContext(SharedMapConfigurationContext);

  // First load:
  useMap(map => {
    ReplaceLayer(map, sharedMapConfiguration.visualMode);
  }, []);

  // Toggle on 'visualMode':
  useMap(map => {
    map.once("styledata", () => {
      ReplaceLayer(map, sharedMapConfiguration.visualMode);
    });

  }, [sharedMapConfiguration.visualMode]);

  return <></>;
}

/**
 * Replace or adds the Building-number-label layer to the map.
 * @param map Map object
 * @param visualMode The visual mode (dark/light). 
 */
function ReplaceLayer(map: mapboxgl.Map, visualMode: VisualMode) {
  const layerId = "building-number-label";
  const currentLayer = map.getLayer(layerId);

  // Determin index of current layer:
  const layers = map.getStyle().layers;
  let currentLayerIndex = layers.findIndex(l => l.id == layerId);
  if (currentLayerIndex == -1) {
    // Current layer doesn't exist in Darkmode fallback to 'building' layer:
    currentLayerIndex = layers.findIndex(l => l.id == "building");
  }

  // Remove layer if exists:
  if (currentLayer) {
    map.removeLayer(layerId);
  }

  // Create json and add new layer:
  const layerJson = GenerateLayerJson(visualMode, map.getStyle().name);
  map.addLayer(layerJson, layers[currentLayerIndex + 1].id);
}

/**
 * Generate Json for Housenumber layer.
 * @param visualMode The visual mode (dark/light). This determines the color of the text.
 * @param styleName The stylename (dark/light). This determines the color of the text.
 */
function GenerateLayerJson(visualMode: VisualMode, styleName: string | undefined): mapboxgl.SymbolLayer {
  const textColor = (visualMode != VisualMode.Dark && styleName != "Mapbox Dark")
    ? "hsl(0, 0%, 0%)"
    : "hsl(0, 0%, 100%)";

  return (
    {
      "minzoom": 17,
      "layout": {
        "text-field": [
          "get",
          "house_num"
        ],
        "text-font": [
          "DIN Pro Regular",
          "Arial Unicode MS Regular"
        ],
        "text-padding": 4,
        "text-max-width": 7,
        "text-size": 12
      },
      "filter": [
        "step",
        [
          "pitch"
        ],
        true,
        50,
        [
          "<",
          [
            "distance-from-center"
          ],
          1
        ],
        60,
        [
          "<",
          [
            "distance-from-center"
          ],
          1.5
        ],
        70,
        [
          "<",
          [
            "distance-from-center"
          ],
          2
        ]
      ],
      "type": "symbol",
      "source": "composite",
      "id": "building-number-label",
      "paint": {
        "text-color": textColor,
        "text-halo-color": "hsl(0, 0%, 18%)",
        "text-halo-width": 0
      },
      "source-layer": "housenum_label"
    }
  );
}