import mapboxgl, { LngLatLike, MapboxOptions, RequestParameters } from "mapbox-gl";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import "./style.scss";
import { useAppConfiguration } from "app/hooks/useAppConfiguration";
import { AppConfiguration } from "app/models/appConfiguration";
import { logger } from "app/services/logger";
mapboxgl.accessToken = "pk.eyJ1IjoiZGltaXRyeS1yZWJlbGl0IiwiYSI6ImNsOW8zaG9qeTBlZXczcG56enhwN3oyN2IifQ.i6CaM2N0E4ur_GtTE_KQiA";

type Props = {
  containerId: string;
  setMap: Dispatch<SetStateAction<mapboxgl.Map | null>>;
}

export function MapboxMap({ containerId, setMap }: Readonly<Props>) {
  const map = useRef<mapboxgl.Map>();
  const appConfiguration = useAppConfiguration();
  const config = useMapboxOptions(appConfiguration);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({ ...config, container: containerId }).once("load", () => {
      if (!map.current)
        return;

      setMap(map.current);
      map.current?.on("error", (e) => {

        // We filter out this message cause it's a known issue that the async image upload is not working properly with states in react.
        if(e.error.message.startsWith("An image with this name already exists."))
          return;

        logger.error("Error occurred within mapbox", e.error);
      });
    });
  });

  return <div id={containerId} />;
}

export const MemoizedMapboxMap = React.memo(MapboxMap);

function useMapboxOptions(appConfig: AppConfiguration) : MapboxOptions {
  return {
    style: "mapbox://styles/mapbox/streets-v12",
    center: [4.775692, 51.591161] as LngLatLike,
    zoom: 10,
    doubleClickZoom: true,
    maxZoom: 21,
    minZoom: 8,
    refreshExpiredTiles: false,
    container: "",
    transformRequest: (url: string): RequestParameters => {
      try {
        if(url.indexOf(appConfig.geoserverBaseUri) > -1) {
          return {
            url: url,
            headers: {"Authorization": `Basic ${appConfig.geoServerAuthenticationKey}`},
            credentials: "include"
          };
        } else if (url.indexOf(appConfig.cogoServerUrl) > -1) {
          return {
            url: url,
            headers: {"Authorization": `Basic ${appConfig.cogoEncodedBasicAuth}`},
            credentials: "include"
          };
        }
        return { url: url };
      } catch (e: unknown) {
        logger.error("Error occured while transforming requests within mapbox", e as Error);
        return { url: url };
      }
    }
  };
}
