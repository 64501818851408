import { AddAPhoto } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import { useResourceFetch } from "app/hooks/useResourceFetch";
import React, { useContext } from "react";
import { useSWRConfig } from "swr";
import { CameraFeed } from "./CameraFeed";
import "./style.scss";
import CloseIcon from "@mui/icons-material/Close";
import { SharedMapConfigurationContext } from "app/contexts/mapContext";
import { SharedMapConfiguration, VisualMode } from "app/models/mapConfiguration";
import { DetermineApiUri } from "../../../../../config";
import { toast } from "react-toastify";

type Props = {
  incidentId: number,
}

export default function CameraView({ incidentId }: Readonly<Props>) {
  const protectedFetch = useResourceFetch();
  const { mutate } = useSWRConfig();
  const { sharedMapConfiguration } = useContext<SharedMapConfiguration>(SharedMapConfigurationContext);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function onSubmit(blob: Blob) {
    const formData = new FormData();
    const file = new File([blob], `${incidentId}-${Date.now()}.png`);
    formData.append("postedImage", file);
    formData.append("incidentId", String(incidentId));

    try {
      const uploadResponse = await protectedFetch(`${DetermineApiUri()}/api/upload`, {
        method: "POST",
        body: formData
      });
    } catch (error) {
      toast.info(`Er is iets mis gegaan met het uploaden van de afbeelding. Foutmelding: ${error}`, {
        position: "top-center",
        hideProgressBar: true,
        theme: "colored",
        closeOnClick: true,
        toastId: "on-image-upload-error"
      });

      return;
    }

    await mutate(`${DetermineApiUri()}/api/media/images/${incidentId}`);
    handleClose();
  }

  return (
    <div className="cameraUpload">
      <div className={"button"} onClick={handleOpen}>
        <AddAPhoto className="icon" />
      </div>
      <Modal
        open={open}
        className={sharedMapConfiguration.visualMode == VisualMode.Light ? "camera-modal" : "camera-modal dark"}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={true}
      >
        <Box>
          <button className="icon-button inverted close-button" onClick={handleClose}>
            <CloseIcon />
          </button>
          <CameraFeed onCapture={(blob: Blob) => onSubmit(blob)} />
        </Box>
      </Modal>
    </div>
  );
}
