import {useEffect, useRef, useState} from "react";
import mapboxgl, {LngLat, Popup} from "mapbox-gl";
import {useMap} from "../../contexts/mapContext";
import {createRoot} from "react-dom/client";
import TemplateManager from "./TemplateManager";
import EventDispatcher, {CustomEvents} from "../../../eventDispatcher";
import {PopupContent} from "../../models/popupContent";

/**
 * Popup for the map
 */
export function MapboxPopup() {
  const [popup, setPopup] = useState<Popup | undefined>();
  const [position, setPosition] = useState<LngLat | undefined>();
  const [content, setContent] = useState<PopupContent[]>([]);
  const stateRef = useRef(content);

  stateRef.current = content;

  useEffect(() => {
    EventDispatcher.on(CustomEvents.PopupOpened, (e) => {
      OnPopupOpened(e as PopupContent);
    });

    return () => {
      if (!popup)
        return;

      popup.remove();
    };
  }, []);

  /**
   * Add a click event to the map
   */
  useMap(map => {
    map.on("click", e => {
      setPosition(e.lngLat);
    });
  }, []);

  /**
   * Update the position of the popup
   */
  useMap(map => {
    if (!position)
      return;

    setContent([]);
    stateRef.current = [];
  }, [position]);

  /**
   * Update the content of the popup
   */
  useMap(map => {
    if (!popup && content.length > 0) {
      InitializePopup(map);
    } else {
      UpdateContent(map);
    }
  }, [content]);

  /**
   * Add a new popup content
   * @param newContent
   * @constructor
   */
  function OnPopupOpened(newContent: PopupContent) {
    setContent([...stateRef.current, newContent]);
  }

  /**
   * Initialize the popup
   * @param map
   * @constructor
   */
  function InitializePopup(map: mapboxgl.Map) {
    const placeholder = document.createElement("div");
    const root = createRoot(placeholder);
    root.render(<TemplateManager content={content}/>);

    const newPopup = new Popup()
      .setMaxWidth("400px")
      .setLngLat(position as LngLat)
      .setDOMContent(placeholder)
      .on("close", () => {
        EventDispatcher.emit(CustomEvents.PopupClosed);
      })
      .addTo(map);

    setPopup(newPopup);
  }

  /**
   * Update the content of the popup
   * @param map
   * @constructor
   */
  function UpdateContent(map: mapboxgl.Map) {
    if (!popup)
      return;

    if (content.length == 0) {
      popup.remove();
      setPopup(undefined);
      return;
    }

    const placeholder = document.createElement("div");
    const root = createRoot(placeholder);
    root.render(<TemplateManager content={content}/>);

    popup
      .setLngLat(position as LngLat)
      .setDOMContent(placeholder)
      .addTo(map);
  }

  return (<></>);
}