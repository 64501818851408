import { useEffect, useState } from "react";
import { DisciplineIndicator } from "../../../../../DisciplineIndicator";
import { TimelineItem } from "./TimelineItem";
import style from "./style.module.scss";
import { useKladblok } from "../../../../../../hooks/useKladblok";
import { LoadingComponent } from "../../../../../LoadingComponent";
import { Incident } from "app/models/incident";
import { Checkbox } from "app/components/Checkbox";

type Props = {
  incident: Incident | undefined
  isMinimized: boolean | null,
  scrollToElement: React.RefObject<HTMLElement> | null,
  toggleMinimized: () => void
};


export interface Filters {
  Image: boolean
}

export function IncidentTimeLine({ incident, isMinimized, scrollToElement, toggleMinimized }: Readonly<Props>) {
  const [activeFilters, setActiveFilters] = useState<Filters>({
    Image: true
  });
  const [forcedOpen, setForcedOpen] = useState<boolean>(false);
  const {
    isIsOtherDisciplinesPrivacyFiltered,
    isPrivacyFiltered,
    kladblokRegels,
    isLoading,
    newKladblokRegels
  } = useKladblok({
    incidentId: incident?.incidentId ?? null,
    forceOpen: forcedOpen,
    isIncidentClosed: incident
      ? incident.endDate > incident.startDate
      : false,
    isMinimized: isMinimized ?? false,
    toggleMinimized
  });

  useEffect(() => {
    if (newKladblokRegels && scrollToElement?.current) {
      scrollToElement.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [newKladblokRegels]);

  if (!incident)
    return <></>;

  if (isLoading)
    return <LoadingComponent />;

  if (!kladblokRegels)
    return <></>;

  return (
    <div className={"content-wrapper"}>
      <div id={style.filters}>
        <Checkbox label={"Afbeeldingen"} checked={activeFilters.Image} onChange={() => setActiveFilters({ ...activeFilters, Image: !activeFilters.Image })} variant="contained" />
      </div>
      {(isIsOtherDisciplinesPrivacyFiltered || isPrivacyFiltered) &&
        <div id={style.forceOpen}>
          {isIsOtherDisciplinesPrivacyFiltered && !isPrivacyFiltered && <p>In verband met privacy zijn de kladblokregels Politie en Ambulance verborgen. Om deze toch in te zien, druk op onderstaande knop</p>}
          {isPrivacyFiltered && <p>In verband met privacy zijn de kladblokregels verborgen. Om deze toch in te zien, druk op onderstaande knop</p>}
          <b> Let op, inzage wordt gemonitord.</b>
          <button onClick={() => {
            setForcedOpen(true);
          }}>Toon kladblokregels
          </button>
        </div>
      }
      <div className={style.incidentTimeline}>
        {
          kladblokRegels
            .filter(regel => activeFilters.Image && regel.type == "Image" || regel.type != "Image")
            .map(regel => {
              return (
                <div
                  className={`${style.itemContainer} ${newKladblokRegels.some(x => x.dateCreated == regel.dateCreated) ? style.new : ""}`}
                  key={`${regel.dateCreated};${regel.userName};${regel.id};${regel.volgNr}`}>
                  {regel.type != "Image" && <DisciplineIndicator disciplines={regel.discipline} />}
                  <TimelineItem regel={regel} incidentId={incident.incidentId ?? 0} />
                </div>
              );
            })
        }
      </div>
    </div>
  );
}
