import {MachineTelemetry} from "../../../../models/machineTelemetry";
import {TelemetryAccu} from "../components/TelemetryAccu";
import {TelemetryUtil} from "../../../../helpers/telemetryUtil";
import {TelemetryTanks} from "../components/TelemetryTanks";
import {TelemetryHogedrukPomp} from "../components/TelemetryHogedrukPomp";
import {TelemetryLagedrukPomp} from "../components/TelemetryLagedrukPomp";

const MeasurementKeys = {
  AccuSpanning: "value.Application.Chassis.AccuSpanning",
  NiveauWatertank: "value.Application.Opbouw.Watertankniveau",
  NiveauSchuimtank: "value.Application.Opbouw.SchuimtankniveautankA",
  NiveauBrandstof: "value.Application.Chassis.Brandstofniveau",
  NiveauAdBlue: "value.Application.Chassis.NiveauAdBlue",
  NiveauAdBlueOpbouw: "value.Application.Opbouw.NiveauAdBlue",
  HogedrukpompActief: "value.Application.Opbouw.Hogedrukpompactief",
  HogedrukpompDruk: "value.Application.Opbouw.HDpompdruk",
  LagedrukpompActief: "value.Application.Opbouw.Lagedrukpompactief",
  LagedrukpompDruk: "value.Application.Opbouw.NDpompdruk",
};

interface Props {
  telemetry: MachineTelemetry
}

/**
 * Component for displaying Tankautospuit telemetry information
 * @param telemetry
 * @constructor
 */
export function Tankautospuit({telemetry} : Readonly<Props>){
  const accuSpanning = TelemetryUtil.extractValue(telemetry, MeasurementKeys.AccuSpanning);
  const hogeDrukPompActief = TelemetryUtil.extractValue(telemetry, MeasurementKeys.HogedrukpompActief);
  const hogeDrukPompDruk = TelemetryUtil.extractValue(telemetry, MeasurementKeys.HogedrukpompDruk);
  const lageDrukPompActief = TelemetryUtil.extractValue(telemetry, MeasurementKeys.LagedrukpompActief);
  const lageDrukPompDruk = TelemetryUtil.extractValue(telemetry, MeasurementKeys.LagedrukpompDruk);
  const niveauWatertank = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauWatertank, 0);
  const niveauSchuimtank = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauSchuimtank,0);
  const niveauBrandstof = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauBrandstof,0);

  return(
    <>
      <TelemetryAccu accuSpanning={accuSpanning} />
      <TelemetryHogedrukPomp active={hogeDrukPompActief} druk={hogeDrukPompDruk} />
      <TelemetryLagedrukPomp active={lageDrukPompActief} druk={lageDrukPompDruk} />
      <TelemetryTanks watertankNiveau={niveauWatertank} schuimtankNiveau={niveauSchuimtank} brandstoftankNiveau={niveauBrandstof} />
    </>
  );
}