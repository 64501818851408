import React, { createContext, useContext, useEffect, useReducer } from "react";
import useSWR from "swr";

// VersionState interface from your excerpt
interface VersionState {
  version: string;
  hasNewVersion: boolean;
}

interface VersionAction {
  type: "SET_VERSION" | "SET_NEW_VERSION_AVAILABLE";
  payload: any;
}

const initialState: VersionState = {
  version: "1.0.0",
  hasNewVersion: false,
};

const VersionContext = createContext<VersionState>(initialState);

const versionReducer = (state: VersionState, action: VersionAction): VersionState => {
  switch (action.type) {
    case "SET_VERSION":
      return {
        ...state,
        version: action.payload,
      };
    case "SET_NEW_VERSION_AVAILABLE":
      return {
        ...state,
        hasNewVersion: action.payload,
      };
    default:
      return state;
  }
};

export function VersionProvider({ children }: Readonly<{ children?: JSX.Element }>) {
  const [state, dispatch] = useReducer(versionReducer, initialState);

  const { data: versionData } = useSWR<string>("/version.txt", {
    refreshInterval: 5000,
    fetcher: (url) => fetch(url).then((res) => res.text()),
  });

  useEffect(() => {
    if (versionData) {
      if (state.version !== versionData) {
        dispatch({ type: "SET_VERSION", payload: versionData });
        if (state.version !== initialState.version) {
          dispatch({ type: "SET_NEW_VERSION_AVAILABLE", payload: true });
        }
      }
    }
  }, [versionData, state.version]);

  return <VersionContext.Provider value={state}>{children}</VersionContext.Provider>;
}

export const useVersion = () => useContext(VersionContext);
export default VersionProvider;