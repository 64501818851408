import * as React from "react";
import { SVGProps } from "react";

const SvgPartlyCloudyNightRain = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="partly-cloudy-night-rain_svg__b" viewBox="0 0 178 178">
        <path
          fill="none"
          stroke="#72b9d5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={10}
          d="M163.6 110.4a84.8 84.8 0 0 1-85.4-84.3A83.3 83.3 0 0 1 81 5 84.7 84.7 0 0 0 5 88.7 84.8 84.8 0 0 0 90.4 173a85.2 85.2 0 0 0 82.6-63.1 88 88 0 0 1-9.4.5Z"
        />
      </symbol>
      <symbol id="partly-cloudy-night-rain_svg__c" viewBox="0 0 359 231">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2 56 56 0 0 0-84.6 48.1 56.6 56.6 0 0 0 .8 9 60 60 0 0 0 11.2 119"
        />
      </symbol>
      <symbol id="partly-cloudy-night-rain_svg__g" viewBox="0 0 435.5 371.5">
        <g clipPath="url(#partly-cloudy-night-rain_svg__a)">
          <use
            xlinkHref="#partly-cloudy-night-rain_svg__b"
            width={178}
            height={178}
            transform="translate(77 118)"
          />
        </g>
        <use
          xlinkHref="#partly-cloudy-night-rain_svg__c"
          width={359}
          height={231}
          transform="translate(76.5 140.5)"
        />
      </symbol>
      <symbol id="partly-cloudy-night-rain_svg__h" viewBox="0 0 129 110">
        <path
          fill="url(#partly-cloudy-night-rain_svg__d)"
          stroke="#0a5ad4"
          strokeMiterlimit={10}
          d="M8.5 56.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
        />
        <path
          fill="url(#partly-cloudy-night-rain_svg__e)"
          stroke="#0a5ad4"
          strokeMiterlimit={10}
          d="M64.5 109.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
        />
        <path
          fill="url(#partly-cloudy-night-rain_svg__f)"
          stroke="#0a5ad4"
          strokeMiterlimit={10}
          d="M120.5 74.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
        />
      </symbol>
      <linearGradient
        id="partly-cloudy-night-rain_svg__d"
        x1={1381.3}
        x2={1399.5}
        y1={-1144.7}
        y2={-1097.4}
        gradientTransform="rotate(-9 8002.567 8233.063)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#0b65ed" />
        <stop offset={0.5} stopColor="#0a5ad4" />
        <stop offset={1} stopColor="#0950bc" />
      </linearGradient>
      <linearGradient
        xlinkHref="#partly-cloudy-night-rain_svg__d"
        id="partly-cloudy-night-rain_svg__e"
        x1={1428.4}
        x2={1446.6}
        y1={-1084.7}
        y2={-1037.4}
        gradientTransform="rotate(-9 8009.537 8233.037)"
      />
      <linearGradient
        xlinkHref="#partly-cloudy-night-rain_svg__d"
        id="partly-cloudy-night-rain_svg__f"
        x1={1489.3}
        x2={1507.5}
        y1={-1111.6}
        y2={-1064.3}
        gradientTransform="rotate(-9 8016.566 8233.078)"
      />
      <clipPath id="partly-cloudy-night-rain_svg__a">
        <path
          fill="none"
          d="M288 148a83.8 83.8 0 0 0-71.4 40 56 56 0 0 0-84.6 48 56.6 56.6 0 0 0 .8 9A60 60 0 0 0 84 304H0V0h288Z"
        />
      </clipPath>
    </defs>
    <use
      xlinkHref="#partly-cloudy-night-rain_svg__g"
      width={435.5}
      height={371.5}
    />
    <use
      xlinkHref="#partly-cloudy-night-rain_svg__h"
      width={129}
      height={110}
      transform="translate(191.5 343.5)"
    />
  </svg>
);

export default SvgPartlyCloudyNightRain;
