import { createContext, DependencyList, useContext, useEffect } from "react";
import { Map } from "mapbox-gl";
import {  SharedMapConfiguration } from "app/models/mapConfiguration";

export const MapContext = createContext<Map | null>(null);
export const SharedMapConfigurationContext = createContext<SharedMapConfiguration>(undefined as any);

type Destructor = () => void;
export type MapCallback = (map: Map) => void | Destructor;
export type MapActionCallback = (map: Map) => void;

/**
 * useMap works the same as React.useEffect except that the callback is
 * only fired when the Map is ready, changed or when the dependencies have changed.
 * Instead of using an outer lexical-scoped state, prop or variable, the Map is provided
 * as the first argument of the callback. You may only use this argument within the callback.
 * An empty dependency list doesn't have the same behaviour as React.useEffect and
 * should be avoided, because we always depend on the MapContext.
 *
 * @param cb The callback to fire.
 * @param deps The dependency list, works the same as with useEffect.
 */
export function useMap(cb: MapCallback, deps?: DependencyList) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (map) {
      return cb(map);
    }
  }, [map, ...(deps || [])]);
}

/**
 * useMapAction fires the callback immediately only when the Map is ready. It can be used to execute one-off actions.
 * @param cb The callback to fire when a trigger happens.
 * @returns The trigger.
 */
export function useMapAction(cb: MapActionCallback) {
  const map = useContext(MapContext);

  return () => {
    if (map) {
      cb(map);
    }
  };
}