import { useAppConfiguration } from "../../hooks/useAppConfiguration";
import { useUser } from "app/hooks/useUser";

export const POLICY = {
  selectMapLayers: "SelectMapLayers",
  retrieveKladblokRegels: "RetrieveKladblokRegels",
  RetrieveKladblokRegelsUnfiltered: "RetrieveKladblokRegelsUnfiltered",
  drawingToolbar: "DrawingToolbar",
  searchBOI: "SearchBOI",
};

export const ROLE = {
  Manschap: "MANSCHAP"
};

type Props = {
  children: JSX.Element
  policy: string
};

export default function PermissionsGate({ children, policy }: Props) {
  const user = useUser();
  const appConfiguration = useAppConfiguration();

  if (user.assignedApplicationRoles.length == 0)
    return <></>;

  if (!appConfiguration.policies)
    return <></>;

  const appPolicy = appConfiguration.policies.find(x => x.name == policy);

  if (!appPolicy)
    return <></>;

  const isAllowed = appPolicy.allowedRoles
    .map(x => x.toUpperCase())
    .some(x => user.assignedApplicationRoles
      .map(x => x?.toUpperCase()).includes(x));

  if (!isAllowed)
    return <></>;

  return <>{children}</>;
}