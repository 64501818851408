import { SharedMapConfigurationContext, useMap } from "app/contexts/mapContext";
import { Marker } from "mapbox-gl";
import { useContext, useState } from "react";

const userMarker = GenerateUserMarker();

export function UserGeoLocationLayer() {
  const { sharedMapConfiguration } = useContext(SharedMapConfigurationContext);
  const [userPositionWatchId, setUserPositionWatchId] = useState<number | null>(null);
  const [userLocationMarker] = useState<Marker>(new Marker(userMarker));
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [jumped, setJumped] = useState(false);

  useMap(map => {
    if (position && !jumped) {
      map.flyTo({
        center: [position.coords.longitude, position.coords.latitude],
        zoom: 15
      });

      setJumped(true);
    }

  }, [position]);

  useMap(map => {
    if (sharedMapConfiguration.trackGeoLocation && !userPositionWatchId) {
      setUserPositionWatchId(navigator.geolocation.watchPosition(async pos => {
        userLocationMarker.setLngLat([pos.coords.longitude, pos.coords.latitude]);
        userLocationMarker.addTo(map);
        setPosition(pos);
      }, async pos => {
        alert("Locatievoorziening dient toegestaan te zijn om je eigen locatie te laten zien op de kaart.");
      }));
    } else if (!sharedMapConfiguration.trackGeoLocation && userPositionWatchId) {
      navigator.geolocation.clearWatch(userPositionWatchId);
      setUserPositionWatchId(null);
      userLocationMarker.remove();
      setJumped(false);
    }
  }, [sharedMapConfiguration.trackGeoLocation]);

  return <></>;
}

function GenerateUserMarker(): HTMLDivElement {
  const userMarker = document.createElement("div");
  userMarker.className = "userMarker";
  userMarker.id = "userPosition";

  const pin = document.createElement("div");
  pin.className = "pin bounce";

  const pulse = document.createElement("div");
  pulse.className = "pulse";

  pin.appendChild(pulse);
  userMarker.appendChild(pin);

  return userMarker;
}
