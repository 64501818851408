/**
 * Base class for form field
 */
export class BaseFormField{
  displayName: string;
  fieldName: string;
  value: string;
  errors: string | undefined;

  /**
   *
   * @param displayName Name that will be displayed in the label
   * @param fieldName Name that is used for the FormData
   * @param defaultValue Default value
   */
  constructor(displayName: string, fieldName: string, defaultValue: string) {
    this.displayName = displayName;
    this.fieldName = fieldName;
    this.value = defaultValue;
  }
}