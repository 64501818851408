import { faArrowUpRightFromSquare, faLink } from "@fortawesome/free-solid-svg-icons";
import { Button } from "app/components/Button";
import { useAppConfiguration } from "app/hooks/useAppConfiguration";
import EventDispatcher, { CustomEvents } from "eventDispatcher";
import style from "./style.module.scss";

/**
 *  Shortcuts for an incident
 */
export function IncidentShortcuts({ leidendIncidentId }: Readonly<{ leidendIncidentId: number | undefined }>) {
  const appConfiguration = useAppConfiguration();

  /**
   * Open the planvorming in a new tab
   */
  function openPlanvorming() {
    window.open(appConfiguration.planvormingURI, "_blank");
  }

  /**
   * Open the leidend incident
   */
  function openLeidendIncident() {
    EventDispatcher.emit(CustomEvents.OpenIncident, leidendIncidentId);
  }

  return (
    <div className={style.actionwrapper}>
      <Button variant="outlined" label="PLANVORMING (BOI)" icon={faArrowUpRightFromSquare} onClick={openPlanvorming} />
      {leidendIncidentId && <Button variant="contained" icon={faLink} label="Leidend Incident" onClick={openLeidendIncident} />}
    </div>
  );
}