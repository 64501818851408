import MapboxDraw, { DrawCustomMode, DrawFeature } from "@mapbox/mapbox-gl-draw";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { StyleType } from "../draw-styles/styleTypes";
import { CalculatePixelPosition } from "./CalculatePixelPosition";

type DragLineModeState = {
    line: any;
    distance: any;
    dragging: boolean;
    touchMode: boolean;
}

const divStyleAttributes = {
  position: "absolute",
  fontSize: "14px",
  nightModeColor: "white"
};

const DragLineMode: DrawCustomMode<DragLineModeState, any> = {
  ...MapboxDraw.modes.simple_select,

  onSetup: function () {
    this.map.dragPan.disable();
    
    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false
    });

    return {
      line: null,
      dragging: false,
      distance: null,
      touchMode: false
    };
  },

  onDrag: function (state, e) {
    if(state.dragging && !state.touchMode){
      state.line.updateCoordinate(1, e.lngLat.lng, e.lngLat.lat);
      if(state.distance)
        UpdateDivPosition(state.distance, state.line, this);
    }    
  },

  onTouchMove: function (state, e) {
    if(e.originalEvent.targetTouches.length > 1)
      return;

    if(state.dragging){
      state.line.updateCoordinate(1, e.lngLat.lng, e.lngLat.lat);
      if(state.distance)
        UpdateDivPosition(state.distance, state.line, this);
    }
  },

  onMouseDown: function (state, e) {
    state.touchMode = false;
    Init(state, e.lngLat,this);
  },

  onTouchStart: function (state, e) {
    state.touchMode = true;

    // Detect triple tap to change mode
    if(e.originalEvent.targetTouches.length > 2)
      this.changeMode("simple_select");

    if(e.originalEvent.targetTouches.length > 1)
      return;

    Init(state, e.lngLat,this);
  },

  onMouseUp: function (state, e) {
    EndDrag(state, e, this);
  },

  onTouchEnd: function (state, e) {
    EndDrag(state, e, this);
  },

  onStop: function (state) {
    // Cleanup 
    if (this.getFeature(state.line.id) !== undefined) {
      this.deleteFeature(state.line.id, { silent: true });
    }
    if (state.distance) {
      if(this.map.getContainer().contains(state.distance)){
        this.map.getContainer().removeChild(state.distance);
      }
    }
    this.map.dragPan.enable();  
  }
};

function Init(state: DragLineModeState, lngLat: mapboxgl.LngLat ,mode: any) {
  const line = mode.newFeature({
    type: "Feature",
    properties: {
      type: StyleType.DragLine,
    },
    geometry: {
      type: "LineString",
      coordinates: []
    }
  });
  mode.addFeature(line);
  state.line = line;
  
  mode.map.dragPan.disable();
  state.line.addCoordinate(0, lngLat.lng, lngLat.lat);
  state.dragging = true;    
  const div = document.createElement("div");
  div.style.position = divStyleAttributes.position;
  mode.map.getContainer().appendChild(div);
  state.distance = div;
}

function EndDrag (state: any, e: any, mode: any) {
  state.line.updateCoordinate(1, e.lngLat.lng, e.lngLat.lat);
  state.dragging = false;
  if(mode.map.getContainer().contains(state.distance))
    mode.map.getContainer().removeChild(state.distance);

  mode.deleteFeature(state.line.id, { silent: true });
  mode.map.dragPan.enable();
}

function UpdateDivPosition(distanceDiv: any, line: any, mode: any) {
  const coords = line.coordinates;
  if (coords.length < 2) return;
  
  const calculationResult = CalculatePixelPosition(coords, mode.map);

  distanceDiv.style.top = calculationResult.y + "px";
  distanceDiv.style.left = calculationResult.x + "px";
  distanceDiv.style.transform = `translate(-50%, -50%) rotate(${calculationResult.bearing}deg)`;
  distanceDiv.style.fontSize = divStyleAttributes.fontSize;

  if(mode.map.getStyle().name == "Mapbox Dark")
    distanceDiv.style.color = divStyleAttributes.nightModeColor;

  distanceDiv.textContent = `${Math.round(calculationResult.distance)} m`;
}

export default DragLineMode;