export enum MelvinRestrictionDirection{
  BOTH = "BOTH",
  A_TO_B = "A_TO_B",
}

/**
 * Class representing a melvin belemmering
 */
export class MelvinBelemmering {

  /**
   * Indicator if the both ways are restricted
   */
  direction: MelvinRestrictionDirection;

  /**
   * Name of the restriction
   * @remarks This name is given from Melvin.
   */
  name: string;

  /**
   * Type of the restriction
   */
  restriction: string;

  /**
   * Indicator if emergency services are allowed to pass
   */
  emergencyServiceAllowed: string;
  
  /**
   * Description for the emergency service 
   */
  emergencyServiceDescription: string;

  /**
   * Default constructor
   * @param data Record containing melvin data
   */
  constructor(data: Record<string, string>) {
    if (data["restrictionType"] === undefined)
      throw new Error("Invalid data, no melvin belemmering type found");

    this.direction = data["direction"] as MelvinRestrictionDirection;
    this.name = data["name"];
    this.restriction = data["restriction"];

    const emergency: Record<string,string> = JSON.parse(data["emergency"]);

    if(emergency["passageAllowed"] == "true"){
      this.emergencyServiceAllowed = "Ja";
    }else if(emergency["passageAllowed"] == "false"){
      this.emergencyServiceAllowed = "Nee";
    }else{
      this.emergencyServiceAllowed = "Onbekend";
    }

    this.emergencyServiceDescription = emergency["description"] ?? "";
  }
}