import { Button } from "@mui/material";
import { DeleteForever, Hexagon, PanTool, Polyline, Straighten } from "@mui/icons-material";
import { useCallback, useContext, useEffect, useState } from "react";
import { SharedMapConfigurationContext, useMap } from "app/contexts/mapContext";
import { useDraw } from "app/hooks/useDraw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";

type FeatureMeasurement = {
  id: string;
  value: string;
  label: string;
}

export function MeasurementDrawControl(){
  const measurementDrawMode = useContext(SharedMapConfigurationContext)?.sharedMapConfiguration?.measurementDrawMode;
  const { draw, toggleDraw, drawMode, setDrawMode,active } = useDraw();
  const [features, setFeatures] = useState<FeatureMeasurement[]>([]);

  useEffect(() => {
    toggleDraw(measurementDrawMode);    
  }, [measurementDrawMode]);
  useMap((map) => {
    if(draw){
      map.on("draw.create", onUpdate);
      map.on("draw.update", onUpdate);
      map.on("draw.delete", onUpdate);
    }
    else
    {
      map.off("draw.create", onUpdate);
      map.off("draw.update", onUpdate);  
      map.off("draw.delete", onUpdate);
    }
  }, [active, draw]);

  function onDragMeasure(){
    if(draw) {
      draw.changeMode("drag_line");
      setDrawMode("drag_line");
    }
  }

  function onPolyLine() {
    if(draw) {
      draw.changeMode("draw_line_string");
      setDrawMode("draw_line_string");
    }
  }

  function onPolygon() {
    if(draw) {
      draw.changeMode("draw_polygon");
      setDrawMode("draw_polygon");
    }
  }

  function onStatic() {
    if(draw) {
      draw.changeMode("simple_select");
      setDrawMode("simple_select");
    }
  }

  function onClear() {
    if(draw) {
      draw.trash();
    }
  }

  function onMeasureClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if(draw) {
      draw.changeMode("simple_select", { featureIds: [e.currentTarget.id]});
    }
  }

  const onUpdate = useCallback(function (e: MapboxDraw.DrawUpdateEvent) {
    if(draw) {
      setFeatures([]);
      draw.getAll().features.map((feature, index) => {
        const i = index + 1;
        const featureMeasurement: FeatureMeasurement = {id: feature.id?.toString() ?? "", value: "", label: ""};

        if(feature.geometry.type == "LineString"){
          const line = turf.lineString(feature.geometry.coordinates);
          const length = turf.length(line, {units: "meters"});
          featureMeasurement.label = `Lijn ${i} afstand:`;
          featureMeasurement.value = `${length.toFixed(2)} m`;
        }
        else if(feature.geometry.type == "Polygon"){
          const polygon = turf.polygon(feature.geometry.coordinates);
          const area = turf.area(polygon);
          featureMeasurement.label = `Oppervlakte ${i}`;
          featureMeasurement.value = `${area.toFixed(2)} m2`;
        }
        setFeatures((prev) => { 
          const index = prev.findIndex((f) => f.id == feature.id);
          if(index == -1){
            return [...prev, featureMeasurement];
          }
          else{
            prev[index] = featureMeasurement;
            return [...prev];
          }
        });
      });
    }
  }, [draw]);

  if (!active) {
    return null;
  }

  return (
    <div className="measurement-container">
      <div className="measurement-menu">
        <Button className={drawMode == "drag_line" ? "active" : ""}  color="primary" size="large" variant="outlined" onClick={onDragMeasure}>
          <Straighten fontSize="large"/>
        </Button>
        <Button className={drawMode == "draw_line_string" ? "active" : ""} color="primary" size="large" variant="outlined" onClick={onPolyLine}>
          <Polyline fontSize="large"/>
        </Button>
        <Button className={drawMode == "draw_polygon" ? "active" : ""} color="primary" size="large" variant="outlined" onClick={onPolygon}>
          <Hexagon fontSize="large"/>
        </Button>
        <Button className={drawMode == "simple_select" ? "active" : ""} color="primary" size="large" variant="outlined" onClick={onStatic}>
          <PanTool fontSize="large"/>
        </Button>
        <Button className="active" color="primary" size="large" variant="outlined" onClick={onClear}>
          <DeleteForever fontSize="large"/>
        </Button>
      </div>
      {
        features.length > 0 && 
      <div className="measurement-features">
        {
          features.map((feature) => {
            return <Button key={feature.id} id={feature.id}  onClick={onMeasureClick} className="measurement-feature"><label>{feature.label}</label><span>{feature.value}</span></Button>;
          })                
        }
      </div>
      }   
    </div>);
}