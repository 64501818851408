import {useEnrichText} from "../../../hooks/useEnrichText";

interface Props {
  data: Record<string,string>[];
}

export function VoorzieningPopupTemplate({ data }: Readonly<Props>) {
  return (
    <>
      {data.map((feature) => {
        return (
          <ul key={`layer-${crypto.randomUUID()}`} className="object-info-popup voorziening">
            <li className="object-info-property">
              <div className={"symbol-name"}>
                <p>Symbool</p>
                <span>{feature.symbool}</span>
              </div>
              <div className={"symbol-icon"}>
                <div dangerouslySetInnerHTML={{__html: feature.icon_svg}}></div>
              </div>
            </li>
            <li className="object-info-property">
              <p>Bijzonderheden</p>
              <span>{useEnrichText(feature.bijzonderheden ?? "-")}</span>
            </li>
          </ul>
        );
      })}
    </>
  );
}