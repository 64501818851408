import { LoadingComponent } from "../LoadingComponent";
import { UserProfile } from "../UserProfile";
import style from "./style.module.scss";
import { EenheidProfile } from "../EenheidProfile";
import { useUser } from "../../hooks/useUser";
import EventDispatcher, { CustomEvents } from "eventDispatcher";


export function UserMenu() {
  const { user, isLoading } = useUser();

  function openUserSettings() {
    EventDispatcher.emit(CustomEvents.OpenUserSettings);
  }

  if (isLoading || !user)
    return (
      <div id={style.userMenu}>
        <LoadingComponent />
      </div>
    );

  return (
    <div id={style.userMenu} onClick={openUserSettings}>
      {!user.isEenheid && <UserProfile user={user} />}
      {user.isEenheid && <EenheidProfile roepnaamEenheid={user.displayName} />}
    </div>
  );
}
