import { MachineTelemetry } from "../../../../models/machineTelemetry";
import { TelemetryAccu } from "../components/TelemetryAccu";
import { TelemetryTanks } from "../components/TelemetryTanks";
import { TelemetryUtil } from "../../../../helpers/telemetryUtil";
import { TelemetryWaterflow } from "../components/TelemetryWaterflow";
import { TelemetryDrukTransmitter } from "../components/TelemetryDrukTransmitter";

const MeasurementKeys = {
  AccuSpanning: "value.Application.Opbouw.AccuSpanning",
  LaderStatus: "value.Application.Opbouw.LaderStatusText",
  NiveauBrandstof: "value.Application.Motor.Brandstofniveau",

  IngaandeDrukBoosterpomp: "value.Application.Opbouw.DruktransmitterIngangWater",
  UitgaandeDrukBoosterpomp: "value.Application.Opbouw.DruktransmitterUitgangWater",

  ActueeleWaterflow: "value.Application.Opbouw.GecalculeerdeWaterFlow",
  TripVolumeWater: "value.Application.Opbouw.TotaalTripVolume",
};

export function Grootmiddelwatertransport({ telemetry }: Readonly<{ telemetry: MachineTelemetry }>) {
  const accuSpanning = TelemetryUtil.extractValue(telemetry, MeasurementKeys.AccuSpanning);
  const laderStatus = TelemetryUtil.extractValue(telemetry, MeasurementKeys.LaderStatus, 0);
  const niveauBrandstof = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauBrandstof, 0); 
  
  const ingaandeDrukBoosterpomp = TelemetryUtil.extractValue(telemetry, MeasurementKeys.IngaandeDrukBoosterpomp, 1);
  const uitgaandeDrukBoosterpomp = TelemetryUtil.extractValue(telemetry, MeasurementKeys.UitgaandeDrukBoosterpomp, 0);
  const actueeleWaterflow = TelemetryUtil.extractValue(telemetry, MeasurementKeys.ActueeleWaterflow, 0);
  const tripVolumeWater = TelemetryUtil.extractValue(telemetry, MeasurementKeys.TripVolumeWater, 0);

  return (
    <>
      <TelemetryAccu accuSpanning={accuSpanning} laderStatus={laderStatus} />
      <TelemetryDrukTransmitter ingaandedruk={ingaandeDrukBoosterpomp} uitgaandedruk={uitgaandeDrukBoosterpomp} />      
      <TelemetryWaterflow actueel={actueeleWaterflow} tripVolume={tripVolumeWater} />
      <TelemetryTanks brandstoftankNiveau={niveauBrandstof} />
    </>
  );
}
