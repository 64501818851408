export class RequestError extends Error{
  status: number;
  cause?: unknown;

  constructor(msg: string, status: number, cause?: unknown) {
    super(msg);

    this.status = status;
    this.cause = cause;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}
