import { ErrorOutline } from "@mui/icons-material";

export default function ErrorComponent() {
  return (
    <div className="vehicle-telemetry-info">
      <ErrorOutline />
      <div className="vehicle-telemetry-info-text">
        <p>Er is momenteel geen telemetrie beschikbaar voor dit voertuig</p>
      </div>
    </div>
  );
}