import useSWRImmutable from "swr/immutable";
import PrismaZoom from "react-prismazoom";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useResourceFetchBlobUrl } from "app/hooks/useResourceFetch";
import React from "react";
import useIntersectionObserver from "app/hooks/useIntersectionObserver";
import { DetermineApiUri } from "config";
import style  from "./style.module.scss";

type Props = {
  incidentId: number | null;

  fileGuid: string | null | undefined;
}

/**
 * Component that displays an image in a timeline.
 */
function TimeLineImage({ incidentId, fileGuid }: Readonly<Props>) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [imageModalOpen, setImageModalOpen] = React.useState(false);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement: IntersectionObserver) => {
      if (isIntersecting && ref.current) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
    threshold: 0.1,
    rootMargin: "600px"
  });

  const imageSize = {
    maxWidth: "100%",
    maxHeight: "700px"
  };

  const resourceFetch = useResourceFetchBlobUrl();
  const { data: imgUrl } = useSWRImmutable<string>(isVisible ? `${DetermineApiUri()}/api/media/images/${incidentId}/${fileGuid}` : null, resourceFetch);

  if (!fileGuid)
    return <></>;

  return (
    <div ref={ref}>
      {!isVisible && (
        <p>Afbeelding is aan het laden</p>
      )}
      {isVisible && imgUrl && (
        <>
          <img role="button" onKeyDown={() => setImageModalOpen(true)} style={imageSize} src={imgUrl} onClick={() => setImageModalOpen(true)} alt="" />
          <Modal open={imageModalOpen} onClose={() => setImageModalOpen(false)}>
            <div className={style.prismazoomWrapper}>
              <button className={`icon-button inverted ${style.closeButton}`} onClick={() => setImageModalOpen(false)}>
                <CloseIcon />
              </button>
              <PrismaZoom>
                <img style={imageSize} src={imgUrl} alt="" />
              </PrismaZoom>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}



export const MemoizedTimeLineImage = React.memo(TimeLineImage);
