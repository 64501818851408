/**
 * Component for displaying dompel pump telemetry information.
 * @component
 */
export function TelemetryDompelPomp({ active }: Readonly<{ active: number | undefined }>) {
  return (
    <div className="telemetry-pomp">
      <div className="column">
        <div className={"header"}>Dompelpomp:</div>
      </div>
      <div className={"column"}>
        <span>{active ? "Actief" : "Inactief"}</span>
      </div>
    </div>
  );
}
