import * as React from "react";
import { SVGProps } from "react";

const SvgUvIndex8 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <clipPath id="uv-index-8_svg__a">
        <path fill="none" d="M64 64h384v192H328a72 72 0 0 0-72 72v120H64Z" />
      </clipPath>
      <symbol id="uv-index-8_svg__b" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
    </defs>
    <g clipPath="url(#uv-index-8_svg__a)">
      <use
        xlinkHref="#uv-index-8_svg__b"
        width={375}
        height={375}
        transform="translate(68.5 68.5)"
      />
      <path
        fill="none"
        stroke="#fbbf24"
        strokeMiterlimit={10}
        strokeWidth={15}
        d="M254 338v-10a74 74 0 0 1 74-74h10"
      />
    </g>
    <rect width={144} height={144} x={280} y={280} fill="#ff3c00" rx={48} />
    <path
      fill="#fff"
      d="M373.4 382.1q-7.9 5.9-21.4 5.9t-21.4-5.9a19 19 0 0 1-7.9-16 16.2 16.2 0 0 1 3.5-10.7 17.7 17.7 0 0 1 9.9-5.9v-.2a14.8 14.8 0 0 1-7.5-5.6 15.3 15.3 0 0 1-2.7-9 16 16 0 0 1 6.8-13.7q7-5 19.3-5t19.3 5a16 16 0 0 1 7 13.7 15.4 15.4 0 0 1-2.8 9.1 14.3 14.3 0 0 1-7.4 5.5v.3a18.1 18.1 0 0 1 9.8 5.9 16.2 16.2 0 0 1 3.5 10.6 19 19 0 0 1-8 16Zm-21.3-7q5.6 0 9-2.5a8.5 8.5 0 0 0 3.2-7.1 8.5 8.5 0 0 0-3.2-7.1c-2.2-1.7-5.2-2.5-9-2.5s-7 .8-9.2 2.5a8.5 8.5 0 0 0-3.2 7 8.4 8.4 0 0 0 3.3 7.2 15 15 0 0 0 9 2.5Zm0-30.3q5 0 7.8-2.3a7.4 7.4 0 0 0 2.9-6.1 7 7 0 0 0-2.8-6 13 13 0 0 0-8-2q-10.7 0-10.7 8a7.4 7.4 0 0 0 2.8 6.2q2.8 2.1 8 2.1Z"
    />
  </svg>
);

export default SvgUvIndex8;
