import {
  SearchMode,
  SearchProvider,
  SearchResult,
} from "./interfaces/searchProvider";
import { DetermineApiUri } from "../../config";

/**
 * Interface for search result of the BOI provider
 */
export interface BOIResult extends SearchResult {
  webUrl: string;
  listItemId: number;
  title: string;
  docIcon: string;
  synonyms: string[];
  notificationClassification: string[];
}

// A rudimentary cache for pdfs, storing the blobUrl and window reference
// This ensures that if a document is opened in a differend tab, It will not
// be opened/downloaded again. The contents of a blobUrl will be remove from memory 
// after the tab is closed.
interface PdfCache {
  [listItemId: number]: PdfCacheEntry | undefined;
}

interface PdfCacheEntry {
  blobUrl: string;
  windowRef: Window | null;
}

/**
 * BOI search provider
 * @remarks This search provider is used within the search component to search in documents found in the BOI (Sharepoint).
 */
export default class BOIProvider implements SearchProvider<BOIResult> {
  label = "Planvorming";
  mode = "boi" as SearchMode;
  #protectedFetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;
  pdfCache: PdfCache = {};

  /**
   * Default constructor
   * @param protectedFetch Fetch function that is used for fetching the data from the protected api.
   */
  constructor(protectedFetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>) {
    this.#protectedFetch = protectedFetch;
  }

  /** @inheritdoc */
  async search(searchTerm: string): Promise<BOIResult[]> {
    const response = await this.#protectedFetch(`${DetermineApiUri()}/api/boi/Items/SimpleSearch?query=${searchTerm}`, {
      method: "GET",
    });

    const result = await response.json();
    for (const element of result) {
      element.label = element.title;
    }

    return result;
  }

  /** @inheritdoc */
  async onResultTapped(result: BOIResult): Promise<void> {

    const newWindow = window.open();
    if (newWindow == null)
      return;

    // Check if document is already opened in a tab
    const cacheEntry = this.pdfCache[result.listItemId];
    if (cacheEntry?.windowRef && !cacheEntry?.windowRef?.closed) {
      cacheEntry.windowRef.focus();
      return;
    }
    // Clear cache entry if window is closed
    if (cacheEntry?.windowRef?.closed) {
      delete this.pdfCache[result.listItemId];
    }

    await this.#protectedFetch(
      `${DetermineApiUri()}/api/boi/document/${result.listItemId}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        newWindow.location = url;
        this.pdfCache[result.listItemId] = {blobUrl: url, windowRef: newWindow };
      })
      .catch((error) => console.error(error));
  }

  /** @inheritdoc */
  onCancel(): void {
    // Do nothing
  }
}
