import { Notification } from "./Notification";

/**
 * Notification for when a new version of the OIV-Viewer is available.
 * @see {@link /src/app/hooks/useVersion.ts}
 */
export const NewVersionReadyNotification: Notification = {
  type: "warning",
  content: "Er is een nieuwe versie van de OIV-Viewer beschikbaar. Klik hier om te verversen.",
  onClick: () => window.location.href = `${window.location.href}`,
};