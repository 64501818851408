import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis Calamiteiten layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/1000}
 */
export const CalamiteitenRouteDefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.calamiteitenroute,
  layers: [
    {
      layerId: 1000,
      layerDefinitions: [
        {
          "id": LayerLabels.calamiteitenroute,
          "type": "fill",
          "paint": {
            "fill-color": "rgba(255,54,80,153)",
            "fill-outline-color": "rgba(115,0,0,255)"
          }
        }
      ]
    }
  ],
  isToggledByKermisInformatieLayer: true
};