import { useCallback } from "react";
import useGeoServer from "../../hooks/useGeoServer";
import { Templates } from "../MapboxPopup/TemplateManager";
import { PopupContent } from "app/models/popupContent";
import EventDispatcher, { CustomEvents } from "../../../eventDispatcher";
import { useMap } from "app/contexts/mapContext";

export const WaterwinningLayerName = "internal_waterwinning";

/**
 * Waterwinning layer
 * @constructor
 * @remarks This layer is custom cause it uses two different sources
 */
export function WaterwinningLayer() {
  useGeoServer(WaterwinningLayerName, [
    { name: "vik:brabant_water", source: "VRMWB", isTiled: true, isClickable: false }
  ]);

  const { fetchFeatures } = useGeoServer(`${WaterwinningLayerName}-cogo`, [
    { name: "roi_vrmwb_waterwinning_eigen_beheer", source: "COGO", isTiled: false, isClickable: true }
  ]);

  /*
   * Callback for the click event
   * @remarks This function is memoized to prevent unnecessary re-renders and to prevent the function from being recreated on every render
   *          It needs to be the exact same function to unsubscribe from the event.
   */
  const onMapClick = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const bbox = e.lngLat.toBounds(10);
      const boundingBox = [[bbox.getSouthWest().lng, bbox.getSouthWest().lat], [bbox.getNorthEast().lng, bbox.getNorthEast().lat]];
      fetchFeatures(boundingBox).then(result => {
        if (result.length == 0)
          return;

        const content: PopupContent = {
          id: "roi_vrmwb_waterwinning_eigen_beheer",
          tabName: "Waterwinning",
          template: Templates.Waterwinning,
          data: result
        };

        EventDispatcher.emit(CustomEvents.PopupOpened, content);
      }
      );
    }, []
  );

  /**
   * Add a click event to the map
   */
  useMap(map => {
    map.on("click", onMapClick);

    return () => {
      map.off("click", onMapClick);
    };
  }, []);

  return <></>;
}