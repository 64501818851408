import * as React from "react";
import { SVGProps } from "react";

const SvgWindBeaufort5 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="wind-beaufort-5_svg__a" viewBox="0 0 342 234">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M264.2 21.3A40 40 0 1 1 293 89H9m139.2 123.7A40 40 0 1 0 177 145H9"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#wind-beaufort-5_svg__a"
      width={342}
      height={234}
      transform="translate(85 139)"
    />
    <path
      fill="#374251"
      d="M383.8 344q-12 0-19.7-5.5t-9-15.7l17.6-1.4a9.9 9.9 0 0 0 3.8 6 12 12 0 0 0 7.2 2.2 11.6 11.6 0 0 0 8.5-3 10.5 10.5 0 0 0 3-8 10.4 10.4 0 0 0-3-7.8 11.6 11.6 0 0 0-8.5-3 12.6 12.6 0 0 0-9.7 4.3l-16.6-1.5 6.4-38.6H408v14.7h-30.9l-2.3 12 .3.2q5.6-4 14.2-4 10.7 0 17 6.2t6.6 17.1q0 11.8-7.8 18.8t-21.3 7Z"
    />
  </svg>
);

export default SvgWindBeaufort5;
