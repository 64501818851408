export const incidentMarkerSLD = `<?xml version="1.0" encoding="UTF-8"?>
<StyledLayerDescriptor version="1.0.0"
    xsi:schemaLocation="https://schemas.opengis.net/sld/1.1/StyledLayerDescriptor.xsd"
    xmlns="http://www.opengis.net/sld"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <NamedLayer>
        <Name>IncidentMarker</Name>
        <UserStyle>
            <Title>Orange Marker</Title>
            <FeatureTypeStyle>
                <!-- Rule for smaller scales (zoomed out) -->
                <Rule>
                    <MinScaleDenominator>0</MinScaleDenominator>
                    <MaxScaleDenominator>50000</MaxScaleDenominator>
                    <PointSymbolizer>
                        <Graphic>
                            <Mark>
                                <WellKnownName>circle</WellKnownName>
                                <Fill>
                                    <CssParameter name="fill">#FFA500</CssParameter>
                                </Fill>
                                <Stroke>
                                    <CssParameter name="stroke">#FFFFFF</CssParameter> <!-- White border -->
                                    <CssParameter name="stroke-width">5</CssParameter> <!-- Border width -->
                                </Stroke>
                            </Mark>
                            <Size>10</Size> <!-- Smaller size for zoomed out view -->
                        </Graphic>
                    </PointSymbolizer>
                </Rule>
                <!-- Rule for medium scales -->
                <Rule>
                    <MinScaleDenominator>50001</MinScaleDenominator>
                    <MaxScaleDenominator>200000</MaxScaleDenominator>
                    <PointSymbolizer>
                        <Graphic>
                            <Mark>
                                <WellKnownName>circle</WellKnownName>
                                <Fill>
                                    <CssParameter name="fill">#FFA500</CssParameter>
                                </Fill>
                                <Stroke>
                                    <CssParameter name="stroke">#FFFFFF</CssParameter> <!-- White border -->
                                    <CssParameter name="stroke-width">5</CssParameter> <!-- Border width -->
                                </Stroke>
                            </Mark>
                            <Size>20</Size> <!-- Medium size -->
                        </Graphic>
                    </PointSymbolizer>
                </Rule>
                <!-- Rule for larger scales (zoomed in) -->
                <Rule>
                    <MinScaleDenominator>200001</MinScaleDenominator>
                    <MaxScaleDenominator>1000000</MaxScaleDenominator>
                    <PointSymbolizer>
                        <Graphic>
                            <Mark>
                                <WellKnownName>circle</WellKnownName>
                                <Fill>
                                    <CssParameter name="fill">#FFA500</CssParameter>
                                </Fill>
                                <Stroke>
                                    <CssParameter name="stroke">#FFFFFF</CssParameter> <!-- White border -->
                                    <CssParameter name="stroke-width">15</CssParameter> <!-- Border width -->
                                </Stroke>
                            </Mark>
                            <Size>50</Size> <!-- Larger size for zoomed in view -->
                        </Graphic>
                    </PointSymbolizer>
                </Rule>
            </FeatureTypeStyle>
        </UserStyle>
    </NamedLayer>
</StyledLayerDescriptor>`;