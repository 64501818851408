import {useCallback, useRef} from "react";
import {  useMap } from "../contexts/mapContext";
import { MapboxUtil } from "../helpers/mapboxUtil";
import { FeatureCollection } from "geojson";
import mapboxgl, {AnyLayer, Point} from "mapbox-gl";
import {MapIcon} from "../models/mapIcon";

/**
 * Hook for using GeoJSON layers on the map
 * @param layerName Name the layer will have in mapbox
 * @param source GeoJSON source (can be a url or a FeatureCollection)
 * @param layerDefinitions Array of layers to add to the map
 * @param icons Icon's that need to be added to the map
 */
const useGeoJson = (layerName: string, source: FeatureCollection | undefined, layerDefinitions: AnyLayer[], icons?: MapIcon[] | undefined) => {
  const refSource = useRef<FeatureCollection | undefined>(source);
  const refIcons = useRef<MapIcon[] | undefined>(icons);
  const sourceId = `${layerName}-source`;

  /**
   * Update the source reference whenever source changes
   */
  useMap((map) => {
    refSource.current = source;
    refIcons.current = icons;
    MapboxUtil.refreshGeoJSONSource(sourceId, source ?? {type: "FeatureCollection", features: []}, map);

    if (icons)
      MapboxUtil.addImagesToMap(icons, map);

  }, [source, icons]);

  /**
   * Redraw the layer to the map
   * @remarks This function is within a callback to prevent it from being "changed" on every render
   */
  const redrawLayer = useCallback((e: mapboxgl.MapStyleDataEvent & mapboxgl.EventData) => {
    const targetMap = e.target;
    if (!targetMap)
      return;

    if (icons)
      MapboxUtil.addImagesToMap(icons, targetMap);

    MapboxUtil.addGeoJSONSourceToMap(sourceId, refSource.current ?? {
      type: "FeatureCollection",
      features: []
    }, targetMap);
    MapboxUtil.addLayersToMap(targetMap, layerDefinitions.map(x => [x, undefined]));
  }, []);

  /**
   * Initialize layers on the map
   */
  useMap((map) => {
    if (source == null)
      return;

    MapboxUtil.addGeoJSONSourceToMap(sourceId, refSource.current ?? {type: "FeatureCollection", features: []}, map);
    MapboxUtil.addLayersToMap(map, layerDefinitions.map(x => [x, undefined]));
    map.on("styledata", redrawLayer);

    /**
     * Dispose
     */
    return () => {
      MapboxUtil.removeLayersFromMap(map, layerDefinitions.map(x => x.id));
      MapboxUtil.removeSourcesFromMap(map, [sourceId]);

      map.off("styledata", redrawLayer);
    };
  }, [source]);

  /**
   * Query the rendered features on the map
   * @param point
   * @param boundingBoxRadius
   * @param map
   * @param mergeFeaturesBasedOnProperty If provided, the features will be merged based on the provided property
   * @returns Array with all properties per feature found within the radius.
   */
  async function queryRenderedFeatures(point: Point, boundingBoxRadius: number, map: mapboxgl.Map, mergeFeaturesBasedOnProperty?: string): Promise<Record<string, string>[]> {
    const features = await MapboxUtil.queryRenderedFeatures(point.x, point.y, boundingBoxRadius, layerDefinitions.map(x => x.id), map);
    if(features.length == 0)
      return [];

    if(!mergeFeaturesBasedOnProperty){
      features.forEach(element => {
        map.setFeatureState({ source: sourceId, id: element.id as unknown as string }, { selected: true });
      });

      return features.map(x => x.properties as unknown as Record<string,string>);
    }

    // Following ts-ignore is needed because the properties are not typed in the mapboxgl library
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mergeValue = features[0]?.properties[mergeFeaturesBasedOnProperty];
    if(!mergeValue)
      return [];

    const relatedFeatures = await MapboxUtil.querySourceFeaturesByProperty(mergeFeaturesBasedOnProperty, mergeValue, sourceId, layerName, map);
    return relatedFeatures.map(x => x.properties as unknown as Record<string,string>);
  }

  return {queryRenderedFeatures};
};

export default useGeoJson;
