/**
 * Component for displaying booster pump telemetry information.
 * @component
 */
export function TelemetryWaterflow({ actueel, tripVolume }: Readonly<{ actueel: number | undefined, tripVolume: number | undefined }>) {
  return (
    <div className="telemetry-pomp">
      <div className="column">
        <div className={"header"}>Waterflow:</div>
      </div>
      <div className={"column"}>
        <span><b>Actueel:</b> {actueel ?? 0} l/min</span>
        <span><b>Verpompt:</b> {tripVolume ?? 0} m<sup>3</sup></span>
      </div>
    </div>
  );
}
