import {Vehicle} from "../../../../models/vehicleLocation";
import CircleIcon from "@mui/icons-material/Circle";

/**
 * Props for the VehicleStatus component
 */
interface Props {
  readonly statusCode: number | undefined;
  readonly statusCodeAfkr: string;
  readonly showLabel: boolean;
}

/**
 * VehicleStatus shows the status of a vehicle
 * @param vehicle
 * @param showLabel
 * @constructor
 */
export function VehicleStatus({statusCode, statusCodeAfkr, showLabel}: Props) {
  switch (statusCode) {
    case 1:
      return <StatusComponent status={"red"} label={"Uitgerukt"} abbreviation={statusCodeAfkr.toUpperCase()} showLabel={showLabel} />;
    case 2:
      return <StatusComponent status={"red"} label={"Ter plaatse"} abbreviation={statusCodeAfkr.toUpperCase()} showLabel={showLabel} />;
    case 3:
      return <StatusComponent status={"red"} label={"Ingerukt"} abbreviation={statusCodeAfkr.toUpperCase()} showLabel={showLabel} />;
    case 4:
      return <StatusComponent status={"green"} label={"Beschikbaar"} abbreviation={statusCodeAfkr.toUpperCase()} showLabel={showLabel} />;
    case 5:
      return <StatusComponent status={"green"} label={"Op kazerne"} abbreviation={statusCodeAfkr.toUpperCase()} showLabel={showLabel} />;
    case 6:
      return <StatusComponent status={"gray"} label={"Buiten dienst"} abbreviation={statusCodeAfkr.toUpperCase()} showLabel={showLabel} />;
    case 10:
      return <StatusComponent status={"red"} label={"Opdracht verstrekt"} abbreviation={statusCodeAfkr.toUpperCase()} showLabel={showLabel} />;
    default:
      return <StatusComponent status={"red"} label={"Onbekend"} abbreviation={""} showLabel={showLabel} />;
  }
}

/**
 * StatusComponent is the component that displays the status of a vehicle
 */
type StatusColor = "red" | "green" | "gray" | "orange";

/**
 * Props for the StatusComponent
 */
type StatusProps = {
  readonly status: StatusColor;
  readonly label: string;
  readonly abbreviation: string;
  readonly showLabel: boolean;
};

/**
 * StatusComponent displays the status of a vehicle
 * @param status
 * @param label
 * @param abbreviation
 * @param showLabel
 * @constructor
 */
function StatusComponent({status, label, abbreviation, showLabel}: StatusProps) {
  return (
    <div className={`vehicle-status ${status}`}>
      <CircleIcon />
      {showLabel &&
          <>
            {label}
            {abbreviation && abbreviation != "" &&
              <span className={"abbreviation"}>({abbreviation.toUpperCase()})</span>
            }
          </>
      }
    </div>
  );
}
