/**
 * Convert a File to Base64 sstring
 * @param file
 */
export function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = error => reject(error);
  });
}

/**
 * Convert Base64 to blob
 * @param base64
 */
export async function toBlob(base64: string): Promise<Blob> {
  const base64Response = await fetch(base64);
  return await base64Response.blob();
}