import { MapContext, useMap } from "../contexts/mapContext";
import { useAppConfiguration } from "./useAppConfiguration";
import mapboxgl from "mapbox-gl";
import GeoserverUtil, { GeoServerLayer } from "../helpers/geoserverUtil";
import { GeoLayer } from "../models/geoLayer";
import { useCallback, useContext, useState } from "react";
import { FeatureCollection } from "geojson";
import { MapboxUtil } from "../helpers/mapboxUtil";
import { Feature } from "../models/geoServerModels";

/**
 * Hook for using GeoServer layers on the map
 * @param mapboxLayerName Name the layer will have in mapbox
 * @param layers Array of layers to add to the map
 * @remarks Uses our custom useMap hook to add the map to the layer.
 */
const useGeoServer = (mapboxLayerName: string, layers: GeoServerLayer[]) => {
  const appConfiguration = useAppConfiguration();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const mapFromContext = useContext(MapContext);
  const sourceId = `${mapboxLayerName}-source`;
  const addLayer = useCallback(() => {
    if (!mapFromContext)
      return;

    setLoading(true);
    addSourcesToMap(mapFromContext);
    addLayerToMap(mapFromContext);
    setLoading(false);
  }, []);

  /**
   * Initialize layers on the map
   */
  useMap((map) => {
    try {
      addLayer();
    } catch (e: unknown) {
      const error = e as Error;
      setErrors([...errors, error.message]);
    }

    /** 
     * Styledata event is triggered whenever the map changes basic style (aka the map layer).
     * User triggers this whenever the changes the mode to light/dark.
     */
    map.on("styledata", addLayer);

    return () => {
      try {
        map.off("styledata", addLayer);
        MapboxUtil.removeLayersFromMap(map, [mapboxLayerName]);
        MapboxUtil.removeSourcesFromMap(map, [sourceId]);
      } catch (e: unknown) {
        const error = e as Error;
        setErrors([...errors, error.message]);
      }
    };
  }, []);

  /**
   * Add layer source to the map
   * @param map
   */
  function addSourcesToMap(map: mapboxgl.Map) {
    const groupedBy = GeoserverUtil.groupBySource(layers);
    const tileUrls = Object.entries(groupedBy).map(([source, layers]) => {
      const baseUri = source == "COGO" ? `${appConfiguration.cogoServerUrl}/geoserver/vrmwb-besloten` : `${appConfiguration.geoserverBaseUri}/geoserver`;
      return GeoserverUtil.generateGetMapUri(baseUri, layers);
    });

    MapboxUtil.addWMSSourceToMap(sourceId, tileUrls, map);
  }

  /**
   * Add layer to the map
   * @param map
   */
  function addLayerToMap(map: mapboxgl.Map) {
    if (map.getLayer(mapboxLayerName))
      return;

    const renderLayerBefore = determineRenderOrder(map);
    MapboxUtil.addLayersToMap(map, [[{
      id: mapboxLayerName,
      type: "raster",
      source: `${mapboxLayerName}-source`,
      layout: { visibility: "visible" },
    }, renderLayerBefore]]);
  }

  /**
   * Determine the render order of the layer
   * @param map Mapbox map
   * @returns The layer id before which the layer should be rendered
   */
  function determineRenderOrder(map: mapboxgl.Map): string {
    const layers: GeoLayer[] = [];
    const layer = appConfiguration.selectableGeoLayers.geoLayers.find(x => x.name == mapboxLayerName);

    let renderBefore = map.getStyle().name == "Mapbox Dark" ? "road-label-simple" : "road-label";

    if (!layer)
      return renderBefore;

    appConfiguration.selectableGeoLayers.geoLayers.filter(x => !x.name.includes("oiv_internal")).forEach(x => {
      const result = x.layers.filter(y => y.order > layer?.order);
      if (result.length > 0)
        layers.push(...result);
    });

    layers.sort((a, b) => (a.order < b.order) ? 1 : -1);
    layers.reverse();

    layers.every((x) => {
      if (map.getLayer(x.name)) {
        renderBefore = x.name;
        return false;
      }
      return true;
    });

    return renderBefore;
  }

  /**
   * Fetch features from the geoserver
   * @param boundingBox
   */
  async function fetchFeatures(boundingBox: number[][]): Promise<Record<string, string>[]> {
    const features: Record<string, string>[] = [];

    for (const layer of layers.filter(x => x.isClickable)) {
      const authKey = layer.source == "COGO" ? appConfiguration.cogoEncodedBasicAuth : appConfiguration.geoServerAuthenticationKey;
      const baseUri = layer.source == "COGO" ? `${appConfiguration.cogoServerUrl}/geoserver/vrmwb-besloten` : `${appConfiguration.geoserverBaseUri}/geoserver`;
      const uri = GeoserverUtil.generateGetFeatureUri(baseUri, boundingBox, layer.name);

      const requestOptions = {
        method: "GET",
        headers: {
          "Authorization": `Basic ${authKey}`
        }
      };
      const response = await fetch(uri, requestOptions);
      const featureInfo: FeatureCollection = await response.json();

      if (layer.includeSnapshot) {
        for (const feature of featureInfo.features) {
          const x = (feature as Feature).geometry.coordinates[0];
          const y = (feature as Feature).geometry.coordinates[1];
          const c_bbox = [[x - 0.000001, y - 0.000001], [x + 0.000001, y + 0.000001]];
          const uri = GeoserverUtil.generateSnapshotUri(baseUri, [layer.name], c_bbox);
          const response = await fetch(uri, requestOptions);

          if (feature.properties)
            feature.properties["icon_svg"] = await response.text();
        }
      }

      features.push(...featureInfo.features.map((x) => x.properties as Record<string, string>));
    }

    return features;
  }

  return { errors, loading, fetchFeatures };
};

export default useGeoServer;
