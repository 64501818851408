import {ReactNode} from "react";
import CarCrash from "@mui/icons-material/CarCrash";

type LicensePlateFormat = {
  label: string;
  expression: RegExp;
  series: number;
};

type LicensePlateNegativeLookBack = {
  expression: string;
  fromSeries: number;
}

export const negativeLookBacks: LicensePlateNegativeLookBack[] = [
  {expression: "^(KM|LX|LE|UN)", fromSeries: 0},
  {expression: "GVD", fromSeries: 1},
  {expression: "KKK", fromSeries: 1},
  {expression: "KVT", fromSeries: 1},
  {expression: "LPF", fromSeries: 1},
  {expression: "NSB", fromSeries: 1},
  {expression: "PKK", fromSeries: 1},
  {expression: "PSV", fromSeries: 1},
  {expression: "PVV", fromSeries: 1},
  {expression: "SGP", fromSeries: 1},
  {expression: "VVD", fromSeries: 1},
  {expression: "CDA", fromSeries: 1},
  {expression: "TBS", fromSeries: 1},
  {expression: "SS", fromSeries: 1},
  {expression: "SD", fromSeries: 1},  
  {expression: "[CQAEOIU]+", fromSeries: 5},
];

// Make a list based on LicensePlatePatterns but with the object LicensePlateFormat
const Patterns: LicensePlateFormat[] = [

  { label: "XX-99-99", expression: /\b([A-Z]{2}-\d{2}-\d{2})\b/i, series: 1 },
  { label: "XX9999", expression: /\b([A-Z]{2}\d{2}\d{2})\b/i, series: 1 },

  { label: "99-99-XX", expression: /\b(\d{2}-\d{2}-[A-Z]{2})\b/i, series: 2 },

  { label: "99-XX-99", expression: /\b(\d{2}-[A-Z]{2}-\d{2})\b/i, series: 3 },
  { label: "99XX99", expression: /\b(\d{2}[A-Z]{2}\d{2})\b/i, series: 3 },

  { label: "XX-99-XX", expression: /\b([A-Z]{2}-\d{2}-[A-Z]{2})\b/i, series: 4 },
  { label: "XX99XX", expression: /\b([A-Z]{2}\d{2}[A-Z]{2})\b/i, series: 4 },

  { label: "XX-XX-99", expression: /\b([A-Z]{2}-[A-Z]{2}-\d{2})\b/i, series: 5 },
  { label: "XXXX99", expression: /\b([A-Z]{2}[A-Z]{2}\d{2})\b/i, series: 5 },

  { label: "99-XX-XX", expression: /\b(\d{2}-[A-Z]{2}-[A-Z]{2})\b/i, series: 6 },
  { label: "99XX99", expression: /\b(\d{2}[A-Z]{2}[A-Z]{2})\b/i, series: 6 },

  { label: "99-XXX-9", expression: /\b(\d{2}-[A-Z]{3}-\d)\b/i, series: 7 },
  { label: "99XXX9", expression: /\b(\d{2}[A-Z]{3}\d)\b/i, series: 7 },

  { label: "9-XXX-99", expression: /\b(\d-[A-Z]{3}-\d{2})\b/i, series: 8 },
  { label: "9XXX99", expression: /\b(\d[A-Z]{3}\d{2})\b/i, series: 8 },

  { label: "XX-999-X", expression: /\b([A-Z]{2}-\d{3}-[A-Z])\b/i, series: 9 },
  { label: "XX999X", expression: /\b([A-Z]{2}\d{3}[A-Z])\b/i, series: 9 },

  { label: "X-999-XX", expression: /\b([A-Z]{3}-\d{2}-[A-Z])\b/i, series: 10 },
  { label: "X999XX", expression: /\b([A-Z]{3}\d{2}[A-Z])\b/i, series: 10 },

  { label: "XXX-99-X", expression: /\b([A-Z]-\d{3}-[A-Z]{2})\b/i, series: 11 },
  { label: "XXX99X", expression: /\b([A-Z]\d{3}[A-Z]{2})\b/i, series: 11 },

  { label: "X-99-XXX", expression: /\b([A-Z]-\d{2}-[A-Z]{3})\b/i, series: 12 },
  { label: "X99XXX", expression: /\b([A-Z]\d{2}[A-Z]{3})\b/i, series: 12 },

  { label: "9-XX-999", expression: /\b(\d-[A-Z]{2}-\d{3})\b/i, series: 13 },
  { label: "9XX999", expression: /\b(\d[A-Z]{2}\d{3})\b/i, series: 13 },

  { label: "999-XX-9", expression: /\b(\d{3}-[A-Z]{2}-\d)\b/i, series: 14 },
  { label: "999XX9", expression: /\b(\d{3}[A-Z]{2}\d)\b/i, series: 14 },

  // Special format for royal house vehicles
  { label: "AA-99", expression: /\b(A{2}-\d{2})\b/i, series: 0 },
  { label: "AA99", expression: /\b(A{2}\d{2})\b/i, series: 0 },
];

/**
 * Add exclusions to the regex patterns.
 * @param patterns
 * @remarks used to detect combinations that aren't valid for Dutch license plates.
 */
function addNegativeLookbackExpressions(patterns: LicensePlateFormat[]): RegExp[] {
  return patterns.map(pattern => {
    const exclusionPattern = negativeLookBacks.filter(x => x.fromSeries <= pattern.series).map(combination => `${combination.expression}`).join("|");
    return new RegExp(pattern.expression.source.replace(/\[A-Z]/g, `(?!${exclusionPattern})[A-Z]`), pattern.expression.flags);
  });
}

/**
 * Wraps the given content in a href component when a dutch license plate is detected.
 * @param content
 * @param regex
 */
function wrapInCrashRecoveryAppLink(content: string, regex: RegExp) : ReactNode{
  if (regex.test(content)) {
    const firstMatch = regex[Symbol.match](content)?.[0];
    if(!firstMatch)
      return content;

    const matchArray = regex[Symbol.match](content);
    if(!matchArray)
      return content;

    const crsLicensePlate = firstMatch.replaceAll("-", "").toUpperCase();
    return (
      <>
        {content.substring(0, matchArray.index)}
        <a className="license-plate" href={`moditech.crs://startview?page=numberplate&regnr=${crsLicensePlate}&country=1`}><span>{firstMatch}</span><CarCrash/></a>
        {content.substring((matchArray.index ?? 0) + firstMatch.length)}
      </>
    );
  }
  return `${content}`;
}

/**
 * Adds a href components when dutch license plates are detected within the given content.
 * @param  {string} content   The content that will be checked for license plates.
 * @return {ReactNodeArray}      Returns multiple components including new a href components.
 */
export function addCrashRecoveryAppLinks(content: string): ReactNode[]{
  const alteredContent: ReactNode[] = [];
  const alteredPatterns = addNegativeLookbackExpressions(Patterns);

  content.split(/(\s+)/).forEach(word => {
    let alteredWord : string | ReactNode = word;
    alteredPatterns.every(pattern => {
      if(pattern.test(word)){
        alteredWord = wrapInCrashRecoveryAppLink(word, pattern);
        return false;
      }
      return true;
    });
    alteredContent.push(alteredWord);
  });

  return alteredContent;
}