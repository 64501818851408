import { useMap } from "app/contexts/mapContext";
import { useState } from "react";

export function useMapBearing() {
  const [bearing, setBearing] = useState(0);

  useMap(map => {
    map.on("rotate", () => setBearing(map.getBearing() ?? 0));
  });

  return { bearing };
}