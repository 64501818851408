export interface GeoServerLayer {
  name: string;
  source: "VRMWB" | "COGO";
  isTiled: boolean;
  isClickable: boolean;
  childLayers?: [];
  includeSnapshot?: boolean;
}

export default class GeoserverUtil {

  /**
   * Group the layers by source
   * @param layers
   */
  static groupBySource(layers: GeoServerLayer[]): Record<string, GeoServerLayer[]> {
    const result: Record<string, GeoServerLayer[]> = {};
    layers.forEach(x => {
      if (result[x.source]) {
        result[x.source].push(x);
      } else {
        result[x.source] = [x];
      }
    });
    return result;
  }

  /**
   * Generate a WMS uri for the geoserver
   * @param baseUrl
   * @param layers
   * @param tiledLayers
   */
  static generateGetMapUri(baseUrl: string, layers: GeoServerLayer[]): string{
    const service = "WMS";
    const version = "1.1.0";
    const request = "GetMap";
    const styles = "";
    const format = "image/png";
    const transparent = "true";
    const tiled =  layers.some(x => x.isTiled);
    const srs = "EPSG:3857";
    const width = "256";
    const height = "256";
    const bbox = "{bbox-epsg-3857}";

    const suffix = `${baseUrl}/wms?service=${service}&version=${version}&request=${request}&layers=`;

    let layerString = "";
    layers.forEach(x => {
      if (layerString == "") {
        layerString = x.name;
      } else {
        layerString = layerString + "," + x.name;
      }
    });

    const prefix = `&bbox=${bbox}&width=${width}&height=${height}&srs=${srs}&styles=${styles}&format=${format}&TILED=${tiled}&TRANSPARENT=${transparent}`;
    return `${suffix}${layerString}${prefix}`;
  }

  /**
   * Generate a WFS uri for the geoserver
   * @param baseUrl
   * @param boundingBox
   * @param name
   */
  static generateGetFeatureUri(baseUrl: string, boundingBox: number[][], name: string){
    const service = "WFS";
    const version = "2.0.0";
    const request = "GetFeature";
    const typeNames = name;
    const bbox = `${boundingBox[0][0]},${boundingBox[0][1]},${boundingBox[1][0]},${boundingBox[1][1]},EPSG:4326`;
    const srsName = "EPSG:4326";
    const outputFormat = "application/json";

    return `${baseUrl}/wfs?service=${service}&version=${version}&request=${request}&typeNames=${typeNames}&bbox=${bbox}&srsName=${srsName}&outputFormat=${outputFormat}`;
  }

  /**
   * Generate a snapshot uri for the geoserver
   * @param baseUrl
   * @param layerNames
   * @param boundingBox
   */
  static generateSnapshotUri(baseUrl: string, layerNames: string[], boundingBox: number[][]): string {
    const service = "WMS";
    const version = "1.1.1";
    const request = "GetMap";
    const layers = layerNames.join(",");
    const bbox = `${boundingBox[0][0]},${boundingBox[0][1]},${boundingBox[1][0]},${boundingBox[1][1]},EPSG:4326`;
    const width = "60";
    const height = "60";
    const format = "image/svg";
    return `${baseUrl}/wms?service=${service}&version=${version}&request=${request}&layers=${layers}&bbox=${bbox}&width=${width}&height=${height}&format=${format}`;
  }
}