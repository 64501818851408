import {AnyLayer} from "mapbox-gl";
import useSWR from "swr";
import {FeatureCollection} from "geojson";
import {DetermineApiUri} from "../../../config";
import useGeoJson from "../../hooks/useGeoJson";
import {MapIcon} from "../../models/mapIcon";
import {useMap} from "../../contexts/mapContext";
import EventDispatcher, {CustomEvents} from "../../../eventDispatcher";
import {useCallback} from "react";
import {PopupContent} from "../../models/popupContent";
import {Templates} from "../MapboxPopup/TemplateManager";

/**
 * Name of the melvin layer
 */
export const WegwerkzaamhedenLayerName = "internal_melvin";
const layerDefinitions: AnyLayer[] = [
  {
    "id": `${WegwerkzaamhedenLayerName}-layer`,
    "type": "line",
    "source": `${WegwerkzaamhedenLayerName}-source`,
    "layout": {
      "line-join": "round",
      "line-cap": "round",
      "visibility": "visible"
    },
    "paint": {
      "line-color":
        ["match", ["get", "passageAllowed", ["get", "emergency", ["properties"]]],
          "YES", "#9ba9b1",
          "true", "#9ba9b1",
          "yes", "#9ba9b1",
          "false", "#355263",
          "#355263",
        ],
      "line-opacity": 1,
      "line-width":
        [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          5,
          2
        ],
    }
  },
  {
    "id": `${WegwerkzaamhedenLayerName}--layer`,
    "type": "symbol",
    "source": `${WegwerkzaamhedenLayerName}-source`,
    "layout": {
      "icon-image": `${WegwerkzaamhedenLayerName}-marker`,
      "icon-size": 0.05,
      "icon-allow-overlap": false,
      "visibility": "visible"
    },
    "paint": {
      "icon-opacity": 1
    }
  }
];
const icons: MapIcon[] = [{ name: `${WegwerkzaamhedenLayerName}-marker`, path: "/assets/images/icons/melvin-marker.png" }];

/**
 * Melvin layer
 * @constructor
 */
export function WegwerkzaamhedenLayer() {
  const {data} = useSWR<FeatureCollection>(`${DetermineApiUri()}/api/melvin`,
    {
      refreshInterval: 5000,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  const { queryRenderedFeatures } = useGeoJson(WegwerkzaamhedenLayerName, data, layerDefinitions, icons);

  /**
   * Add a click event to the map
   */
  useMap(map => {
    map.on("click", onMapClick);
    return () => {
      map.off("click", onMapClick);
    };
  }, []);

  /**
   * Callback for the click event
   * @remarks This function is memoized to prevent unnecessary re-renders and to prevent the function from being recreated on every render
   *          It needs to be the exact same function to unsubscribe from the event.
   */
  const onMapClick = useCallback((e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    queryRenderedFeatures(e.point, 20, e.target, "melvin_id").then(result => {
      if (result.length == 0)
        return;

      const content: PopupContent = {
        id: "melvin",
        tabName: "Wegwerkzaamheden",
        template: Templates.MelvinBelemmering,
        data: result
      };

      EventDispatcher.emit(CustomEvents.PopupOpened, content);
    });
  }, []);


  return (<></>);
}
