import { MapContext, useMap } from "app/contexts/mapContext";
import mapboxgl, { AnyLayer } from "mapbox-gl";
import { useContext } from "react";
import { MapboxUtil } from "app/helpers/mapboxUtil";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FeatureService from "mapbox-gl-arcgis-featureserver";
import { MapIcon } from "app/models/mapIcon";

type ArcGISFeatureServerLayerProps = {
  apiKey: string;
  sourceId: string;
  url: string;
  layerDefinitions: AnyLayer[];
  renderBefore?: string;
  icons?: MapIcon[];
}

/**
 * Component that adds an ArcGIS feature server layer to the map, make use a third-party library (without types)
 * @param apiKey  The API key to access the feature server
 * @param sourceId  The source identifier
 * @param url  The URL of the feature server
 * @param layerDefinitions  The layer definitions
 * @returns The ArcGIS feature server layer on mapbox
 * @see {@link https://www.npmjs.com/package/mapbox-gl-arcgis-featureserver}
 */
export function ArcGISFeatureServerLayer({ apiKey, sourceId, url, layerDefinitions, renderBefore, icons }: Readonly<ArcGISFeatureServerLayerProps>) {
  const mapContext = useContext(MapContext);

  /**
   * Add the layer to the map
   * @param map  The map to add the layer to (Mapbox instance)
   */
  function addLayer(map: mapboxgl.Map): void {
    if (!mapContext || mapContext.getSource(sourceId))
      return;

    if (icons)
      MapboxUtil.addImagesToMap(icons, map);

    new FeatureService(sourceId, map, {
      url: url,
      token: apiKey,
      simplifyFactor: 0,
    });

    layerDefinitions.forEach((layerDefinition) => {
      const layer = { ...layerDefinition, source: sourceId } as mapboxgl.AnyLayer;
      MapboxUtil.addLayersToMap(map, [[layer, renderBefore ?? "continent-label"]]);
    });
  }

  useMap((map) => {
    addLayer(map);

    /**
     * Dispose
     */
    return () => {
      MapboxUtil.removeLayersFromMap(map, layerDefinitions.map(x => x.id));
      MapboxUtil.removeSourcesFromMap(map, [sourceId]);
    };
  }, []);

  return (<></>);
}