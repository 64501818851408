import { useAppConfiguration } from "../../hooks/useAppConfiguration";

interface Props {
  layerName: string;
  children: JSX.Element
}

/**
 * Renders the children only when the layer is allowed to be selected.
 * @param layerName
 * @param children
 * @constructor
 */
export function ProtectedLayer({ layerName, children }: Readonly<Props>) {
  const appConfiguration = useAppConfiguration();
  const allowedLayers = appConfiguration.selectableGeoLayers.geoLayers
    .map(category => category.layers)
    .flat()
    .map(x => x.name);

  if (!allowedLayers.includes(layerName)) {
    return <></>;
  }

  return children;
}