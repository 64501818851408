import { useRef } from "react";

/**
 * Hook to get the previous value of a variable (mostly used in combination with useEffect)
 * @param value value that needs to be stored
 * @returns  The previous value
 */
export default function usePrevious<Type>(value: Type): Type | undefined {
  const currentRef = useRef<Type>(value);
  const previousRef = useRef<Type>();
  if (currentRef.current !== value) {
    previousRef.current = currentRef.current;
    currentRef.current = value;
  }
  return previousRef.current;
}