import * as React from "react";
import { SVGProps } from "react";

const SvgClearDay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="clear-day_svg__a" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#clear-day_svg__a"
      width={375}
      height={375}
      transform="translate(68.5 68.5)"
    />
  </svg>
);

export default SvgClearDay;
