import { LoadingComponent } from "app/components/LoadingComponent";
import { useIncidents } from "app/hooks/useIncidents";
import { useUser } from "app/hooks/useUser";
import { Incident } from "app/models/incident";
import { IncidentFilter } from "app/models/incidentFilter";
import style from "./style.module.scss";
import { IncidentItem } from "./components/IncidentItems/IncidentItem";
import { ToggleButton } from "app/components/ToggleButton";
import { IncidentTypeFilter } from "app/models/enums/incidentTypeFilter";
import { Checkbox } from "app/components/Checkbox";
import { IncidentPriorityFilter } from "app/models/enums/incidentPriorityFilter";
import { Button } from "app/components/Button";
import { useEffect, useState } from "react";
import usePrevious from "app/hooks/usePrevious";

type Props = {
  filter: IncidentFilter,
  onFilterChange: (v: IncidentFilter) => void;
  onIncidentSelect: (v: Incident) => void;
  isActive: boolean;
};

type Pagination = {
  take: number,
};

/**
 *  Component that displays the incidents in a list.
 */
export function IncidentListView({ filter, onFilterChange, onIncidentSelect, isActive }: Readonly<Props>) {
  const { user } = useUser();
  const [pagination, setPagination] = useState<Pagination>({ take: 10 });
  const { incidents } = useIncidents({ isManschap: user?.isEenheid ?? true, onlyHighPrio: filter.priority, typeIncidents: filter.type, keepRefreshing: isActive, take: pagination.take });
  const previousIncidents = usePrevious(incidents);

  /**
   *  Function that is called when the filter value changes
   */
  function onFilterValueChanged(value: string) {
    onFilterChange({
      ...filter,
      type: value === "actief" ? IncidentTypeFilter.Active : IncidentTypeFilter.Closed
    });
    setPagination({ ...pagination, take: 10 });
  }

  /**
   * Function that is called when the user wants to load more incidents
   * @remarks This function will increase the take value by 10, only applicable when the filter is set to closed.
   */
  function loadMore() {
    setPagination({ ...pagination, take: pagination.take + 10 });
  }

  /**
   * Function that opens a new incident automatically when a new incident is added for the manschappen
   */
  useEffect(() => {
    const flattenedPreviousIncidents = previousIncidents?.flat();
    const flattenedIncidents = incidents?.flat();

    if (flattenedPreviousIncidents && flattenedIncidents && flattenedPreviousIncidents.length < flattenedIncidents.length) {
      const newIncident = flattenedIncidents.find(v => !flattenedPreviousIncidents.some(p => p.incidentId === v.incidentId));
      if (newIncident?.isActive && user?.isEenheid)
        onIncidentSelect(newIncident);
    }
  }, [incidents]);

  return (
    <div id={style.listView}>
      <ToggleButton
        values={["actief", "afgesloten"]}
        currentValue={filter.type == IncidentTypeFilter.Active ? "actief" : "afgesloten"}
        onChange={onFilterValueChanged} />
      <Checkbox
        className={style.priofilter}
        label="Alleen PRIO 1 en 2"
        checked={filter.priority == IncidentPriorityFilter.HighPriority}
        onChange={() => onFilterChange({ ...filter, priority: filter.priority == IncidentPriorityFilter.HighPriority ? IncidentPriorityFilter.All : IncidentPriorityFilter.HighPriority })}
        variant="contained"
      />
      {
        !incidents
          ? <LoadingComponent />
          : incidents.sort((a, b) => new Date(b[0].startDate).valueOf() - new Date(a[0].startDate).valueOf()).map(incidentGroup =>
            <div className={style.groupedIncidentWrapper} key={crypto.randomUUID()}>
              {incidentGroup.map(incident => <div key={incident.incidentId} className={`${incident.leidendIncidentId ? style.hasLeidendIncident : ""}`} >
                <IncidentItem incident={incident} onClick={onIncidentSelect} />
              </div>)}
            </div>
          )
      }
      {(filter.type == IncidentTypeFilter.Closed && incidents) && <Button onClick={loadMore} variant={"contained"} label="Laad meer" />}
    </div>
  );
}
