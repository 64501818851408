type Props = {
  stroke: string;
  strokeWidth: number;
  background: string;
  diameter: number;
  orientation: "up" | "down";
  direction: "left" | "right";
  showPercentValue: boolean;
  percentage: number;
};

/**
 * Component that displays a semicircle progress bar.
 * @component
 * @param stroke The stroke color (HEX) of the progress bar.
 * @param strokeWidth The stroke width of the progress bar.
 * @param background The background color of the progress bar.
 * @param diameter The diameter of the progress bar.
 * @param orientation The orientation of the progress bar.
 * @param direction The direction of the progress bar.
 * @param showPercentValue Show the percentage value in the progress bar.
 * @param percentage The percentage value of the progress bar.
 * @returns {JSX.Element} The SemiCircleProgress component.
 */
export default function SemiCircleProgress({stroke, strokeWidth, background, diameter, orientation, direction, showPercentValue, percentage}: Readonly<Props>){
  const coordinateForCircle = diameter / 2;
  const radius = (diameter - 2 * strokeWidth) / 2;
  const circumference = Math.PI * radius;
  const semiCirclePercentage = percentage * (circumference / 100);

  let rotation;
  if (orientation === "down") {
    if (direction === "left") {
      rotation = "rotate(180deg) rotateY(180deg)";
    } else {
      rotation = "rotate(180deg)";
    }
  } else if (direction === "right") {
    rotation = "rotateY(180deg)";
  }

  return (
    <div className="semicircle-container" style={{ position: "relative" }}>
      <svg
        width={diameter}
        height={diameter / 2}
        style={{ transform: rotation, overflow: "hidden" }}
      >
        <circle
          cx={coordinateForCircle}
          cy={coordinateForCircle}
          r={radius}
          fill="none"
          stroke={background}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          style={{
            strokeDashoffset: circumference
          }}
        />
        <circle
          cx={coordinateForCircle}
          cy={coordinateForCircle}
          r={radius}
          fill="none"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          style={{
            strokeDashoffset: semiCirclePercentage,
            transition:
              "stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s"
          }}
        />
      </svg>
      {showPercentValue && (
        <span
          className="semicircle-percent-value"
          style={{
            width: "100%",
            left: "0",
            textAlign: "center",
            bottom: orientation === "down" ? "auto" : "0",
            position: "absolute"
          }}
        >
          {percentage}%
        </span>
      )}
    </div>
  );
}