import useGeoServer from "../../hooks/useGeoServer";
import { useCallback } from "react";
import { useMap } from "../../contexts/mapContext";
import { PopupContent } from "../../models/popupContent";
import { Templates } from "../MapboxPopup/TemplateManager";
import EventDispatcher, { CustomEvents } from "../../../eventDispatcher";
export const ObjectInformatieLayerName = "internal_cogo_dbk";

export function ObjectInformatieLayer() {
  const { fetchFeatures } = useGeoServer(ObjectInformatieLayerName, [
    { name: "vrmwb-besloten:roi_gebouw_geo_pand", source: "COGO", isTiled: false, isClickable: false },
    { name: "vrmwb-besloten:roi_gebouw_geo_bereikbaarheid", source: "COGO", isTiled: false, isClickable: false },
    { name: "vrmwb-besloten:roi_gebouw_geo_gevaarlijkestof", source: "COGO", isTiled: false, isClickable: true },
    { name: "vrmwb-besloten:roi_gebouw_geo_label", source: "COGO", isTiled: false, isClickable: false },
    { name: "vrmwb-besloten:roi_gebouw_geo_object", source: "COGO", isTiled: false, isClickable: true },
    { name: "vrmwb-besloten:roi_gebouw_geo_opstelplaats", source: "COGO", isTiled: false, isClickable: false },
    { name: "vrmwb-besloten:roi_gebouw_geo_risico", source: "COGO", isTiled: false, isClickable: true },
    { name: "vrmwb-besloten:roi_gebouw_geo_scheiding", source: "COGO", isTiled: false, isClickable: false },
    { name: "vrmwb-besloten:roi_gebouw_geo_sector", source: "COGO", isTiled: false, isClickable: false },
    { name: "vrmwb-besloten:roi_gebouw_geo_voorziening", source: "COGO", isTiled: false, isClickable: true, includeSnapshot: true },
  ]);

  /**
   * Add a click event to the map
   */
  useMap(map => {
    map.on("click", onMapClick);

    return () => {
      map.off("click", onMapClick);
    };
  }, []);

  /**
   * Callback for the click event
   * @remarks This function is memoized to prevent unnecessary re-renders and to prevent the function from being recreated on every render
   *          It needs to be the exact same function to unsubscribe from the event.
   */
  const onMapClick = useCallback((e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const bbox = e.lngLat.toBounds(10);
    const boundingBox = [[bbox.getSouthWest().lng, bbox.getSouthWest().lat], [bbox.getNorthEast().lng, bbox.getNorthEast().lat]];
    fetchFeatures(boundingBox).then(result => {
      if (result.length == 0)
        return;

      const voorziening = result.filter(f => f["icon_svg"]);
      const objectgegevens = result.filter(f => !f["icon_svg"]);

      if (voorziening.length > 0) {
        const content: PopupContent = {
          id: `CogoDBKLayerName-${crypto.randomUUID()}`,
          tabName: "Voorzieningen",
          template: Templates.Voorziening,
          data: voorziening
        };
        EventDispatcher.emit(CustomEvents.PopupOpened, content);
      }

      if (objectgegevens.length > 0) {
        const content2: PopupContent = {
          id: `CogoDBKLayerName-${crypto.randomUUID()}`,
          tabName: "Objectinformatie",
          template: Templates.Default,
          data: objectgegevens
        };
        EventDispatcher.emit(CustomEvents.PopupOpened, content2);
      }
    });
  }, []);

  return <></>;
}
