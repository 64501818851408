import * as React from "react";
import { SVGProps } from "react";

const SvgWindBeaufort12 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="wind-beaufort-12_svg__a" viewBox="0 0 342 234">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M264.2 21.3A40 40 0 1 1 293 89H9m139.2 123.7A40 40 0 1 0 177 145H9"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#wind-beaufort-12_svg__a"
      width={342}
      height={234}
      transform="translate(85 139)"
    />
    <path
      fill="#374251"
      d="M367.5 344.4h-19v-45h-17.7v-12.6h3q7.8 0 12.3-3.4 4.3-3.1 5.6-10.2l.2-.8h15.5Zm69.7-.8h-55.7q0-9.4 4-15.7 4-6.1 14.9-13.5l10.9-7.7a22.2 22.2 0 0 0 5.8-5.5 10.2 10.2 0 0 0 1.8-5.8 8.7 8.7 0 0 0-2.6-6.7 9.8 9.8 0 0 0-7-2.4q-10 0-10 11.8v1.4h-16.8v-2.1q0-12.2 7.1-19t20.2-6.8q12.4 0 19.7 6t7.4 16.6a22.2 22.2 0 0 1-3.5 12.3q-3.5 5.4-11.2 10.4l-12.1 7.7c-2.3 1.5-3.6 2.8-3.8 3.8h31Z"
    />
  </svg>
);

export default SvgWindBeaufort12;
