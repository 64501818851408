/**
 * Component that will be displayed when an uncaught error occurs in the application.
 * @constructor
 */
export function ErrorFallback() {
  return(
    <div className="critical-error-wrapper">
      <div className="vrwmb-logo"><img alt={"Veiligheidsregio Midden- en West-Brabant logo"} src="assets/images/vrmwb-logo.png" /></div>
      <h4>Sorry, er ging iets fout binnen de viewer.</h4>
      <p> De fout is doorgestuurd naar de afdeling Informatisering. <br /> De applicatie zal zich binnen 5 seconden herstarten, <a onClick={() => window.location.reload()}>als dit niet lukt klik dan hier.</a></p>
    </div>
  );
}