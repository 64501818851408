import { DetermineApiUri } from "config";
import style from "./style.module.scss";
import { SelectableGeoLayers } from "app/models/selectableGeoLayers";
import useSWR, { useSWRConfig } from "swr";
import { useContext } from "react";
import { UserContext } from "app/contexts/userContext";
import { SharedMapConfigurationContext } from "app/contexts/mapContext";
import { useResourceFetch } from "app/hooks/useResourceFetch";
import { LoadingComponent } from "app/components/LoadingComponent";

export function GeolayersSelectionModal({ onClose }: Readonly<{ onClose?: () => void }>) {
  const protectedFetch = useResourceFetch();
  const { mutate } = useSWRConfig();
  const { userSettings, selectedLayers, userSettingsDispatch } = useContext(UserContext);
  const { setSharedMapConfiguration } = useContext(SharedMapConfigurationContext);
  const { data: selectableLayers } = useSWR<SelectableGeoLayers>(`${DetermineApiUri()}/api/geo/selectablelayers`);

  /**
   * Toggle a layer on or off on the map 
   * @param layerName name of the layer
   * @param enabled boolean indicating wether the layer should be enabled or disabled
   */
  function toggleLayer(layerName: string, enabled: boolean) {
    const payload: Record<string, boolean> = {};
    payload[`${layerName}`] = enabled;
    setSharedMapConfiguration(old => {
      return { ...old, externalLayers: { ...old.externalLayers, ...payload } };
    });
    userSettingsDispatch({ type: "UPDATE_LAYERS", payload });
  }

  async function saveSelectionAsync() {
    if (selectedLayers) {
      const data: string[] = [];

      Object.entries(selectedLayers).forEach(([layerName, enabled]) => {
        if (enabled)
          data.push(layerName);
      });

      await protectedFetch(`${DetermineApiUri()}/api/settings`, {
        method: "PUT",
        headers: {
          "content-type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({
          ...userSettings,
          selectedLayers: data
        })
      });
      // Invalidate cache:
      mutate(`${DetermineApiUri()}/api/settings`);

      if (onClose)
        onClose();
    }
  }

  return (
    <div className={style.modal}>
      <div className={style.header}>
        <h1>Selecteer kaartlagen</h1>
      </div>
      {!selectableLayers && <div className={style.loadingWrapper}><LoadingComponent /></div>}
      <div className={style.layerWrapper}>
        {selectableLayers?.geoLayers.map((category) => (
          <div key={crypto.randomUUID()} className={style.layerCategory}>
            <h4>{category.title}</h4>
            {category.layers.map((layer) => (
              <div key={crypto.randomUUID()} className={style.layer}>
                <Toggle isActive={selectedLayers[layer.name]} onValueChange={(value) => toggleLayer(layer.name, value)} />
                <span className={style.layerName}>{layer.title}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={style.actions}>
        <button onClick={onClose}>Sluiten</button>
        <button onClick={saveSelectionAsync}>Vastleggen als standaard</button>
      </div>
    </div>
  );

}

export function Toggle({ isActive, onValueChange }: Readonly<{ isActive: boolean, onValueChange: (value: boolean) => void }>) {
  return (
    <div className={`${style.toggle} ${isActive ? style.active : ""}`} onClick={() => onValueChange(!isActive)}>
      <div className={style.sliderBackground}></div>
      <input type="checkbox" onChange={(e) => onValueChange(e.target.checked)} />
      <div className={style.slider}></div>
    </div>
  );
}


/** Include autocomplete */