import {InteractionStatus} from "@azure/msal-browser";
import {useMsal} from "@azure/msal-react";
import {useEffect, useState} from "react";
import {loginRequest} from "../../../config";
import {ErrorFallback} from "../ErrorFallback";

/**
 * State of a protected component.
 */
enum ProtectedState {
  LOADING,
  SUCCESS,
  ERROR
}

type Props = {
  children: JSX.Element
};

/**
 * A ProtectedComponent renders it's children only when the user is authenticated.
 * It will try to redirect directly to a SSO page using MSAL if the current user is not signed in.
 */
export function AuthGuard({ children }: Readonly<Props>) {
  const { instance, inProgress, accounts } = useMsal();
  const [state, setState] = useState(ProtectedState.LOADING);

  useEffect(() => {
    (async () => {
      try{
        if(inProgress != InteractionStatus.None)
          return;

        if(accounts.length < 1){
          await instance.loginRedirect(loginRequest);
        }else{
          instance.setActiveAccount(accounts[0]);
          setState(ProtectedState.SUCCESS);
        }

      }catch(e) {
        console.error(e);
        await instance.logoutRedirect();
        setState(ProtectedState.ERROR);
      }
    })();
  }, [accounts, inProgress]);

  switch (state) {
    case ProtectedState.LOADING:
      return (
        <div className="critical-error-wrapper">
          <div className="vrwmb-logo"><img alt={"Veiligheidsregio Midden- en West-Brabant logo"} src="/assets/images/vrmwb-logo.png"/></div>
          <p> U wordt automatisch doorverwezen naar Microsoft om in te loggen. <br/> Wordt u niet automatisch doorgestuurd? <a role={"link"} onKeyDown={() => instance.loginRedirect(loginRequest)} onClick={() => instance.loginRedirect(loginRequest)}>Klik dan hier.</a></p>
        </div>
      );
    case ProtectedState.SUCCESS:
      return children;

    default:
      return <ErrorFallback/>;
  }
}