import mapboxgl, { LngLatLike, Marker } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";
import EventDispatcher, { CustomEvents } from "eventDispatcher";

const regionCenter = [4.775692, 51.591161];
const defaultRegionZoomLevel = 10;
const defaultIncidentZoomLevel = 15;
const incidentMarker = document.createElement("div");
incidentMarker.className = "pulseIcon";
incidentMarker.id = "incident";

/***
 * Component for changing the azure maps based on incident
 */
export function IncidentLocationLayer() {
  const [incidentLocationMarker] = useState<Marker>(new Marker(incidentMarker));
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const SetIncidentMarkerCallback = useCallback(SetIncidentMarker, []);
  const ClearIncidentMarkCallback = useCallback(ClearIncidentMark, []);

  useEffect(() => {
    EventDispatcher.on(CustomEvents.SetIncidentMarker, (payload: { map: mapboxgl.Map | undefined, lat: number | undefined, lon: number | undefined }) => {
      SetIncidentMarkerCallback(payload.map, payload.lat, payload.lon);
    });

    EventDispatcher.on(CustomEvents.ClearIncidentMark, () => {
      ClearIncidentMarkCallback();
    });

    return () => {
      EventDispatcher.off(CustomEvents.SetIncidentMarker, SetIncidentMarkerCallback);
      EventDispatcher.off(CustomEvents.ClearIncidentMark, ClearIncidentMarkCallback);
    };

  }, []);

  function SetIncidentMarker(map: mapboxgl.Map | undefined, lat: number | undefined, lon: number | undefined) {
    if (!map) {
      console.error("Map context not found");
      return;
    }

    // If the marker is already visible and the lat long is the same, we don't need to do anything
    if (isVisible && incidentLocationMarker.getLngLat()?.lat === lat && incidentLocationMarker.getLngLat()?.lng === lon)
      return;

    // If incident doesn't have a lat long, we default to the center of the region.
    if (!lon || !lat) {
      incidentLocationMarker.remove();
      setIsVisible(false);
      map.flyTo({
        center: regionCenter as LngLatLike,
        zoom: defaultRegionZoomLevel
      });

      return;
    }

    incidentLocationMarker.setLngLat([lon, lat]);
    map?.flyTo({
      center: incidentLocationMarker.getLngLat(),
      zoom: defaultIncidentZoomLevel
    });

    setIsVisible(true);
    incidentLocationMarker.addTo(map);
  }

  /**
   * Clear the incident marker on the map
   */
  function ClearIncidentMark(): void {
    incidentLocationMarker.remove();
    setIsVisible(false);
  }

  return <></>;
}
