import { UserContext } from "app/contexts/userContext";
import { Incident } from "app/models/incident";
import { IncidentFilter } from "app/models/incidentFilter";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { EenheidStatus } from "app/models/eenheidStatus";
import style from "./style.module.scss";
import { IncidentListView } from "./components/IncidentListView";
import { IncidentDetailView } from "./components/IncidentDetailView";
import EventDispatcher, { CustomEvents } from "eventDispatcher";
import { MapContext } from "app/contexts/mapContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import WeatherWidget from "../WeatherWidget";
import { CustomCyclomediaEvents } from "../Cyclomedia/models/CustomCyclomediaEvents";
import FloatingTimer from "../FloatingTimer";

type IncidentPanelState = {
  filter: IncidentFilter,
  isMinimized: boolean,
  incidentId: number | undefined,
  currentView: "list" | "detail",
  eenheden: EenheidStatus[],
  openedLocation?: [number, number]
}

/**
 * Component that displays the incidents and incident details.
 */
export function IncidentPanel() {
  const mapContext = useContext(MapContext);
  const callbackedOpenIncident = useCallback(openIncident, []);
  const view = useRef<HTMLDivElement>(null);
  const { userSettings, userSettingsDispatch } = useContext(UserContext);
  const [incidentPanelState, setIncidentPanelState] = useState<IncidentPanelState>({
    filter: userSettings.incidentFilter,
    isMinimized: false,
    currentView: "list",
    eenheden: [],
    incidentId: undefined,
  });


  /**
   * Bind events
   */
  useEffect(() => {
    if (!view.current)
      return;

    view.current.addEventListener("scroll", (e) => {
      EventDispatcher.emit(CustomCyclomediaEvents.MoveStreetSmart, { up: view.current?.scrollTop });
    });

  }, [view]);

  /**
   * Open an incident
   */
  function openIncident(incidentId: number) {
    setIncidentPanelState({ ...incidentPanelState, currentView: "detail", incidentId: incidentId });
  }

  /**
   * Function that is called when the panel is minimized.
   */
  function minimize() {
    setIncidentPanelState({ ...incidentPanelState, isMinimized: !incidentPanelState.isMinimized });
  }

  /**
   * Function that is called when an incident is closed.
   */
  function onIncidentClose() {
    setIncidentPanelState({ ...incidentPanelState, currentView: "list", incidentId: undefined });
    EventDispatcher.emit(CustomEvents.ClearIncidentMark);
  }

  /**
   * Function that is called when the list view filter changes
   * @param incidentFilter The new filter value
   */
  function onFilterChange(incidentFilter: IncidentFilter) {
    setIncidentPanelState({ ...incidentPanelState, filter: incidentFilter });
    userSettingsDispatch({ type: "SET_SETTINGS", payload: { ...userSettings, incidentFilter: incidentFilter } });
  }

  /**
   * Function that is called when an incident is selected
   * @param incident The incident that is selected
   */
  function onIncidentSelect(incident: Incident) {
    setIncidentPanelState({ ...incidentPanelState, currentView: "detail", incidentId: incident.incidentId, openedLocation: [incident.lat, incident.lon] });
    EventDispatcher.emit(CustomEvents.SetIncidentMarker, { map: mapContext, lat: incident.lat, lon: incident.lon });
  }

  useEffect(() => {
    EventDispatcher.on(CustomEvents.OpenIncident, callbackedOpenIncident);
    return () => {
      EventDispatcher.off(CustomEvents.OpenIncident, callbackedOpenIncident);
    };
  }, []);

  useEffect(() => {
    if (incidentPanelState.isMinimized)
      EventDispatcher.emit(CustomCyclomediaEvents.MoveStreetSmart, { left: 300 });
    else {
      EventDispatcher.emit(CustomCyclomediaEvents.MoveStreetSmart, { left: 0 });
    }

  }, [incidentPanelState.isMinimized]);

  return (
    <div className={incidentPanelState.isMinimized ? `${style.isMinimized} ${style.panelWrapper}` : style.panelWrapper}>
      <div ref={view} id={style.incidentPanel} className={incidentPanelState.isMinimized ? style.isMinimized : ""} >
        {incidentPanelState.currentView == "detail" &&
          <div id={style.detailViewContainer}>
            <IncidentDetailView
              isMinimized={incidentPanelState.isMinimized}
              incidentId={incidentPanelState.incidentId}
              onClose={onIncidentClose}
              toggleMinimized={minimize}
            />
          </div>
        }
        {incidentPanelState.currentView == "list" &&
          <div id={style.listViewContainer}>
            <IncidentListView
              filter={incidentPanelState.filter}
              onFilterChange={onFilterChange}
              onIncidentSelect={onIncidentSelect}
              isActive={true} />
          </div>
        }
      </div>
      <div
        id={style.minimizeButton}
        role="button"
        className={incidentPanelState.isMinimized ? style.isMinimized : ""}
        onClick={minimize}
        onKeyDown={minimize}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div id={style.weatherWidget} className={incidentPanelState.isMinimized ? style.isMinimized : ""}>
        <WeatherWidget location={incidentPanelState.openedLocation} />
      </div>
      <FloatingTimer isMinimized={incidentPanelState.isMinimized} />
    </div>
  );
}