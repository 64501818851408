import { Speed, WeatherApiResponse, WeatherForecastApiResponse, Wind } from "app/models/weatherApiResult";
import useSWR from "swr";
import { useAppConfiguration } from "./useAppConfiguration";
import { usePosition } from "./usePosition";

const weatherApiUri = (lat: number, lon: number) =>
  `https://atlas.microsoft.com/weather/currentConditions/json?api-version=1.1&query=${lat},${lon}&language=nl-NL`;

const weatherForecastApiUri = (lat: number, lon: number) =>
  `https://atlas.microsoft.com/weather/forecast/hourly/json?api-version=1.1&query=${lat},${lon}&duration=24&language=nl-NL`;

export function useAzureMapsWeather(location: [number, number] | undefined) {
  const appConfiguration = useAppConfiguration();
  const { latitude, longitude } = usePosition();

  const fetcher = async (input: RequestInfo, init?: RequestInit) => {
    init = {
      headers: {
        ...init?.headers,
        "subscription-key": appConfiguration.azureMapsSubscriptionKey
      }
    };
    const response = await fetch(input, init);
    if (!response.ok) {
      throw new Error("fetch Weather Data: Failed");
    }

    return response.json();
  };

  const mapMetersPerSecondWindSpeedToWind = (wind: Wind): Wind => {
    const conversionFactor = 0.277778;
    // Unit type is derived from https://learn.microsoft.com/en-us/azure/azure-maps/weather-services-concepts
    const metersPerSecondSpeed = {
      value: Math.round((wind.speed.value * conversionFactor) * 1e1) / 1e1,
      unit: "m/s",
      unitType: 10
    } as Speed;

    return { ...wind, metersPerSecondSpeed };
  };

  const { data: currentWeatherResponse } = useSWR<WeatherApiResponse>(
    (location ?
      weatherApiUri(location[0], location[1]) :
      latitude && longitude ?
        weatherApiUri(latitude, longitude) :
        weatherApiUri(51.57, 4.76))
    , fetcher);

  const { data: weatherForecastResponse } = useSWR<WeatherForecastApiResponse>(
    (location ?
      weatherForecastApiUri(location[0], location[1]) :
      latitude && longitude ?
        weatherForecastApiUri(latitude, longitude) :
        weatherForecastApiUri(51.57, 4.76))
    , fetcher);

  const currentWeather =
    currentWeatherResponse && {
      ...currentWeatherResponse,
      results: currentWeatherResponse.results.map(result => ({
        ...result,
        wind: mapMetersPerSecondWindSpeedToWind(result.wind)
      }))
    };

  const weatherForecast =
    weatherForecastResponse && {
      ...weatherForecastResponse,
      forecasts: weatherForecastResponse.forecasts.map(forecast => ({
        ...forecast,
        wind: mapMetersPerSecondWindSpeedToWind(forecast.wind)
      }))
    };

  return { currentWeather, forecast: weatherForecast };
}