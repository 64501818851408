import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis Sectoren Informatie layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/3000}
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/3400}
 */
export const SectorenInformatieDefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.sectorenInformatie,
  layers: [
    {
      layerId: 3000,
      layerDefinitions: [
        {
          "id": `${LayerLabels.sectorenInformatie}-sector-brandweer`,
          "type": "fill",
          "paint": {
            "fill-color": "rgba(255,170,0,0)",
            "fill-outline-color": "rgba(255,170,0,255)",
          }
        }
      ]
    },
    {
      layerId: 3400,
      layerDefinitions: [
        {
          "id": `${LayerLabels.sectorenInformatie}-sector-tekst`,
          "type": "symbol",
          "layout": {
            "text-field": "{OPMERKING}", // Use the OPMERKING attribute for the label
            "text-font": ["Open Sans Semibold"], // Specify the font
            "text-size": 15,
            "text-allow-overlap": true, // Allow the text to overlap
          },
          "paint": {
            "text-halo-blur": 20,
            "text-halo-color": "rgba(255,255,255,1)", // Updated to match the new halo color [255,255,255,255]
          }
        }
      ]
    }
  ],
  isToggledByKermisInformatieLayer: true,
};