import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis Grid Informatie layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/2000}
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/3300}
 */
export const GridInformatieDefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.gridInformatie,
  layers: [
    {
      layerId: 2000,
      layerDefinitions: [
        {
          "id": `${LayerLabels.gridInformatie}-gridInformatie-layer`,
          "type": "fill",
          "paint": {
            "fill-color": "rgba(255,255,255,0)", // Updated to match the new color [255,255,255,0]
            "fill-outline-color": "rgba(0,0,0,1)", // Updated to match the new outline color [0,0,0,255] and opacity adjusted for Mapbox
          }
        }
      ]
    },
    {
      layerId: 3300,
      layerDefinitions: [
        {
          "id": `${LayerLabels.gridInformatie}-textGrid-layer`,
          "type": "symbol",
          "layout": {
            "text-field": "{OPMERKING}", // Use the OPMERKING attribute for the label
            "text-font": ["Open Sans Semibold"], // Specify the font
            "text-size": 15,
            "text-allow-overlap": true, // Allow the text to overlap
          },
          "paint": {
            "text-halo-blur": 20,
            "text-halo-color": "rgba(255,255,255,1)", // Updated to match the new halo color [255,255,255,255]
          }
        }
      ]
    }
  ],
  isToggledByKermisInformatieLayer: false,
};