import { EenheidStatus } from "app/models/eenheidStatus";
import { IncidentPriorityFilter } from "app/models/enums/incidentPriorityFilter";
import { IncidentTypeFilter } from "app/models/enums/incidentTypeFilter";
import { Incident } from "app/models/incident";
import { DetermineApiUri } from "config";
import useSWR from "swr";

type UseIncidentsProp = {
  /**
   * Indicator whether the user is a manschap
   * @remarks This is used to get incidents from a different endpoint
   */
  isManschap: boolean;

  /**
   * Filter only high priority incidents
   */
  onlyHighPrio: IncidentPriorityFilter;

  /**
   * Type of incidents to retrieve
   */
  typeIncidents: IncidentTypeFilter;

  /**
   * Indicator whether the incidents should keep refreshing
   */
  keepRefreshing?: boolean;

  take?: number;
};

/**
 * Hook for fetching incidents from the OIV backend
 * @returns incidents, mutate, error
 */
export const useIncidents = ({ isManschap, onlyHighPrio, typeIncidents, keepRefreshing, take }: Readonly<UseIncidentsProp>) => {
  const {
    data: incidents,
    error,
    mutate
  } = useSWR<Incident[]>(`${generateIncidentsUri(isManschap, typeIncidents)}?take=${take}`, { refreshInterval: keepRefreshing ? 5000 : 0 });

  const groupedIncidents = groupRelatedIncidents(onlyHighPrio == IncidentPriorityFilter.HighPriority ? incidents?.filter(v => v.prioriteitBrandweer == "1" || v.prioriteitBrandweer == "2") : incidents);

  return {
    incidents: groupedIncidents ? Object.values(groupedIncidents) : undefined,
    mutate,
    error
  };
};

/**
 * Hook for fetching a single incident from the OIV backend
 * @returns incident, mutate, error
 */
export const useIncident = ({ incidentId, keepRefreshing }: Readonly<{ incidentId: number | undefined, keepRefreshing: boolean }>) => {
  const { data: incident } = useSWR<Incident>(incidentId ? `${DetermineApiUri()}/api/incident/details?id=${incidentId}` : null, { refreshInterval: keepRefreshing ? 5000 : 0 });
  const { data: eenheden } = useSWR<EenheidStatus[]>(incidentId ? `${DetermineApiUri()}/api/incident/eenhedenStatuses?incidentId=${incidentId}` : null, { refreshInterval: 5000 });

  return {
    incident,
    eenheden
  };
};

/**
 * Group related incidents together
 * @remarks This function groups incidents that are related to each other together; This can't be done by the backend which will impact the performance.
 */
function groupRelatedIncidents(incidents: Incident[] | undefined): Record<number, Incident[]> | undefined {
  if (!incidents)
    return undefined;

  const result: Record<number, Incident[]> = [];
  incidents.forEach(incident => {
    if (!incident.leidendIncidentId) {
      if (Object.hasOwnProperty.call(result, incident.incidentId))
        result[incident.incidentId].push(incident);
      else
        result[incident.incidentId] = [incident];
      return;
    }

    const group = result[incident.leidendIncidentId];
    if (!group) {
      result[incident.leidendIncidentId] = [incident];
      return;
    }

    group.push(incident);
  });

  return result;
}

/**
 * Generate the incidents uri based on the user role and the type of incidents
 * @param isManschap  Indicator whether the user is a manschap
 * @param typeIncidents  Type of incidents to retrieve
 * @returns The uri to fetch the incidents
 */
function generateIncidentsUri(isManschap: boolean, typeIncidents: IncidentTypeFilter): string {
  if (isManschap) {
    return `${DetermineApiUri()}/api/incident/${typeIncidents == IncidentTypeFilter.Active ? "active" : "closed"}`;
  }
  return `${DetermineApiUri()}/api/incident/${typeIncidents == IncidentTypeFilter.Active ? "active/all" : "closed/all"}`;
}