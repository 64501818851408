import React, {ChangeEvent} from "react";
import {Button} from "@mui/material";
import {Add, Collections, PlusOne} from "@mui/icons-material";

/**
 * Button the handles file uploading
 * @param Props
 * @constructor
 */
export function FileUploadButton(Props: { text: string, handleFile: any, isBusy: boolean, allowMultipleFiles: boolean, compactStyling: boolean }){
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  /**
   * Handle click on button to trigger field
   */
  function handleClick(){
    if(hiddenFileInput.current)
      hiddenFileInput.current.click();
  }

  /**
   * Handle ChangeEvent
   * @param event
   */
  function handleChange(event: ChangeEvent<HTMLInputElement>){
    const file = event.target.files;
    // 8MB in bytes
    const maxFileSize = 8388608;

    if(file){
      let invalidSize = false;

      Array.prototype.forEach.call(file, element => {
        if(element.size > maxFileSize){
          Props.handleFile(null, "De afbeelding is te groot. De maximale grootte is 8MB.");
          invalidSize = true;
        }
      });

      if(invalidSize){
        return;
      }

      Props.handleFile(file);
      event.target.value = "";
    }
  }

  if(Props.compactStyling){
    return (
      <>
        <button disabled={Props.isBusy}  onClick={handleClick} style={{
          height: 40,
          width: 40,
          border: "2px solid #D0021B",
          backgroundColor: "#D0021B",
          padding: 5,
          color: "#FFF",
          borderRadius: "50%"
        }}><Add></Add></button>
        <input
          type="file"
          accept="image/png, image/jpeg"
          multiple={Props.allowMultipleFiles}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: "none"}}
        />

      </>
    );
  }

  return (
    <>
      <Button startIcon={<Collections />} disabled={Props.isBusy}  style={{flex: 2, height: 60}} variant="contained" onClick={handleClick}>
        {Props.text}
      </Button>
      <input
        type="file"
        accept="image/*"
        multiple={Props.allowMultipleFiles}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: "none"}}
      />
    </>
  );
}