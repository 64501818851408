import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis EVA layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/1500}
 */
export const EVADefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.eva,
  layers: [
    {
      layerId: 1500,
      layerDefinitions: [
        {
          "id": LayerLabels.eva,
          "type": "fill",
          "paint": {
            "fill-color": "rgba(211,255,190,255)",
            "fill-outline-color": "rgba(137,68,68,255)"
          }
        }
      ]
    },
  ],
  isToggledByKermisInformatieLayer: true
};