import "./style.scss";
import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAzureMapsWeather } from "app/hooks/useAzureMapsWeather";
import { Forecast, WeatherResult } from "app/models/weatherApiResult";
import {
  NavigationIcon,
  ThermoMeterIcon,
  WindIntensityIcon,
  UvIndexIcon,
  WeatherIcon
} from "./Icons";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ValidateDate } from "app/services/parseWithDate";
import { useMapBearing } from "app/hooks/useMapBearing";
import EventDispatcher, { CustomEvents } from "../../../eventDispatcher";

export default function WeatherWidget({ location }: Readonly<{ location: [number, number] | undefined }>) {
  const [isExtended, setIsExtended] = useState(false);
  const { bearing } = useMapBearing();
  const [isOpen, setIsOpen] = useState(true);

  EventDispatcher.on(CustomEvents.CloseAllTools, () => {
    setIsOpen(false);
    setIsExtended(false);
  });

  useEffect(() => {
    if (!location)
      setIsOpen(true);
    else
      setIsOpen(false);
  }, [location]);

  const { currentWeather, forecast } = useAzureMapsWeather(location);

  if (!currentWeather || !forecast)
    return (
      <div id="meteo">
        <div className="weatherWidget" >
          <div className="icon-container">
            <CircularProgress />
          </div>
        </div>
      </div>
    );

  const weather = currentWeather.results[0];

  return (
    <>
      <div id="meteo">
        <div id="weatherWidget" onClick={() => {
          setIsOpen(!isOpen);
          if (isOpen)
            setIsExtended(false);
        }} className={isOpen ? "weatherWidget open" : "weatherWidget"}>
          <button className={isOpen ? "weather-button hidden" : "weather-button"}>
            <NavigationIcon direction={weather.wind.direction.degrees + 180 - bearing} />
            <p>{weather.wind.metersPerSecondSpeed.value} {weather.wind.metersPerSecondSpeed.unit}</p>
          </button>
          <WeatherDetails data={weather} bearing={bearing} />
        </div>
        <button className={isOpen ? "expand-button" : "hidden"} onClick={(e) => {
          e.stopPropagation();
          setIsExtended(!isExtended);
        }}>
          {isExtended ? <ExpandLess /> : <ExpandMore />}
        </button>
        <div id="meteo-extended">
          <div id="forecastWidget" className={isExtended ? "forecastWidget open" : "forecastWidget"}>
            <WeatherForecast data={forecast.forecasts} bearing={bearing} />
          </div>
        </div>
      </div>
    </>
  );
}

interface WeatherDetailsProps {
  data: WeatherResult;
  bearing: number;
}

function WeatherDetails({ data, bearing }: WeatherDetailsProps) {
  return (
    <>
      <div className="icon-container">
        <ThermoMeterIcon />
        <Typography variant="h6" fontSize="small">{data.temperature.value}</Typography>
      </div>
      <div className="icon-container">
        <WeatherIcon iconCode={(data.iconCode)} />
        <Typography variant="h6" fontSize="small">{data.phrase}</Typography>
      </div>
      <div className="icon-container">
        <NavigationIcon direction={data.wind.direction.degrees + 180 - bearing} />
        <Typography variant="h6" fontSize="small">{`${data.wind.direction.localizedDescription}`}</Typography>
      </div>
      <div className="icon-container">
        <WindIntensityIcon speed={data.wind.speed.value} />
        <Typography variant="h6" fontSize="small">{`${data.wind.metersPerSecondSpeed.value} ${data.wind.metersPerSecondSpeed.unit}`}</Typography>
      </div>
      <div className="icon-container">
        <UvIndexIcon uvIndex={data.uvIndex} />
        <Typography variant="h6" fontSize="small">{data.uvIndexPhrase}</Typography>
      </div>
    </>
  );
}

interface WeatherForcastProps {
  data: Forecast[];
  bearing: number;
}

function WeatherForecast({ data, bearing }: WeatherForcastProps) {
  return (
    <>
      {data.map((forecast) => {
        forecast.date = ValidateDate(forecast.date);
        return (
          <div key={forecast.date.getTime()} className="row">
            <div className="icon-container">
              <Typography variant="h6" fontSize="large" align="center">{`${forecast.date.getHours()}:00`}</Typography>
            </div>
            <div className="icon-container">
              <ThermoMeterIcon />
              <Typography variant="h6" fontSize="small">{forecast.temperature.value}</Typography>
            </div>
            <div className="icon-container">
              <WeatherIcon iconCode={(forecast.iconCode)} />
              <Typography variant="h6" fontSize="small">{forecast.iconPhrase}</Typography>
            </div>
            <div className="icon-container">
              <NavigationIcon direction={forecast.wind.direction.degrees + 180 - bearing} />
              <Typography variant="h6" fontSize="small">{`${forecast.wind.direction.localizedDescription}`}</Typography>
            </div>
            <div className="icon-container">
              <WindIntensityIcon speed={forecast.wind.speed.value} />
              <Typography variant="h6" fontSize="small">{`${forecast.wind.metersPerSecondSpeed.value} ${forecast.wind.metersPerSecondSpeed.unit}`}</Typography>
            </div>
            <div className="icon-container">
              <UvIndexIcon uvIndex={forecast.uvIndex} />
              <Typography variant="h6" fontSize="small">{forecast.uvIndexPhrase}</Typography>
            </div>
          </div>
        );
      })}
    </>
  );
}
