import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis Attracties layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/Kermis_attracties_extern_rp/FeatureServer/0}
 */
export const AttractiesDefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.attracties,
  layers: [
    {
      layerId: 0,
      layerDefinitions: [{
        "id": `${LayerLabels.attracties}-default`,
        "type": "fill",
        "paint": {
          "fill-color": "rgba(247,247,245,0.8)",
          "fill-outline-color": "rgba(130,130,130,1)"
        }
      },
      {
        "id": `${LayerLabels.attracties}-consumptieve-waren`,
        "type": "fill",
        "filter": ["==", ["get", "CATEGORIE"], "Consumptieve waren"],
        "paint": {
          "fill-color": "rgba(240,110,5,0.4)",
          "fill-outline-color": "rgba(240,110,5,1)"
        }
      },
      {
        "id": `${LayerLabels.attracties}-grootvermaak`,
        "type": "fill",
        "filter": ["==", ["get", "CATEGORIE"], "Grootvermaak"],
        "paint": {
          "fill-color": "rgba(0,156,218,0.4)",
          "fill-outline-color": "rgba(0,156,218,1)"
        }
      },
      {
        "id": `${LayerLabels.attracties}-kinderzaken`,
        "type": "fill",
        "filter": ["==", ["get", "CATEGORIE"], "Kinderzaken"],
        "paint": {
          "fill-color": "rgba(226,0,122,0.4)",
          "fill-outline-color": "rgba(226,0,122,1)"
        }
      },
      {
        "id": `${LayerLabels.attracties}-niet-bekend`,
        "type": "fill",
        "filter": ["==", ["get", "CATEGORIE"], "Niet bekend"],
        "paint": {
          "fill-color": "rgba(193,206,203,0.8)",
          "fill-outline-color": "rgba(130,130,130,1)"
        }
      },
      {
        "id": `${LayerLabels.attracties}-oefeningspelen`,
        "type": "fill",
        "filter": ["==", ["get", "CATEGORIE"], "Oefeningspelen"],
        "paint": {
          "fill-color": "rgba(0,139,108,0.4)",
          "fill-outline-color": "rgba(0,139,108,1)"
        }
      },
      {
        "id": `${LayerLabels.attracties}-overig`,
        "type": "fill",
        "filter": ["==", ["get", "CATEGORIE"], "Overig"],
        "paint": {
          "fill-color": "rgba(240,110,5,0.4)",
          "fill-outline-color": "rgba(240,110,5,1)"
        }
      },
      {
        "id": `${LayerLabels.attracties}-debiteur-naam"`,
        "type": "symbol",
        "layout": {
          "text-field": ["get", "DEBITEURNAAM"],
          "text-size": 12,
          "text-anchor": "top",
          "text-offset": [0, 1.2] // Adjusts the text position slightly above the point
        },
        "paint": {
          "text-color": "#000000" // Set the text color as needed
        }
      }]
    },
  ],
  isToggledByKermisInformatieLayer: true
};