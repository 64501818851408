import * as React from "react";
import { SVGProps } from "react";

const SvgWindBeaufort2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="wind-beaufort-2_svg__a" viewBox="0 0 342 234">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M264.2 21.3A40 40 0 1 1 293 89H9m139.2 123.7A40 40 0 1 0 177 145H9"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#wind-beaufort-2_svg__a"
      width={342}
      height={234}
      transform="translate(85 139)"
    />
    <path
      fill="#374251"
      d="M411.9 344h-55.7q0-9.4 4-15.7 4-6.1 14.8-13.5l11-7.8a22.4 22.4 0 0 0 5.9-5.5 10.2 10.2 0 0 0 1.7-5.8 8.7 8.7 0 0 0-2.6-6.7 9.8 9.8 0 0 0-7-2.4q-10 0-10 11.8v1.5h-17v-2.2q0-12.1 7.2-19t20-6.8q12.5 0 19.8 6t7.4 16.7a22 22 0 0 1-3.5 12.2q-3.5 5.5-11.2 10.4l-12.1 7.7c-2.3 1.5-3.6 2.8-3.8 3.9h31Z"
    />
  </svg>
);

export default SvgWindBeaufort2;
