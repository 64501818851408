import { OpenViewerOptions, ViewerType } from "@cyclomedia/streetsmart-api";

/**
 * Default options for the fullscreen viewer.
 */
export const defaultObliqueViewerOptions: OpenViewerOptions = {
  viewerType: [ViewerType.OBLIQUE],
  srs: "EPSG:28992",
  obliqueViewer: {
    closable: false,
    timeTravelVisible: false,
    maximizable: false,
    navbarVisible: false,
  }
};