import { SharedMapConfigurationContext, useMap } from "app/contexts/mapContext";
import { SharedMapConfiguration } from "app/models/mapConfiguration";
import { useCallback, useContext } from "react";
import { useAppConfiguration } from "../../hooks/useAppConfiguration";
import { ProtectedLayer } from "../ProtectedLayer";
import { Templates } from "../MapboxPopup/TemplateManager";
import useGeoServer from "../../hooks/useGeoServer";
import mapboxgl from "mapbox-gl";
import { PopupContent } from "../../models/popupContent";
import EventDispatcher, { CustomEvents } from "../../../eventDispatcher";

/**
 * Generic geo layers component
 * @constructor
 * @remarks Renders every GeoServer layer that isn't labeled as "internal" as a separate layer.
 */
export function GenericGeoServerLayers() {
  const appConfiguration = useAppConfiguration();
  const { sharedMapConfiguration } = useContext<SharedMapConfiguration>(SharedMapConfigurationContext);
  const notClickableLayers = ["vik:natuurbrand_weg"];

  return (
    <>
      {Object.entries(sharedMapConfiguration.externalLayers)
        .filter(x => x[1])
        .filter(x => !x[0]
          .includes("internal")).map(x => {
          const layer = appConfiguration
            .selectableGeoLayers
            .geoLayers
            .map(category => category.layers)
            .flat()
            .filter(x => x.name.includes("internal"))
            .find(layer => layer.name == x[0]);        

          let isClickable = true;
          if(notClickableLayers.includes(x[0]))
            isClickable = false;      
          
          return (
            <ProtectedLayer key={`${x[0]}-protected`} layerName={x[0]}>
              <GenericGeoServerLayer key={`${x[0]}-component`} title={layer?.title ?? x[0]} name={x[0]} source={"VRMWB"} isTiled={false} isClickable={isClickable} />
            </ProtectedLayer>
          );
        })}
    </>
  );
}

interface Props {
  name: string;
  title: string;
  source: "VRMWB" | "COGO";
  isTiled: boolean;
  isClickable: boolean;
}


const GenericTemplateSelector = [
  ["vik:voorzieningen_duiken", Templates.Duiken],
  ["vik:bestand_sleutelbuizen_nieuw_2023_liveop", Templates.Toegangsleutel]
];

/**
 * Generic GeoServer layer
 * @param name Name of the layer
 * @param title Title that is displayed
 * @param source Source of the layer
 * @param isTiled Is the layer tiled
 * @param isClickable Is the layer clickable
 * @constructor
 */
export function GenericGeoServerLayer({ name, title, source, isTiled, isClickable }: Readonly<Props>) {
  const { fetchFeatures } = useGeoServer(name, [
    { name: name, source: source, isTiled: isTiled, isClickable: isClickable },
  ]);

  /**
   * Callback for the click event
   * @remarks This function is memoized to prevent unnecessary re-renders and to prevent the function from being recreated on every render
   *          It needs to be the exact same function to unsubscribe from the event.
   */
  const onMapClick = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const bbox = e.lngLat.toBounds(10);
      const boundingBox = [[bbox.getSouthWest().lng, bbox.getSouthWest().lat], [bbox.getNorthEast().lng, bbox.getNorthEast().lat]];
      fetchFeatures(boundingBox).then(result => {
        if (result.length == 0)
          return;

        const content: PopupContent = {
          id: name,
          tabName: title,
          template: GenericTemplateSelector.find(x => x[0] == name)?.[1] as Templates ?? Templates.Default,
          data: result
        };

        EventDispatcher.emit(CustomEvents.PopupOpened, content);
      }
      );
    }, []
  );

  /**
   * Add a click event to the map
   */
  useMap(map => {
    map.on("click", onMapClick);

    return () => {
      map.off("click", onMapClick);
    };
  }, []);

  return <></>;
}