import React, { createContext, useContext, useEffect, useState } from "react";
import { AppConfiguration } from "app/models/appConfiguration";
import { useResourceFetchJson } from "./useResourceFetch";
import {DetermineApiUri} from "../../config";

const AppConfigurationContext = createContext<AppConfiguration>(null!);

/**
 * Retrieves and provides the global application configuration.
 */
export function AppConfigurationProvider({ children }: Readonly<{ children: React.JSX.Element }>) {
  const [appConfig, setAppConfig] = useState<AppConfiguration | null>(null);
  const resourceFetchJson = useResourceFetchJson();

  useEffect(() => {
    resourceFetchJson(`${DetermineApiUri()}/api/AppConfiguration`)
      .then((response: AppConfiguration) => {
        // Equal check for values to prevent unnecessary state changes (and rendering).
        if (!appConfig?.isEqual(response)) {
          setAppConfig(response);
        }
      });
  }, []);

  if (!appConfig)
    return null;

  return <AppConfigurationContext.Provider value={ appConfig }>{children}</AppConfigurationContext.Provider>;
}

/**
 * Use the global application configuration.
 * @returns AppConfiguration
 */
export function useAppConfiguration() {
  return useContext(AppConfigurationContext);
}
