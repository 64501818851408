import {ApplicationInsights} from "@microsoft/applicationinsights-web";

export type LogLevel = "verbose" | "log" | "warn" | "error";
export interface AdditionalLogData {
  [key: string]: unknown;
}

/**
 * Custom logger class for logging to multiple sources
 * @remarks This logger class has been made static to be used anywhere in the application. It's not a function class cause you can use this logger also in map events.
 */
class Logger {
  level: LogLevel = "log";

  /**
   * Log a error
   * @param  {string} message
   * @param  {unknown} error
   * @param  {AdditionalLogData} additionalLogData
   * @returns void
   */
  error(message: string, error: unknown, additionalLogData: AdditionalLogData = {}): void {
    console.error(message, [error, additionalLogData]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (globalThis.applicationInsights as ApplicationInsights).trackException({exception: error, properties: additionalLogData});
  }

  /**
   * Log a message as a warning
   * @param  {string} message
   * @param  {AdditionalLogData} additionalLogData
   * @returns void
   */
  warn(message: string, additionalLogData: AdditionalLogData = {}): void {
    if (this.level == "error")
      return;

    const stackTrace = new Error().stack?.replace("Error", "");
    additionalLogData.stackTrace = stackTrace ?? "";
    console.warn(message, additionalLogData);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (globalThis.applicationInsights as ApplicationInsights).trackEvent({name: "Warning", properties: {...additionalLogData, "message": message}});
  }

  /**
   * Log a message
   * @param  {string} message
   * @param  {AdditionalLogData} additionalLogData
   * @returns void
   */
  log(message: string, additionalLogData: AdditionalLogData = {}): void {
    if (this.level == "error" || this.level == "warn")
      return;

    const stackTrace = new Error().stack?.replace("Error", "");
    additionalLogData.stackTrace = stackTrace ?? "";
    console.debug(message, additionalLogData);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (globalThis.applicationInsights as ApplicationInsights).trackEvent({name: "Debug", properties: {...additionalLogData, "message": message}});
  }

  /**
   * Log a message as verbose (Verbose isn"t logged to application insights)
   * @param  {string} message
   * @param  {AdditionalLogData} additionalLogData
   * @returns void
   */
  verbose(message: string, additionalLogData?: AdditionalLogData): void {
    if (this.level != "verbose")
      return;

    if (additionalLogData == undefined)
      console.debug(message);
    else
      console.debug(message, additionalLogData);
  }
}

export const logger = new Logger();