import {useEffect, useState} from "react";

type Props = {
  /**
   * The start time of the timer.
   */
  startTime: Date;

  /**
   * The end time of the timer.
   */
  endTime?: Date;
};

/**
 * Basic timer component showing the past time.
 * @constructor
 */
export default function TimerComponent({startTime, endTime}: Readonly<Props>) {
  const [time, setTime] = useState(0);

  /**
   * Get the elapsed time between two dates.
   * @param startTime
   * @param endTime
   */
  function getElapsedTime(startTime: Date, endTime?: Date){
    if(endTime){
      return Math.min(new Date(endTime).getTime() - new Date(startTime).getTime(), Infinity);
    }

    return Math.min(new Date().getTime() - new Date(startTime).getTime(), Infinity);
  }

  /**
   * Update the timer every second.
   */
  useEffect(() => {
    if(endTime){
      setTime(getElapsedTime(startTime, endTime));
      return;
    }

    setTime(getElapsedTime(startTime));

    const intervalId = setInterval(() => {
      setTime(getElapsedTime(startTime));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime, endTime]);


  const hours = Math.floor(time / 3600000);
  const minutes = Math.floor((time % 3600000) / 60000);
  const seconds = Math.floor((time % 60000) / 1000);

  return (
    <span>{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{ seconds.toString().padStart(2, "0")}</span>
  );
}
