import style from "./style.module.scss";

/**
 * Component displaying the disciplines of an incident
 * @param diciplines - the disciplines of the incident, e.g. ["-AB", "P--"]
 * @constructor
 */
export function DisciplineIndicator({disciplines}: Readonly<{disciplines: string}>) {
  return (
    <div className={style.disciplineIndicator}>
      {disciplines.includes("P") &&
        <div style={{backgroundColor: "blue", flex: 1}}/>}
      {disciplines.includes("B") &&
        <div style={{backgroundColor: "red", flex: 1}}/>}
      {disciplines.includes("A") &&
        <div style={{backgroundColor: "yellow", flex: 1}}/>}
      {disciplines == "---" &&
        <div style={{backgroundColor: "orange", flex: 1}}/>}
    </div>
  );
}
