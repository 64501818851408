import { VehicleStatus } from "../Popups/VehiclePopup/components/VehicleStatus";
import { useMachine } from "../../hooks/useMachines";
import style from "./style.module.scss";

/**
 * EenheidProfile component
 * @param roepnaamEenheid
 * @constructor
 */
export function EenheidProfile({ roepnaamEenheid, hideStatus }: Readonly<{ roepnaamEenheid: string, hideStatus?: boolean }>) {
  const machine = useMachine({ assetNumber: roepnaamEenheid.replace("-", "") });

  return (
    <div id={style.eenheidProfile}>
      <div id={style.details}>
        <p id={style.name}>{roepnaamEenheid}</p>
        {!hideStatus && <div id={style.manschapStatus}>
          <p style={{ float: "left", fontWeight: "bold" }}>Status: </p>
          <VehicleStatus showLabel={true} statusCode={machine?.status?.tActStatusCodeExtBrw} statusCodeAfkr={machine?.status?.tActStatusAfkBrw ?? ""} />
        </div>}
      </div>
      <img id={style.eenheidIcon} src="/assets/images/icons/icon_brw_status_called.svg" alt="vehicle" />
    </div>
  );
}
