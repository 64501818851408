import * as React from "react";
import { SVGProps } from "react";

const SvgUvIndex5 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <clipPath id="uv-index-5_svg__a">
        <path fill="none" d="M64 64h384v192H328a72 72 0 0 0-72 72v120H64Z" />
      </clipPath>
      <symbol id="uv-index-5_svg__b" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
    </defs>
    <g clipPath="url(#uv-index-5_svg__a)">
      <use
        xlinkHref="#uv-index-5_svg__b"
        width={375}
        height={375}
        transform="translate(68.5 68.5)"
      />
      <path
        fill="none"
        stroke="#fbbf24"
        strokeMiterlimit={10}
        strokeWidth={15}
        d="M254 338v-10a74 74 0 0 1 74-74h10"
      />
    </g>
    <rect width={144} height={144} x={280} y={280} fill="#ffb800" rx={48} />
    <path
      fill="#fff"
      d="M351.8 388q-12 0-19.7-5.5t-9-15.7l17.6-1.4a9.9 9.9 0 0 0 3.8 6 12 12 0 0 0 7.2 2.2 11.6 11.6 0 0 0 8.5-3 10.5 10.5 0 0 0 3-8 10.4 10.4 0 0 0-3-7.8 11.6 11.6 0 0 0-8.5-3 12.6 12.6 0 0 0-9.7 4.3l-16.6-1.5 6.4-38.6H376v14.7h-30.9l-2.3 12 .3.2q5.6-4 14.2-4 10.7 0 17 6.2t6.6 17.1q0 11.8-7.8 18.8t-21.3 7Z"
    />
  </svg>
);

export default SvgUvIndex5;
