import { Incident } from "app/models/incident";
import style from "./style.module.scss";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "app/components/Accordion";
import { Button } from "app/components/Button";
import EventDispatcher, { CustomEvents } from "eventDispatcher";

/**
 * Component that displays related incidents
 */
export function IncidentGerelateerd({ incidents }: Readonly<{ incidents: Incident[] }>) {

  /**
   * Function that is called to open a related incident
   * @param incidentId  The id of the incident to open
   */
  function openRelatedIncident(incidentId: number) {
    EventDispatcher.emit(CustomEvents.OpenIncident, incidentId);
  }

  return (
    <div className={`content-wrapper ${style.gerelateerdeIncidenten}`} >
      <Accordion title="Gerelateerde incidenten" titleStyle="ACTIVE" isOpenInitially={true}>
        {incidents.map(incident => (
          <>
            <div key={crypto.randomUUID()} className={style.relatedIncident}>
              <div className={style.labelWrapper}>
                <span className={`${style.incidentLabel} ${style.prio}`}>PRIO {incident.prioriteitBrandweer}</span>
                <span className={style.incidentLabel}>{incident.incidentNr}</span>
                <span className={style.date}>{incident.startDateShortTime} - {incident.startDateShort.split(" ")[0]}</span>
              </div>
              <div className={style.actions}>
                <Button onClick={() => { openRelatedIncident(incident.incidentId); }} icon={faLink} variant="contained" ></Button>
              </div>
            </div>
            <hr></hr>
          </>
        ))}
      </Accordion>
    </div >
  );
}