export class ConfigurationHelper {

  /**
   * The API key for the ArcGIS services
   */
  static readonly apiKey: Readonly<string> = "AAPTxy8BH1VEsoebNVZXo8HurANAJ4ua-8zbY3a5wZ0G_-XLcLM1g8HE9bremziuQCSpBwmJ3RfAtwvM1NgFals3DS1ojUooqD8MIU2facWED4OaXACeLw4v-loT1jVdI8vQcW8bmHj9C0bv10vhwFS3-m11yjIrLTpDLR4ijCKsDvsnzhzNhfedWLpE5sSmWgJMhVwrU6oGmu3qtU85Cf_s9x1eqL8s_eLQLvM2KPLSvL6grc-_7q3R-o1ke6wBLm-5AT1_3AeC8xhx";

  static readonly kermisKermisInformatie_RP: Readonly<string> = "https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer";

  static readonly kermis_attracties_extern_rp: Readonly<string> = "https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/Kermis_attracties_extern_rp/FeatureServer";
}