import * as React from "react";
import { SVGProps } from "react";

const SvgUvIndex9 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <clipPath id="uv-index-9_svg__a">
        <path fill="none" d="M64 64h384v192H328a72 72 0 0 0-72 72v120H64Z" />
      </clipPath>
      <symbol id="uv-index-9_svg__b" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
    </defs>
    <g clipPath="url(#uv-index-9_svg__a)">
      <use
        xlinkHref="#uv-index-9_svg__b"
        width={375}
        height={375}
        transform="translate(68.5 68.5)"
      />
      <path
        fill="none"
        stroke="#fbbf24"
        strokeMiterlimit={10}
        strokeWidth={15}
        d="M254 338v-10a74 74 0 0 1 74-74h10"
      />
    </g>
    <rect width={144} height={144} x={280} y={280} fill="#ff3c00" rx={48} />
    <path
      fill="#fff"
      d="M371.4 323.7q9.7 9 9.7 27.6 0 17.5-7.7 27.1t-22.3 9.6q-10.1 0-16.8-4.6a21.9 21.9 0 0 1-9-12.8l16.6-1.1q2.1 5.5 9.3 5.5 5.9 0 9-4t3.8-13q-6 7.1-16.6 7.1-10.9 0-17.6-6.4t-6.9-17.3q0-11.2 7.8-18.4t20-7q12.7 0 20.7 7.7Zm-20 28.5a11 11 0 0 0 8.2-3 12.2 12.2 0 0 0 3.3-8.3 11.6 11.6 0 0 0-2.7-7.3 11.2 11.2 0 0 0-9-3.7c-3.5 0-6.2 1-8.2 3.1a11.3 11.3 0 0 0-2.9 8 10.9 10.9 0 0 0 3.1 8.2 11.3 11.3 0 0 0 8.3 3Z"
    />
  </svg>
);

export default SvgUvIndex9;
