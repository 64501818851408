import {MelvinActivity} from "app/models/melvinActivity";
import {MelvinBelemmering, MelvinRestrictionDirection} from "app/models/melvinBelemmering";

interface Props {
  data: Record<string, string>[];
}

export function MelvinPopupTemplate({data}: Readonly<Props>) {
  const melding = data
    .filter(x => x["source"] !== undefined)
    .map((x) => new MelvinActivity(x))
    .filter((value, index, self) => self.findIndex(x => x.situationId == value.situationId) === index)[0];

  const beperkingen = data
    .filter(x => x["restrictionType"] !== undefined)
    .map((x) => new MelvinBelemmering(x))
    .filter((value, index, self) => self.findIndex(x => x.name == value.name) === index);

  return (
    <div className="melvin-popup">
      <div className="melvin-belemmeringen">
        {beperkingen.map((x) => {
          const directionIcon = x.direction == MelvinRestrictionDirection.BOTH
            ? "assets/images/icons/direction_both_restricted.svg"
            : "assets/images/icons/direction.svg";

          return (
            <div key={`popup-${crypto.randomUUID()}`} className="popup-category">
              <div className="columns">
                <div className="belemmering-basic-information">
                  <span className="category-header">{x.name}</span>
                  <div className="columns">
                    <span className="category-header">Toegang hulpdiensten: </span>
                    <p>{x.emergencyServiceAllowed}</p>
                  </div>
                </div>
                <img alt={x.direction} src={directionIcon}/>
              </div>
              {x.emergencyServiceDescription.length > 0 &&
                  <div>
                    <p className="service-description">{x.emergencyServiceDescription}</p>
                  </div>
              }
            </div>
          );
        })}
      </div>
      {melding &&
      <div className="melvin-melding-informatie popup-category">
        <div className="information-row">
          <span className={"label"}> Locatie: </span>
          <span>{melding.location?.road} {melding.location?.district} {melding.location?.city}</span>
        </div>
        {melding.location?.comment &&
            <span className={"comment"}>{melding.location?.comment}</span>
        }
        <div className="information-row">
          <span className={"label"}> Van: </span>
          <span>{new Date(melding.currentActivePeriod(new Date())?.startDate ?? "").toLocaleString()}</span>
        </div>
        <div className="information-row">
          <span className={"label"}> Tot: </span>
          <span>{new Date(melding.currentActivePeriod(new Date())?.endDate ?? "").toLocaleString()}</span>
        </div>
        {(melding.addition || melding.remarks) &&
            <>
              <div className="information-row full">
                <span className={"label"}> Opmerking: </span>
              </div>
              {melding.addition &&
                  <span
                    className="melding-addition">{melding.addition.charAt(0).toUpperCase() + melding.addition.slice(1)}</span>
              }
              {melding.remarks?.map((x) => {
                return (<span className={"remark"} key={`remark_${crypto.randomUUID()}`}>{x}</span>);
              })}
            </>
        }
      </div>
      }
    </div>
  );
}