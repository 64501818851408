import { EmojiPeople } from "@mui/icons-material";

import "./style.scss";
import { useCallback, useState } from "react";
import { useMap } from "app/contexts/mapContext";
import { MapMouseEvent } from "mapbox-gl";
import EventDispatcher from "eventDispatcher";
import { OpenLocationProps } from "../Cyclomedia/models/openLocationProps";
import { CustomCyclomediaEvents } from "../Cyclomedia/models/CustomCyclomediaEvents";

/**
 * Component for selecting a point on the map and opening a street view modal.
 * @component
 */
export default function ManualStreetViewSelector() {
  const [active, setActive] = useState(false);

  // Callback to ensure single instance of handleClick
  const handleClick = useCallback(function (e: MapMouseEvent) {
    EventDispatcher.emit(CustomCyclomediaEvents.OpenLocation, { coordinate: e.lngLat, openFullscreen: true, mode: "manual" } as OpenLocationProps);
    setActive(false);
  }, []);

  useMap((map) => {
    if (active) {
      map.on("click", handleClick);
    }
    else {
      map.off("click", handleClick);
    }
  }, [active]);

  return (
    <div className="menu-button-wrapper">
      <div className={active ? "street-view-select active" : "street-view-select"}>
        <button onClick={() => setActive(!active)}>
          <EmojiPeople />
        </button>
      </div>
    </div>
  );
}