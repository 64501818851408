import * as React from "react";
import { SVGProps } from "react";

const SvgUvIndex6 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <clipPath id="uv-index-6_svg__a">
        <path fill="none" d="M64 64h384v192H328a72 72 0 0 0-72 72v120H64Z" />
      </clipPath>
      <symbol id="uv-index-6_svg__b" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
    </defs>
    <g clipPath="url(#uv-index-6_svg__a)">
      <use
        xlinkHref="#uv-index-6_svg__b"
        width={375}
        height={375}
        transform="translate(68.5 68.5)"
      />
      <path
        fill="none"
        stroke="#fbbf24"
        strokeMiterlimit={10}
        strokeWidth={15}
        d="M254 338v-10a74 74 0 0 1 74-74h10"
      />
    </g>
    <rect width={144} height={144} x={280} y={280} fill="#ff8d00" rx={48} />
    <path
      fill="#fff"
      d="M356.5 338.8q11 0 17.7 6.5t6.8 17.3q0 11.3-7.7 18.4t-20.2 7q-12.6 0-20.6-7.7-9.5-9-9.5-27.7 0-17.5 7.6-27t22.2-9.6q10.1 0 16.8 4.6a22.3 22.3 0 0 1 9 13l-16.6 1q-2.1-5.6-9.3-5.6-6 0-9 4t-3.7 13q5.8-7.2 16.5-7.2Zm-12.7 31.6a11.5 11.5 0 0 0 8.8 3.7q5.4 0 8.3-3.2a11.9 11.9 0 0 0 2.9-8 10.6 10.6 0 0 0-3.2-8 11.2 11.2 0 0 0-8.2-3.1 11 11 0 0 0-8 3 12 12 0 0 0-3.4 8.2 12.3 12.3 0 0 0 2.8 7.4Z"
    />
  </svg>
);

export default SvgUvIndex6;
