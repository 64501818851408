import { ActionType, UserSettingsContext } from "app/contexts/userContext";

export function userSettingsContextReducer(state: UserSettingsContext, action: ActionType): UserSettingsContext {
  switch (action.type) {
    case "SET_SETTINGS":
      return { ...state, userSettings: action.payload };

    case "UPDATE_LAYERS":
      return {
        ...state, selectedLayers: {
          ...state.selectedLayers,
          ...action.payload
        }
      };
    default: {
      throw new Error("Unhandled action type");
    }
  }
}
