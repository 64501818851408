import React from "react";

interface Props {
  target: React.RefObject<HTMLElement>,
  onIntersect: IntersectionObserverCallback,
  threshold: number,
  rootMargin: string
}

/**
 * Use Intersection Observer
 * @param props
 * @remarks Enables the use of the [Intersection Observer API]{@link https://developer.mozilla.org/en-US/docs/Web /API/Intersection_Observer_API}
 */
const useIntersectionObserver = (props: Props) => {
  React.useEffect(() => {
    const observer = new IntersectionObserver(props.onIntersect, {
      rootMargin: props.rootMargin,
      threshold: props.threshold
    });
    const current = props.target.current;
    if(current){
      observer.observe(current);
    }
    return () => {
      if(current) {
        observer.unobserve(current);
      }
    };
  });
};

export default useIntersectionObserver;