/**
 * Component for showing telemetry values
 * @param label
 * @param value
 * @param unit
 * @constructor
 */
export function TelemetryValue({ label, value, unit }: Readonly<{
  label?: string,
  value?: number | string,
  unit?: string
}>) {
  return (
    <div className={"telemetry-value"}>
      {label && <div className={"label"}>{label}:</div>}
      <span className={"value"}>{value}{unit}</span>
    </div>
  );
}