import style from "./style.module.scss";

type ToggleButtonProps = {
  values: string[],
  currentValue: string,
  onChange: (value: string) => void
}

export function ToggleButton({ values, currentValue, onChange }: Readonly<ToggleButtonProps>) {
  return (
    <div className={style.toggleButton}>
      {values.map((value, index) => (
        <button
          key={crypto.randomUUID()}
          className={value === currentValue ? style.active : ""}
          onClick={() => onChange(value)}
        >
          {value}
        </button>
      ))}
    </div>
  );
}