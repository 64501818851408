import {Kladblokregel, Kladblokregels} from "../models/incident";
import {useEffect, useState} from "react";
import useSWR from "swr";
import {DetermineApiUri} from "../../config";
import {toast} from "react-toastify";
import {useUser} from "./useUser";

/**
 * Hook for fetching kladblokregels from the OIV backend
 * @param incidentId The incident id to fetch the kladblokregels for
 * @param forceOpen Whether to force open the kladblok (this will trigger an email send to monitor access).
 */
export const useKladblok = ({incidentId, forceOpen, isIncidentClosed, isMinimized, toggleMinimized}: Readonly<{
  incidentId: number | null,
  forceOpen: boolean,
  isIncidentClosed: boolean,
  isMinimized: boolean, 
  toggleMinimized: () => void
}>) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [regels, setRegels] = useState<Kladblokregel[]>([]);
  const [newRegels, setNewRegels] = useState<Kladblokregel[]>([]);
  const {user} = useUser();

  const {data} = useSWR<Kladblokregels>(!incidentId ? null : `${DetermineApiUri()}/api/Kladblok?incidentId=${incidentId}&forceOpen=${forceOpen}`, {
    revalidateOnFocus: !(isIncidentClosed && forceOpen),
    revalidateOnMount: !(isIncidentClosed && forceOpen),
    refreshInterval: !(isIncidentClosed && forceOpen) ? 5000 : 0,
  });

  useEffect(() => {
    if(!data)
      return;

    if(isLoading)
      setIsLoading(false);

    // If there are new kladblokregels, send a toast to the user
    if(data && regels.length > 0 && regels.length < data.kladblokregels?.length && !isIncidentClosed) {
      const newRegels = data.kladblokregels.filter(x => !regels.some(y => y.dateCreated == x.dateCreated || x.userName == user?.displayName));
      if(newRegels.length){
        setNewRegels(newRegels);        
        sendToast();
      }
    }

    setRegels(data.kladblokregels);
  }, [data]); 

  
  function toastClose(){    
    toggleMinimized();
    setNewRegels([]);
  }
  /**
   * Sends a toast to the user that new kladblokregels are available, only when incident is closed
   */
  function sendToast(){
    if(isMinimized){ 
      toast.info("Er zijn nieuwe kladblokregels beschikbaar", {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        theme: "colored",
        closeOnClick: true,
        toastId: "new-kladblok-regels",
        onClose: () => toastClose()
      });
    }
  }

  return {
    isIsOtherDisciplinesPrivacyFiltered: data?.isOtherDisciplinesPrivacyFiltered,
    isPrivacyFiltered: data?.isPrivacyFiltered,
    kladblokRegels: regels,
    newKladblokRegels: newRegels,
    isLoading
  };
};


