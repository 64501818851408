/**
 * Waterwinning popup template for showing waterwinning data
 * @param data FeatureProperties from the GeoJSON
 * @returns WaterwinningPopupTemplate
 */
export function WaterwinningPopupTemplate({ data }: Readonly<{ data: Record<string, string>[] }>) {
  return (
    <div className="popup-category">
      <table>
        <tr><td><span className={"label"}>Type:</span></td><td>{data[0]["type"]}</td></tr>
        {data[0]["soort_uitvoering"] && <tr><td><span className={"label"}>Uitvoering:</span></td><td>{data[0]["soort_uitvoering"]}</td></tr>}
        {data[0]["nok_maat"] && <tr><td><span className={"label"}>Nokmaat:</span></td><td>{data[0]["nok_maat"]}</td></tr>}
        {data[0]["diameter"] && <tr><td><span className={"label"}>Diameter:</span></td><td>{data[0]["diameter"]} mm</td></tr>}
        <tr><td><span className={"label"}>Capaciteit</span></td><td>{data[0]["capaciteit"] ? `${data[0]["capaciteit"]} m3/uur` : "-"}</td></tr>
        <tr><td><span className={"label"}>Code</span></td><td>{data[0]["optioneel1"] ?? "-"}</td></tr>
        <tr><td colSpan={2}><span className={"label"}>Bijzonderheden</span></td></tr>
        <tr><td colSpan={2}>{data[0]["bijzonderheden"] ?? "-"}</td></tr>
      </table>
    </div>
  );
}