import { ObliqueViewer, StreetSmartApi } from "@cyclomedia/streetsmart-api";
import { useAppConfiguration } from "app/hooks/useAppConfiguration";
import { useEffect, useState } from "react";
import { defaultPanoramaViewerOptions } from "../models/defaultPanoramaViewerOptions";
import { incidentMarkerSLD } from "../styleDefinitions/incidentMarkerSLD";
import { logger } from "app/services/logger";
import { defaultObliqueViewerOptions } from "../models/defaultObliqueViewerOptions";

type SupportedViewerTypes = "oblique" | "panorama";

/**
 * Hook that provides functionality to interact with the Cyclomedia Street Smart API.
 */
export function useStreetSmartAPI(container: React.RefObject<HTMLDivElement>) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const appConfig = useAppConfiguration();

  /**
   * Initialize the Cyclomedia Street Smart API whenever the container is ready.
   */
  useEffect(() => {
    if (!container.current) {
      logger.warn("Cyclomedia Street Smart container is not available.");
      return;
    }

    setIsLoading(true);
    initializeStreetSmartApi().then(() => {
      setIsLoading(false);
    });
  }, [container.current]);

  /**
 * Function that initializes the Cyclomedia Street Smart API.
 */
  async function initializeStreetSmartApi(): Promise<void> {
    if (!container.current)
      throw new Error("Cyclomedia Street Smart container is not available.");

    if (!appConfig.cyclomediaStreetSmartConfiguration)
      throw new Error("Cyclomedia Street Smart configuration is not available.");

    // If the Cyclomedia Street Smart API is already initialized, don't do anything.
    if (isInitialized())
      return;

    const { apiKey, username, password } = appConfig.cyclomediaStreetSmartConfiguration;

    if (!apiKey || !username || !password)
      return;

    return StreetSmartApi.init({
      targetElement: container.current,
      srs: "EPSG:28992",
      locale: "nl",
      apiKey: apiKey,
      username: username,
      password: password,
      addressSettings: {
        locale: "nl",
        database: "CMDatabase"
      },
      loginOauth: false,
    });
  }

  /**
   *  Function that opens the Cyclomedia Street Smart viewer.
   * @param type  Viewer type to open
   * @param coordinate position in RD format
   * @param addMarker boolean indicating whether a marker should be added to the viewer
   */
  async function openViewer(type: SupportedViewerTypes[], coordinate: number[], addMarker = true) {
    setHasError(false);

    if (!isInitialized())
      throw new Error("Cyclomedia Street Smart API is not initialized.");

    type.forEach(async (viewerType) => {
      const options = viewerType === "oblique" ? defaultObliqueViewerOptions : defaultPanoramaViewerOptions;
      await StreetSmartApi.open({
        coordinate: coordinate,
      }, options);

      if (StreetSmartApi.getViewers().length === 0) {
        setHasError(true);
        return;
      }

      const obliqueViewer = StreetSmartApi.getViewers().find(viewer => viewer?.getType()?.toString() === "@@ViewerType/OBLIQUE") as ObliqueViewer | undefined;
      if (obliqueViewer)
        obliqueViewer.toggleCompass(true);

    });

    if (addMarker)
      addLocationMarker(coordinate);
  }

  /**
   *  Function that closes the Cyclomedia Street Smart viewer.
   * @param type Viewer type to close
   */
  async function closeViewer(type: SupportedViewerTypes[]) {
    if (!isInitialized())
      throw new Error("Cyclomedia Street Smart API is not initialized.");
    const viewers = StreetSmartApi.getViewers();

    type.forEach(async (viewerType) => {
      const cyclomediaType = viewerType === "oblique" ? "@@ViewerType/OBLIQUE" : "@@ViewerType/PANORAMA";
      const viewer = viewers.find(view => view?.getType()?.toString() === cyclomediaType);
      if (viewer)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        StreetSmartApi.closeViewer(viewer.getId());
    });

    setHasError(false);
  }

  /**
 * Function that detects if the Cyclomedia Street Smart API is initialized.
 * @returns boolean indicating whether the API is initialized or not.
 */
  function isInitialized(): boolean {
    return StreetSmartApi.getApiReadyState();
  }

  /**
 * Function that adds a location marker to the Cyclomedia Street Smart container.
 * @param coordinate  position in RD format
 */
  function addLocationMarker(coordinate: number[]): void {
    if (!isInitialized())
      return;

    if (coordinate.length !== 3)
      throw new Error("Coordinate should be an array of 3 numbers (x, y, z)");


    StreetSmartApi.removeOverlay("IncidentMarker");
    StreetSmartApi.addOverlay({
      name: "IncidentMarker",
      sourceSrs: "EPSG:28992",
      sldXMLtext: incidentMarkerSLD,
      geojson: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coordinate,
            }
          }
        ]
      }
    });
  }

  return {
    isLoading,
    hasError,
    isInitialized,
    openViewer,
    closeViewer
  };
}