import {FileUploadButton} from "../FileUploadButton/FileUploadButton";
import {RemoveCircle} from "@mui/icons-material";
import {BaseFormField} from "./BaseFormField";
import {toBase64} from "../../services/base64FileHandling";
import { useState } from "react";
import { InputLabel } from "@mui/material";

/**
 * Class for passing data to a FileFormField
 */
export class FileFormFieldData extends BaseFormField {
  allowMultipleFiles: boolean;
  compactStyling: boolean;
  selectedFiles: string[];
  onValueChanged: any;

  /**
   * Default constructor
   * @param displayName Name that will be displayed in the label
   * @param fieldName Name that is used for the FormData
   * @param defaultValue Default value
   * @param allowMultipleFiles Enables the selection of multiple files
   * @param compactStyling If true the button will be displayed as a "+" instead of a full button
   * @param onValueChanged Function that handles the change of the selectedFiles array
   */
  constructor(displayName: string, fieldName: string, defaultValue: string, allowMultipleFiles: boolean, compactStyling: boolean, onValueChanged: any) {
    super(displayName, fieldName, defaultValue);
    this.allowMultipleFiles = allowMultipleFiles;
    this.compactStyling = compactStyling;
    this.selectedFiles = [];
    this.onValueChanged = onValueChanged;
  }
}

type Props = {
  field: FileFormFieldData
}

/**
 * File selection field
 * @param field
 * @constructor
 */
export function FileFormField({field}: Props) {

  const [fileUploadError, setFileUploadError] = useState("");

  /**
   * Get Base64 strings form the files
   * @param files
   */
  function handleFile(files: FileList, invalidMessage: string) {
    if(invalidMessage){
      setFileUploadError(invalidMessage);
      return;
    }

    if(fileUploadError){
      setFileUploadError("");
    }

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        toBase64(file).then(base64 => {
          if (!field.selectedFiles.includes(base64)) {
            field.selectedFiles.push(base64);
            field.onValueChanged(field.selectedFiles);
          }
        });
      }
    }
  }

  return (
    <>
      <FileUploadButton compactStyling={true} text="Kies bestand" isBusy={false} handleFile={handleFile} allowMultipleFiles={true}/>
      <label className="input-label bijlage"> {field.displayName} (PNG of JPEG)</label>
      {fileUploadError ? <InputLabel size="small" error={true} >{fileUploadError}</InputLabel> : "" }      
      <div className="chosen-attachments">
        {
          field.selectedFiles?.map(x => (
            <div key={x} className="attachment" onClick={() => field.onValueChanged(field.selectedFiles.filter(y => y !== x))}>
              <RemoveCircle color="error"/>
              <img src={x}/>
            </div>
          ))
        }
      </div>
    </>);
}