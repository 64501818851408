import { Notification } from "./Notification";

/**
 * Notification for when the user is reconnected to the internet.
 * @see {@link /src/app/hooks/useNetworkStatus.ts}
 */
export const ReconnectNotification: Notification = {
  content: "Internetverbinding hersteld",
  type: "info",
  autoClose: true
};