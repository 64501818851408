import {useState} from "react";
import {Vehicle} from "../../../../models/vehicleLocation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Props {
  readonly vehicle: Vehicle;
}

/**
 * Component for vehicle bijzonder eenheid information
 * @param vehicle
 * @constructor
 */
export function VehicleBijzEenheid({vehicle}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  if(vehicle.status?.bijzEenheid == null || vehicle.status?.bijzEenheid == "")
    return (<></>);

  return (
    <div className={`bijzonder-status ${isExpanded ? "expanded" : ""}`} onClick={() => setIsExpanded(!isExpanded)}>
      {vehicle.status?.bijzEenheid}
      {vehicle.status?.bijzEenheid.length > 30 &&
      <div className={"arrow"}>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
      }
    </div>
  );
}