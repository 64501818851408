import { useMsal } from "@azure/msal-react";
import { SharedMapConfigurationContext, useMap } from "app/contexts/mapContext";
import { Vehicle } from "app/models/vehicleLocation";
import mapboxgl from "mapbox-gl";
import {MutableRefObject, useContext, useEffect, useState} from "react";
import {logger} from "app/services/logger";
import {VehicleMarker} from "../../models/vehicleMarker";
import {useMachines, VehicleFilters} from "../../hooks/useMachines";

type Props = {
  isAnimated: boolean;
  vehicleMarkers: MutableRefObject<VehicleMarker[]>;
}

/**
 * VehicleLayer is a layer that shows the vehicles on the map.
 * @Remarks this isn't a layer with source but markers that are managed by this component.
 */
export function VehicleLayer({isAnimated, vehicleMarkers} : Readonly<Props>){
  const { instance } = useMsal();
  const [filter, setFilter] = useState<VehicleFilters>("None" as VehicleFilters);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const { sharedMapConfiguration } = useContext(SharedMapConfigurationContext);
  const { machines, mutate } = useMachines({ filter, keepRefreshing: isEnabled });

  /**
   * Update the markers on the map based on the vehicles
   */
  useMap((map) => {
    if (!machines) {
      return;
    }

    if (isInitialized) {
      updateMarkers(machines, map);
    }
    else {
      initializeMarkers(machines, map);
    }

  }, [machines]);

  /**
   * Clean up the markers when the layer is disabled
   */
  useMap((map) => {
    if (isEnabled) {
      initializeMarkers(machines as Vehicle[] ?? [], map);
    }
    else {
      removeMarkers();
    }
  }, [isEnabled]);

  /**
   * Remove all markers when the filter changes
   * @remarks this makes sure no markers are updating of rendering when they are not needed
   */
  useMap((map) => {
    mutate();
    initializeMarkers(machines ?? [], map);
  }, [filter]);

  /**
   * Setup filter/enabled state based on the sharedMapConfiguration
   */
  useEffect(() => {
    const enabled = DetermineEnabled();
    if (enabled)
      DetermineFilter();
  }, [sharedMapConfiguration.externalLayers]);

  /**
   * Remove all markers when the component is unmounted
   */
  useEffect(() => {
    return () => {
      removeMarkers();
    };
  }, []);

  /**
   * Determine if the layer is enabled based on the sharedMapConfiguration
   */
  function DetermineEnabled(): boolean {
    if (("oiv_internal_active_vehicles" in sharedMapConfiguration.externalLayers && sharedMapConfiguration.externalLayers["oiv_internal_active_vehicles"]) ||
      ("oiv_internal_vehicles" in sharedMapConfiguration.externalLayers && sharedMapConfiguration.externalLayers["oiv_internal_vehicles"])) {
      setIsEnabled(true);
      return true;
    }
    else {
      setIsEnabled(false);
      return false;
    }
  }


  /**
   * Determine which filter to use based on the sharedMapConfiguration
   */
  function DetermineFilter() {
    if ("oiv_internal_vehicles" in sharedMapConfiguration.externalLayers && sharedMapConfiguration.externalLayers["oiv_internal_vehicles"]) {
      if (filter == "None")
        return;

      logger.verbose("[VehicleLayer] Setting filter to 'None'");
      setFilter("None");
    } else {
      if (filter == "Called")
        return;

      logger.verbose("[VehicleLayer] Setting filter to 'Called'");
      setFilter("Called");
    }
  }

  /**
   * Remove all markers from the map
   */
  function removeMarkers() {
    vehicleMarkers.current.forEach(marker => {
      marker.remove();
    });

    vehicleMarkers.current = [];
    setIsInitialized(false);
  }

  /**
   *  Set filter based on changes in the sharedMapConfiguration
   * @param vehicles
   * @param map
   */
  function initializeMarkers(vehicles: Vehicle[], map: mapboxgl.Map) {
    if (isInitialized) {
      return;
    }

    if (vehicles.length == 0) {
      return;
    }

    // Remove old markers if any, this makes sure that whenever there is a hot reload or double rendering only 1 marker per
    // vehicle is shown.
    removeMarkers();

    vehicles.forEach(vehicle => {
      try {
        const movingMarker = createMovingMarker(vehicle, map);
        vehicleMarkers.current.push(movingMarker);
      } catch (ex) {
        console.group("Error while creating marker");
        console.error("Exception", ex);
        console.error("Vehicle", vehicle);
        console.groupEnd();
      }

    });
    setIsInitialized(true);
  }

  /**
   * Create a moving marker on the map based on vehicles
   * @param vehicle
   * @param map
   */
  function createMovingMarker(vehicle: Vehicle, map: mapboxgl.Map): VehicleMarker {
    return new VehicleMarker(vehicle, map, isAnimated, instance);
  }

  /**
   * Update all markers on the map based on the vehicles
   * @param vehicles
   * @param map
   */
  function updateMarkers(vehicles: Vehicle[], map: mapboxgl.Map) {
    // If for the given vehicle there is no marker, then it is inactive and should be removed
    vehicleMarkers.current.forEach(marker => {
      if (vehicles.find(v => v.roepnaamEenheid == marker.id))
        return;

      logger.verbose(`[VehicleLayer] Removing marker for vehicle ${marker.id}`);
      marker.remove();

      // Remove the marker from the list
      vehicleMarkers.current = vehicleMarkers.current.filter(m => m.id != marker.id);
    });

    vehicles.forEach(vehicle => {
      const marker = vehicleMarkers.current.find(m => m.id == vehicle.roepnaamEenheid);
      if (!marker) {
        logger.verbose(`[VehicleLayer] Creating new marker for vehicle ${vehicle.roepnaamEenheid}`);
        const newMarker = createMovingMarker(vehicle, map);
        vehicleMarkers.current.push(newMarker);
        return;
      }

      marker.update(vehicle);
    });
  }

  return <></>;
}