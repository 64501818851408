import {Vehicle} from "../../models/vehicleLocation";
import { VehicleStatus } from "../Popups/VehiclePopup/components/VehicleStatus";

/**
 * Props for the GenericVehicleMarker
 */
interface Props {
  vehicle: Vehicle;
}

/**
 * State of the vehicle marker
 * @remarks this is used to determine the color of the icon
 */
enum VehicleMarkerState {
  OutOfService = "outOfService",
  Available = "available",
}

/**
 * Marker indicating a generic vehicle on the map
 * @param props
 * @returns
 */
export default function GenericVehicleMarker({vehicle} : Readonly<Props>) {

  const state =
    vehicle.status?.tActStatusCodeExtBrw == 6 ||
    vehicle.status?.tActStatusCodeExtBrw == -1 ||
    vehicle.status?.tActStatusCodeExtBrw == null ? VehicleMarkerState.OutOfService : VehicleMarkerState.Available;

  return (
    <div className={`vehicle-marker ${state}`} >
      <div className={"icon"}>
        <img src="/assets/images/icons/icon_brw_status_called.svg" alt="vehicle" />
      </div>
      <div className={"info"}>
        <VehicleStatus statusCodeAfkr={vehicle?.status?.tActStatusAfkBrw ?? ""} statusCode={vehicle?.status?.tActStatusCodeExtBrw} showLabel={false} />
        {vehicle.roepnaamEenheid}
        <span className={"codeVoertuigSoort"}>({vehicle.status?.codeVoertuigSoort})</span>
      </div>
    </div>
  );
}
