import mapboxgl, { GeoJSONSource, MapboxGeoJSONFeature, PointLike } from "mapbox-gl";
import { logger } from "../services/logger";
import { FeatureCollection } from "geojson";
import { MapIcon } from "../models/mapIcon";

/**
 * Utility class for Mapbox
 * @remarks contains common functions for querying features
 */
export class MapboxUtil {

  /**
   * Add a layer to the map
   * @param map
   * @param layers
   */
  static addLayersToMap(map: mapboxgl.Map, layers: [mapboxgl.AnyLayer, string | undefined][]) {
    try {
      layers.forEach((x) => {
        if (map.getLayer(x[0].id))
          return;

        map.addLayer(x[0], x[1]);
      });
    } catch (e: unknown) {
      logger.error("Error occurred while adding layer to map", e);
    }
  }
  /**
   * Add a GeoJSON source to the map
   * @param sourceId
   * @param data
   * @param map
   */
  static addGeoJSONSourceToMap(sourceId: string, data: FeatureCollection | string | undefined, map: mapboxgl.Map) {
    try {
      if (map.getSource(sourceId))
        return;

      map.addSource(sourceId, {
        type: "geojson",
        generateId: true,
        data: data ?? { type: "FeatureCollection", features: [] }
      });
    } catch (e: unknown) {
      logger.error("Error occurred while adding GeoJSON source to map", e);
    }
  }

  /**
   * Refresh a GeoJSON source on the map
   * @param sourceId
   * @param data
   * @param map
   */
  static refreshGeoJSONSource(sourceId: string, data: FeatureCollection | string | undefined, map: mapboxgl.Map) {
    try {
      if (!map.getSource(sourceId))
        return;

      const source = map.getSource(sourceId) as GeoJSONSource;
      source.setData(data ?? { type: "FeatureCollection", features: [] });
    } catch (e: unknown) {
      logger.error("Error occurred while refreshing GeoJSON source to map", e);
    }
  }

  /**
   * Add a WMS source to the map
   * @param sourceId
   * @param tileUrls
   * @param map
   */
  static addWMSSourceToMap(sourceId: string, tileUrls: string[], map: mapboxgl.Map) {
    try {
      if (map.getSource(sourceId))
        return;

      map.addSource(sourceId, {
        type: "raster",
        tiles: tileUrls,
        tileSize: 256,
      });
    } catch (e: unknown) {
      logger.error("Error occurred while adding WMS source to map", e);
    }
  }

  /**
   *  Add a source to the map
   * @param sourceId  Source id
   * @param source  Source data
   * @param map  Mapbox instance
   * @returns void
   */
  static addSource(sourceId: string, source: mapboxgl.AnySourceData, map: mapboxgl.Map) {
    try {
      if (map.getSource(sourceId))
        return;

      map.addSource(sourceId, source);
    } catch (e: unknown) {
      logger.error("Error occurred while adding source to map", e);
    }
  }

  /**
   * Update a GeoJSON source on the map
   * @param sourceId Source id
   * @param data Data to update the source with
   * @param map Mapbox instance
   */
  static updateGeoJSONSource(sourceId: string, data: FeatureCollection | undefined, map: mapboxgl.Map) {
    try {
      if (!map.getSource(sourceId))
        return;

      const source = map.getSource(sourceId) as GeoJSONSource;
      source.setData(data ?? { type: "FeatureCollection", features: [] });
    } catch (e: unknown) {
      logger.error("Error occurred while updating GeoJSON source to map", e);
    }
  }

  /**
   * Add images to map
   * @param assets
   * @param map
   * @param callback
   */
  static addImagesToMap(assets: MapIcon[], map: mapboxgl.Map, callback?: (map: mapboxgl.Map) => void) {
    try {
      assets.forEach((x) => {
        if (map.hasImage(x.name))
          return;

        map.loadImage(x.path, (error, image) => {
          if (error)
            throw error;

          if (!image)
            throw new Error("Image is undefined");

          map.addImage(x.name, image);
        });

        if (callback)
          callback(map);
      });
    } catch (e: unknown) {
      logger.error("Error occurred while adding images to map", e);
    }
  }

  /**
   * Remove images from map
   * @param map
   * @param imageIds
   */
  static removeImagesFromMap(map: mapboxgl.Map, imageIds: string[]) {
    try {
      imageIds.forEach((x) => {
        if (map.hasImage(x))
          map.removeImage(x);
      });
    } catch (e: unknown) {
      logger.error("Error occurred while removing images from map", e);
    }
  }

  /**
   * Remove layers from map
   * @param map
   * @param layerIds
   * @constructor
   */
  static removeLayersFromMap(map: mapboxgl.Map, layerIds: string[]) {
    try {
      layerIds.forEach((x) => {
        if (map.getLayer(x))
          map.removeLayer(x);
      });
    } catch (e: unknown) {
      logger.error("Error occurred while removing layers from map", e);
    }
  }

  /**
   * Remove sources from map
   */
  static removeSourcesFromMap(map: mapboxgl.Map, sourceIds: string[]) {
    try {
      sourceIds.forEach((x) => {
        if (map.getSource(x))
          map.removeSource(x);
      });
    } catch (e: unknown) {
      logger.error("Error occurred while removing sources from map", e);
    }
  }

  /**
   * Query rendered features on  the map
   * @param x Point x
   * @param y Point y
   * @param radius Radius for the bounding box
   * @param layerIds Layer to query
   * @param map Mapbox instance
   */
  static async queryRenderedFeatures(x: number, y: number, radius: number, layerIds: string[], map: mapboxgl.Map): Promise<MapboxGeoJSONFeature[]> {
    try {
      const boundingBox: [PointLike, PointLike] = [
        [x - radius, y - radius],
        [x + radius, y + radius]];

      return map.queryRenderedFeatures(boundingBox, { layers: layerIds });
    } catch (e: unknown) {
      logger.error("Error occurred while querying rendered features by property", e);
      return [];
    }
  }

  /**
   * Fetch features from the map based on a value of a property
   * @param propertyName Property name to filter on
   * @param value Value of the property
   * @param sourceId Source of the feature
   * @param layerId Related layer
   * @param map Mapbox instance
   * @remarks Only works with GeoJSON sources
   */
  static async querySourceFeaturesByProperty(propertyName: string, value: string, sourceId: string, layerId: string, map: mapboxgl.Map): Promise<MapboxGeoJSONFeature[]> {
    try {
      const features = map.querySourceFeatures(sourceId, { sourceLayer: layerId });
      return features.filter((x) => x.properties?.[propertyName] == value);
    } catch (e: unknown) {
      logger.error("Error occurred while querying source features by property", e);
      return [];
    }
  }
}
