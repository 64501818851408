import useGeoJson from "../../hooks/useGeoJson";
import { AnyLayer } from "mapbox-gl";
import { DetermineApiUri } from "../../../config";
import useSWR from "swr";
import { FeatureCollection } from "geojson";

/**
 * Name used for the digitale verkenning layer
 */
export const DigitaleVerkenningLayerName = "internal_digitale_verkenning";

/**
 *  Layer for the digitale verkenning
 */
const layerDefinitions: AnyLayer[] = [
  {
    id: `${DigitaleVerkenningLayerName}-line`,
    type: "line",
    source: `${DigitaleVerkenningLayerName}-source`,
    layout: {
      "line-join": "round",
      "line-cap": "round",
      "visibility": "visible"
    },
    paint: {
      "line-color": ["get", "color", ["get", "style", ["properties"]]],
      "line-opacity": 1,
      "line-width": 5,
    }
  },
  {
    id: `${DigitaleVerkenningLayerName}-circle`,
    type: "circle",
    source: `${DigitaleVerkenningLayerName}-source`,
    paint: {
      "circle-radius": 7,
      "circle-color": ["get", "color", ["get", "style", ["properties"]]],
    }
  },
  {
    id: `${DigitaleVerkenningLayerName}-polygon`,
    type: "fill",
    source: `${DigitaleVerkenningLayerName}-source`,
    paint: {
      "fill-color": ["get", "color", ["get", "style", ["properties"]]],
    }
  },
  {
    id: `${DigitaleVerkenningLayerName}-labels`,
    type: "symbol",
    source: `${DigitaleVerkenningLayerName}-source`,
    layout: {
      "text-field": ["get", "tooltip", ["properties"]],
      "text-variable-anchor": ["top"],
      "text-radial-offset": 0.5,
      "text-justify": "auto"
    }
  }
];

/**
 * Layer for the digitale verkenning
 * @constructor
 */
export function DigitaleVerkenningLayer() {
  const { data } = useSWR<FeatureCollection>(`${DetermineApiUri()}/api/Geo/layer/digitale_verkenning`, {
    refreshInterval: 5000
  });

  useGeoJson(DigitaleVerkenningLayerName, data, layerDefinitions);
  return (<></>);
}
