import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis Barries layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/3301}
 */
export const BarriesDefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.barries,
  layers: [
    {
      layerId: 3301,
      layerDefinitions: [
        {
          "id": `${LayerLabels.barries}-barrier`,
          "type": "fill",
          "filter": ["==", ["get", "LAAGNAAM"], "Barrier"],
          "paint": {
            "fill-color": "rgba(28,76,219,0.8)", // Converted from [28,76,219,204]
            "fill-outline-color": "rgba(130,130,130,1)" // Converted from [130,130,130,255]
          }
        },
        {
          "id": `${LayerLabels.barries}-barrier`,
          "type": "fill",
          "filter": ["==", ["get", "LAAGNAAM"], "Mobiele Barrier"],
          "paint": {
            "fill-color": "rgba(46,138,0,1)", // Converted from [46,138,0,255]
            "fill-outline-color": "rgba(110,110,110,1)" // Converted from [110,110,110,255]
          }
        }
      ]
    },
  ],
  isToggledByKermisInformatieLayer: true
};