import useSWR from "swr";
import {Vehicle} from "app/models/vehicleLocation";
import {DetermineApiUri} from "config";
import {useEffect, useState} from "react";
import {useResourceFetch} from "./useResourceFetch";

export type VehicleFilters = "None" | "Called";

type UseMachinesProp = {
  /**
   * The filter to apply to the vehicles being fetched
   * @default undefined
   * @remarks If this is undefined, no filter will be applied
   */
  filter: VehicleFilters | undefined,

  /**
   * Whether to keep refreshing the vehicles
   * @default false
   * @remarks If this is true, the vehicles will be refreshed every 5 seconds
   */
  keepRefreshing: boolean,
}

type UseMachineProps = {
  /**
   * Retrieve explicit asset by their asset number
   * @example "20-1234"
   * @default undefined
   * @remarks If this is undefined, all assets will be retrieved
   */
  assetNumber: string
}

/**
 * Hook for fetching vehicles from the OIV backend
 */
export const useMachines = ({filter, keepRefreshing}: Readonly<UseMachinesProp>) => {
  const {
    data: machines,
    error,
    mutate
  } = useSWR<Vehicle[]>(keepRefreshing ? `${DetermineApiUri()}/api/machine?collectionFilter=${filter}` : null, {refreshInterval: 5000});

  // Clear data cache when the filter changes
  useEffect(() => {
    mutate();
  }, [filter]);

  const deployableMachines = machines?.filter(machine => !machine.roepnaamEenheid.startsWith("50-") && !machine.roepnaamEenheid.startsWith("60-"));

  return {
    machines: deployableMachines,
    mutate,
    error
  };
};

/**
 * Hook for fetching a single vehicle from the OIV backend
 * @param assetNumber
 */
export const useMachine = ({assetNumber}: Readonly<UseMachineProps>) => {
  const protectedFetch = useResourceFetch();
  const [machine, setMachine] = useState<Vehicle>();

  useEffect(() => {
    if(assetNumber.length === 0)
      return;

    const intervalId = setInterval(() => {
      fetchMachine();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if(assetNumber.length === 0)
      return;

    fetchMachine();
  }, [assetNumber]);

  function fetchMachine() {
    protectedFetch(`${DetermineApiUri()}/api/machine/${assetNumber}`).then(response => {
      response.json().then(data => {
        setMachine(data);
      });
    }).catch(error => {
      console.error(error);
    });
  }

  return machine;
};
