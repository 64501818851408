import { faSquare, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type CheckboxProps = {
  /**
   * Checkbox checked state
   */
  checked: boolean,

  /**
   *  Function to be called when the checkbox is clicked
   * @param checked 
   * @returns 
   */
  onChange: (checked: boolean) => void

  /**
   * Additional class name
   */
  className?: string

  /**
   * Checkbox variant
   */
  variant: "default" | "contained" | "outlined"

  /**
   * Checkbox label
   */
  label: string
}

/**
 * Checkbox component
 * @returns 
 */
export function Checkbox({ checked, onChange, className, variant, label }: Readonly<CheckboxProps>) {
  let variantClassName = "";
  switch (variant) {
    case "default":
      variantClassName = style.default;
      break;
    case "contained":
      variantClassName = style.contained;
      break;
    case "outlined":
      variantClassName = style.outlined;
      break;
  }

  const icon = checked ? faSquareCheck : faSquare;

  return (
    <div role="button"
      className={`${style.checkbox} ${className} ${checked ? style.checked : style.unchecked} ${variantClassName}`}
      onKeyDown={() => onChange(!checked)}
      onClick={() => onChange(!checked)}>
      <FontAwesomeIcon icon={icon} />
      <label>{label}</label>
    </div>
  );
}