import { useEffect, useRef, useState } from "react";

type Props = {
  timeoutMs: number
  onIdle?: () => void
}

const useIdle = ({timeoutMs, onIdle} : Props) => {
  const [isIdle, setIsIdle] = useState(false);
  const timerRef = useRef<number | null>(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      setIsIdle(true);

      if(onIdle)
        onIdle();
    }, timeoutMs);
  };

  const handleActivity = () => {
    setIsIdle(false);
    resetTimer();
  };

  useEffect(() => {
    resetTimer();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("mousedown", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("touchmove", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("touchmove", handleActivity);
    };
  }, [timeoutMs, onIdle]);

  return isIdle;
};

export default useIdle;