import proj4 from "proj4";

export class ProjectionHelper {
  // Function to convert WGS84 to RD_NEW
  static convertWGStoRD(latitude: number, longitude: number): [number, number] {

    // Define the two projections
    const WGS84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
    const RD_NEW = "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.4171,50.3319,465.5524,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs";

    // Initialize proj4 with the two projections
    proj4.defs("WGS84", WGS84);
    proj4.defs("RD_NEW", RD_NEW);

    // Use proj4 to transform the coordinates
    const [x, y] = proj4("WGS84", "RD_NEW", [longitude, latitude]);

    return [x, y];
  }
}