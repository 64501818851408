import { SharedMapConfigurationContext, useMap } from "app/contexts/mapContext";
import { VisualMode } from "app/models/mapConfiguration";
import { useContext } from "react";

const nightModeLayerStyle = "mapbox://styles/mapbox/dark-v11";
const defaultStyle = "mapbox://styles/mapbox/streets-v12";

export function NightModeLayer() {
  const { sharedMapConfiguration } = useContext(SharedMapConfigurationContext);

  useMap(map => {
    if (sharedMapConfiguration.visualMode != VisualMode.Dark && map.getStyle().name == "Mapbox Dark") {
      if (map.getStyle().name == "Mapbox Streets")
        return;

      map.setStyle(defaultStyle);
    } else if (sharedMapConfiguration.visualMode == VisualMode.Dark && map.getStyle().name != "Mapbox Dark") {
      map.setStyle(nightModeLayerStyle);
    }
  }, [sharedMapConfiguration.visualMode]);

  return <></>;
}