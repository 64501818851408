import * as React from "react";
import { SVGProps } from "react";

const SvgHazeDay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="haze-day_svg__b" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
      <symbol id="haze-day_svg__c" viewBox="0 0 258 66">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M9 57h30"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeDasharray="60 60"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M99 57h90"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M219 57h30M9 9h30"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeDasharray="60 60"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M99 9h90"
        />
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M219 9h30"
        />
      </symbol>
      <clipPath id="haze-day_svg__a">
        <path fill="none" d="M0 0h512v306H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#haze-day_svg__a)">
      <use
        xlinkHref="#haze-day_svg__b"
        width={375}
        height={375}
        transform="translate(68.5 104.5)"
      />
    </g>
    <use
      xlinkHref="#haze-day_svg__c"
      width={258}
      height={66}
      transform="translate(127 339)"
    />
  </svg>
);

export default SvgHazeDay;
