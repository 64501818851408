import React, { useState, useEffect, useRef } from "react";
import "./style.scss";
import useIdle from "./hooks/useIdle";

/***
 * Screensaver component
 * @constructor
 * @remarks Renders an overlay every 15 minutes of inactivity
 */
const Screensaver = () => {
  const showScreensaver = useIdle({ timeoutMs: 15 * 60 * 1000 });
  const containerRef = useRef<HTMLDivElement | null>(null);

  if (!showScreensaver)
    return <></>;

  return (
    <div className="screensaver-container" ref={containerRef}>
      <ImageMover containerWidth={window.innerWidth - 100} containerHeight={window.innerHeight - 100} src={"assets/images/vrmwb-logo.png"} />
    </div>
  );
};

type ImageMoverProps = {
  containerWidth: number | undefined;
  containerHeight: number | undefined;
  src: string;
}

/**
 * Image that moves randomly within the parent container
 * @param containerWidth width of the parent container
 * @param containerHeight height of the parent container
 * @param src image source
 * @constructor
 */
const ImageMover = ({ containerWidth, containerHeight, src }: ImageMoverProps) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const imageRef = useRef<HTMLImageElement | null>(null);

  if (!containerWidth || !containerHeight)
    return <></>;

  /**
   * Get a random position within the parent container
   */
  const getRandomPosition = () => {
    const parentWidth = containerWidth;
    const parentHeight = containerHeight;
    const imageWidth = imageRef.current?.width ?? 0;
    const imageHeight = imageRef.current?.height ?? 0;

    const maxLeft = parentWidth - imageWidth;
    const maxTop = parentHeight - imageHeight;

    const newLeft = Math.random() * maxLeft;
    const newTop = Math.random() * maxTop;

    return { left: newLeft, top: newTop };
  };

  /**
   * Move the image to a random position
   */
  const moveImage = () => {
    const newPosition = getRandomPosition();
    setPosition(newPosition);
  };

  /**
   * Move the image every 5 seconds
   */
  useEffect(() => {
    const intervalId = setInterval(moveImage, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <img
      ref={imageRef}
      src={src}
      alt="Moving Image"
      className="moving-image"
      style={{
        position: "absolute",
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
      onClick={moveImage}
    />
  );
};

export const MemoizedScreensaver = React.memo(Screensaver);