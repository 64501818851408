import { MapContext, SharedMapConfigurationContext } from "app/contexts/mapContext";
import { UserContext } from "app/contexts/userContext";
import { useContext, useEffect, useState } from "react";
import { LayerLabels } from "./Helpers/LayerLabels";
import { BarriesDefinition } from "./LayerDefinitions/BarriesDefinition";
import { BomenGroenbakkenDefinition } from "./LayerDefinitions/BomenGroenbakkenDefinition";
import { CalamiteitenRouteDefinition } from "./LayerDefinitions/CalamiteitenRouteDefinition";
import { CopiDefinition } from "./LayerDefinitions/CopiDefinition";
import { EVADefinition } from "./LayerDefinitions/EVADefinition";
import { GasflesDefinition } from "./LayerDefinitions/GasflesDefinition";
import { GridInformatieDefinition } from "./LayerDefinitions/GridInformatieDefinition";
import { AttractiesDefinition } from "./LayerDefinitions/AttractiesDefinition";
import { SectorenInformatieDefinition } from "./LayerDefinitions/SectorenInformatieDefinition";
import { TerrasDefinition } from "./LayerDefinitions/TerrasDefinition";
import { ArcGISFeatureServerLayerDefinition } from "./Models/ArcGISExportLayer";
import { ArcGISFeatureServerLayer } from "./Components/ArcGISFeatureServerLayer";
import { ConfigurationHelper } from "./Helpers/ConfigurationHelper";

export function TilburgseKermisLayerManager() {
  const { userSettingsDispatch } = useContext(UserContext);
  const [isRootLayerEnabled, setIsRootLayerEnabled] = useState(false);
  const { sharedMapConfiguration, setSharedMapConfiguration } = useContext(SharedMapConfigurationContext);
  const sharedMapContext = useContext(MapContext);
  const kermisAttractiesLayerDefinitions = [
    AttractiesDefinition
  ];

  const kermisInformationLayerDefinitions = [
    BarriesDefinition,
    BomenGroenbakkenDefinition,
    CalamiteitenRouteDefinition,
    CopiDefinition,
    EVADefinition,
    GasflesDefinition,
    GridInformatieDefinition,
    SectorenInformatieDefinition,
    TerrasDefinition
  ];

  /**
   *  Toggle multiple layers that are toggled with the main "kermisinformatie" layer
   * @param isEnabled
   * @returns
   */
  function toggleKermisInformatieLayer(isEnabled: boolean) {
    const payloads: Record<string, boolean>[] = [...kermisInformationLayerDefinitions, ...kermisAttractiesLayerDefinitions]
      .filter(x => x.isToggledByKermisInformatieLayer)
      .map(x => ({ [x.layerId]: isEnabled }));

    payloads.forEach(payload => {
      setSharedMapConfiguration(old => {
        return { ...old, externalLayers: { ...old.externalLayers, ...payload } };
      });
      userSettingsDispatch({ type: "UPDATE_LAYERS", payload });
    });

    setIsRootLayerEnabled(isEnabled);
  }

  /**
    * Display only the layers that are enabled in the sharedMapConfiguration
    * @param possibleLayers The layers that are possible to display
    * @returns  The layers that are enabled in the sharedMapConfiguration
    */
  function filterActiveLayers(possibleLayers: ArcGISFeatureServerLayerDefinition[]) {
    return possibleLayers.filter(layer => {
      return sharedMapConfiguration.externalLayers[layer.layerId];
    });
  }

  /**
   * Determine the layer the given layer should be rendered before
   * @param layerId Layer that is going to be rendered
   */
  function determineRenderBefore(layerToRender: ArcGISFeatureServerLayerDefinition) {
    if (!sharedMapContext)
      throw new Error("Map context is not available");

    let renderBeforeLayer = sharedMapContext.getStyle().name == "Mapbox Dark" ? "road-label-simple" : "road-label";

    // Check if any kermis layers are already rendered on the map.
    const renderedTilburgKermisLayers = sharedMapContext.getStyle().layers.filter(x => x.id.includes(LayerLabels.prefix));

    if (renderedTilburgKermisLayers.length > 0) {

      // Check if there are layers that should be rendered before the current layer
      const layersBefore = [...kermisAttractiesLayerDefinitions, ...kermisInformationLayerDefinitions].filter(x => Math.max(...x.layers.map(y => y.layerId)) > Math.max(...layerToRender.layers.map(y => y.layerId)));
      if (renderedTilburgKermisLayers.filter(x => layersBefore.some(y => x.id.includes(y.layerId))).length > 0) {
        renderBeforeLayer = renderedTilburgKermisLayers.filter(x => layersBefore.some(y => x.id.includes(y.layerId)))[0].id;
      }
    }

    return renderBeforeLayer;
  }
  /**
   * Whenever the main "kermis_layer" is toggled, we want to toggle multiple layers
   */
  useEffect(() => {
    const enabledRootLayer = sharedMapConfiguration.externalLayers[LayerLabels.kermisInformatie];

    if (enabledRootLayer === isRootLayerEnabled)
      return;

    toggleKermisInformatieLayer(enabledRootLayer);
  }, [sharedMapConfiguration.externalLayers]);

  return (
    <>
      {
        filterActiveLayers(kermisAttractiesLayerDefinitions).map((layerDefinition) => {
          return layerDefinition.layers.map((layer) =>
            <ArcGISFeatureServerLayer
              key={`${layerDefinition.layerId}-${layer.layerId}`}
              apiKey={ConfigurationHelper.apiKey}
              sourceId={`${layerDefinition.layerId}-${layer.layerId}-source`}
              url={`${ConfigurationHelper.kermis_attracties_extern_rp}/${layer.layerId}`}
              layerDefinitions={layer.layerDefinitions}
              icons={layer.icons}
              renderBefore={determineRenderBefore(layerDefinition)}
            />);
        })
      }
      {filterActiveLayers(kermisInformationLayerDefinitions).map((layerDefinition) => {
        return layerDefinition.layers.map((layer) =>
          <ArcGISFeatureServerLayer
            key={`${layerDefinition.layerId}-${layer.layerId}`}
            apiKey={ConfigurationHelper.apiKey}
            sourceId={`${layerDefinition.layerId}-${layer.layerId}-source`}
            url={`${ConfigurationHelper.kermisKermisInformatie_RP}/${layer.layerId}`}
            layerDefinitions={layer.layerDefinitions}
            icons={layer.icons}
            renderBefore={determineRenderBefore(layerDefinition)}
          />);
      })}
    </>
  );

}