import SemiCircleProgress from "app/components/SemiCircleProgress";

interface Props {
  watertankNiveau?: number;
  schuimtankNiveau?: number;
  brandstoftankNiveau?: number;
  adblueChassisNiveau?: number;
  adblueOpbouwNiveau?: number;
}


/**
 * Component for telemetry tanks information
 * @component
 */
export function TelemetryTanks({ watertankNiveau, schuimtankNiveau, brandstoftankNiveau, adblueChassisNiveau, adblueOpbouwNiveau }: Readonly<Props>) {
  if (!watertankNiveau
    && !schuimtankNiveau
    && !brandstoftankNiveau
    && !adblueChassisNiveau
    && !adblueOpbouwNiveau)
    return <></>;

  return (
    <div className="telemetry-tanks">
      <Tank name={"Watertank"} value={watertankNiveau} color={"#0E518D"} />
      <Tank name={"Schuimtank"} value={schuimtankNiveau} color={"#3A98EE"} />
      <Tank name={"Brandstof"} value={brandstoftankNiveau} color={"#E6AC27"} />
      <Tank name={"Adblue chassis"} value={adblueChassisNiveau} color={"#76bcf5"} />
      <Tank name={"Adblue opbouw"} value={adblueOpbouwNiveau} color={"#76bcf5"} />
    </div>
  );
}

/**
 * Component for displaying tank telemetry information
 * @param name
 * @param value
 * @param color
 * @constructor
 */
function Tank({ name, value, color }: Readonly<{ name: string, value: number | undefined, color: string }>) {
  if (!value)
    return <></>;

  return (
    <div className={"column"}>
      <div className={"header"}>{name}</div>
      <SemiCircleProgress
        diameter={70}
        stroke={color}
        showPercentValue={true}
        percentage={value}
        strokeWidth={10}
        background={"#D0EAE8"}
        orientation={"up"}
        direction={"right"} />
    </div>
  );
}