import { useEffect, useReducer, createContext, useContext, Dispatch } from "react";
import useSWR from "swr";
import { userSettingsContextReducer } from "app/stores/reducers/userSettingsReducer";
import { UserSettings, initialUserSettingsContext } from "app/models/userSettings";
import { SharedMapConfigurationContext } from "./mapContext";
import { DetermineApiUri } from "../../config";

export const UserContext = createContext<UserSettingsCompound>({} as UserSettingsCompound);

type Props = {
  children?: JSX.Element;
};

export type ActionType
  = { type: "SET_SETTINGS", payload: UserSettings }
  | { type: "UPDATE_LAYERS", payload: Record<string, boolean> };

export type UserSettingsContext = {
  userSettings: UserSettings;
  selectedLayers: Record<string, boolean>;
};

export type UserSettingsCompound = UserSettingsContext & {
  userSettingsDispatch: Dispatch<ActionType>;
};

export function UserProvider({ children }: Props) {
  const [userSettingsContext, userSettingsDispatch] = useReducer(userSettingsContextReducer, initialUserSettingsContext);
  const { data: userSettingsPayload } = useSWR<UserSettings>(`${DetermineApiUri()}/api/settings`);
  const { setSharedMapConfiguration } = useContext(SharedMapConfigurationContext);

  useEffect(() => {
    if (!userSettingsPayload)
      return;

    if (!userSettingsPayload.selectedLayers)
      userSettingsPayload.selectedLayers = [];

    const layers = userSettingsPayload.selectedLayers.reduce((layers, layerName) => {
      return {
        ...layers,
        [layerName]: true
      };
    }, {} as Record<string, boolean>);
    setSharedMapConfiguration(old => {
      return { ...old, externalLayers: { ...old.externalLayers, ...layers } };
    });
    userSettingsDispatch({ type: "UPDATE_LAYERS", payload: layers });
  }, [userSettingsPayload]);

  return (
    <UserContext.Provider value={{ ...userSettingsContext, userSettingsDispatch }}>
      {children}
    </UserContext.Provider>
  );
}
