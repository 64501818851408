import {ChangeEvent, MutableRefObject, useEffect, useState} from "react";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import {FindInPage, FireTruck} from "@mui/icons-material";
import "./style.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import useSearch from "app/hooks/useSearch";
import { SearchResult } from "app/providers/interfaces/searchProvider";
import PermissionsGate, { POLICY } from "../PermissionsGate";
import EventDispatcher, {CustomEvents} from "../../../eventDispatcher";
import { VehicleMarker } from "app/models/vehicleMarker";
export type SearchMode = "places" | "boi" | "vehicles";

type Props = {
  vehicleMarkers: MutableRefObject<VehicleMarker[]>
}

/**
 * Search component
 * @constructor
 */
export function SearchComponent({ vehicleMarkers }: Readonly<Props>){
  const [mode, setMode] = useState<SearchMode>("places");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [enabled, setEnabled] = useState<boolean>(false);
  const [modeSelectEnabled, setModeSelectEnabled] = useState<boolean>(false);
  const {results, onResultTapped, onClose, placeholder} = useSearch({searchMode: mode, searchQuery: searchQuery, vehicleMarkers: vehicleMarkers});

  useEffect(() => {
    if (!enabled) {
      setSearchQuery("");
      onClose();
    }
  }, [enabled]);

  EventDispatcher.on(CustomEvents.CloseAllTools, () => {
    setEnabled(false);
  });

  /**
   * Triggers when the search query changes
   * @param e
   */
  function handleSearchQueryChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.target.value);
  }

  /**
   *  Triggers when the mode changes
   * @param mode
   */
  function handleSetMode(mode: SearchMode, e?: React.KeyboardEvent<HTMLButtonElement>) {
    if (!e || e && e.key === "Enter") {
      setMode(mode);
      setModeSelectEnabled(false);
    }
  }

  /**
   *  Triggers when the mode selection is toggled
   * @param e
   */
  function handleModeSelectionEnabled(e?: React.KeyboardEvent<HTMLButtonElement>) {
    if (!e || e && e.key === "Enter") {
      setModeSelectEnabled(!modeSelectEnabled);
    }
  }

  /**
   *  Triggers when the search is enabled/disabled
   * @param enable
   * @param e
   */
  function handleEnableSearch(enable: boolean, e?: React.KeyboardEvent<HTMLButtonElement>) {
    if (!e || e && e.key === "Enter") {
      setEnabled(enable);
    }
  }

  /**
   *  Triggers when a search result is tapped
   * @param result
   * @param e
   */
  function handleResultTapped(result: SearchResult, e?: React.KeyboardEvent<HTMLButtonElement>) {
    if (!e || e && e.key === "Enter") {
      onResultTapped(result);
    }
  }

  if(!enabled){
    return(
      <div className="search">
        <div className="search-mode">
          <button className="current" onKeyDown={(x) => handleEnableSearch(true, x)} onClick={() => setEnabled(true)}>
            <div className={"current-icon"} >
              {mode === "places" && <TravelExploreIcon className={"lens"} />}
              {mode === "boi" && <FindInPage className={"lens"} />}
              {mode === "vehicles" && <FireTruck className={"lens"} />}
            </div>
          </button>
        </div>
      </div>
    );
  }

  return(
    <div className="search enabled">
      <div className="search-mode">
        <div className={modeSelectEnabled ? "current selection-opened" : "current"}>
          <button className="current-icon" onKeyDown={(x) => handleModeSelectionEnabled(x)} onClick={() => handleModeSelectionEnabled()}>
            {mode === "places" && <TravelExploreIcon className={"lens"} />}
            {mode === "boi" && <FindInPage className={"lens"} />}
            {mode === "vehicles" && <span className="icon-padding"><FireTruck className={"lens"} /></span>}
            {!modeSelectEnabled && <ExpandMoreIcon className="expand" />}
            {modeSelectEnabled && <ExpandLessIcon />}
          </button>
          <div className={modeSelectEnabled ? "mode-selection" : "mode-selection hidden"}>
            <button className="mode-selection-item" onKeyDown={(x) => handleSetMode("places", x)} onClick={() => handleSetMode("places")}>
              <TravelExploreIcon className={"lens"} />
              <div className="label">Kaart</div>
            </button>
            <PermissionsGate policy={POLICY.searchBOI}>
              <button className="mode-selection-item" onKeyDown={(x) => handleSetMode("boi", x)} onClick={() => handleSetMode("boi")}>
                <FindInPage className={"lens"} />
                <div className="label">Planvorming</div>
              </button>
            </PermissionsGate>
            <button className="mode-selection-item" onKeyDown={(x) => handleSetMode("vehicles", x)} onClick={() => handleSetMode("vehicles")}>
              <FireTruck className={"lens"} />
              <div className="label">Voertuig</div>
            </button>
          </div>
        </div>
      </div>
      <div className="search-input">
        <input type="text" value={searchQuery} onChange={handleSearchQueryChange} placeholder={placeholder()}/>
      </div>
      <div className="search-close">
        <button className="search-close-icon" onKeyDown={(x) => handleEnableSearch(false, x)} onClick={() => handleEnableSearch(false)}>
          <CloseIcon/>
        </button>
      </div>
      <div className={results.length > 0 ? "search-results" : "search-results hidden"}>
        {results?.map((x) =>
          <button key={x.id} onKeyDown={(e) => handleResultTapped(x,e)} onClick={() => handleResultTapped(x)} className={"search-result"}>
            {x.label}
          </button>
        )}
      </div>
    </div>
  );
}
