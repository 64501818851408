/**
 * Enriches the text with html elements
 * @param text
 */
export function useEnrichText(text: string){
  function addAnchorTags(text: string): (string | JSX.Element)[]{
    const result: (string | JSX.Element)[] = [];
    const pattern = /\bhttps?:\/\/\S+/;
    const words = text.toString().split(/(\s+)/);

    let linkIndex = 0;

    words.forEach((word, index) => {
      if (pattern.test(word)){
        result.push(<a key={linkIndex} href={word}>{word}</a>);
        linkIndex++;
        return;
      }

      if (index > 0) {
        const prev = result[result.length - 1];
        if (typeof prev === "string") {
          result[result.length - 1] = prev + word;
          return;
        }
      }

      result.push(word);
    });

    return result;
  }

  return addAnchorTags(text);
}