import { MapContext, SharedMapConfigurationContext } from "app/contexts/mapContext";
import BOIProvider from "app/providers/boiProvider";
import { SearchMode, SearchProvider, SearchResult } from "app/providers/interfaces/searchProvider";
import MapboxProvider from "app/providers/mapboxProvider";
import { MutableRefObject, useContext, useEffect, useState } from "react";
import { useResourceFetch } from "./useResourceFetch";
import { VehicleMarker } from "app/models/vehicleMarker";
import VehicleProvider from "app/providers/vehicleProvider";
import { UserContext } from "app/contexts/userContext";

type Props = {
  searchMode: SearchMode,
  searchQuery: string,

  vehicleMarkers: MutableRefObject<VehicleMarker[]>
}

/**
 * Custom hook for searching items
 */
const useSearch = ({ searchMode, searchQuery, vehicleMarkers }: Props) => {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);
  const { sharedMapConfiguration, setSharedMapConfiguration } = useContext(SharedMapConfigurationContext);
  const { userSettingsDispatch } = useContext(UserContext);


  const map = useContext(MapContext);
  const protectedFetch = useResourceFetch();
  const [providers, setProviders] = useState<SearchProvider<SearchResult>[]>([
    new MapboxProvider(map),
    new BOIProvider(protectedFetch),
    new VehicleProvider(vehicleMarkers)
  ]);

  /**
   * Update the providers when the map changes
   */
  useEffect(() => {
    setProviders([
      new MapboxProvider(map),
      new BOIProvider(protectedFetch),
      new VehicleProvider(vehicleMarkers)
    ]);
  }, [map]);

  const [activeProvider, setActiveProvider] = useState<SearchProvider<SearchResult> | undefined>(providers.find((x) => x.mode === searchMode));

  /**
   * Triggers when the search mode changes
   */
  useEffect(() => {
    setActiveProvider(providers.find((x) => x.mode === searchMode));
  }, [searchMode, providers]);

  /**
   * Triggers when the search query changes
   */
  useEffect(() => {
    try {
      setLoading(true);

      if (!activeProvider)
        return;

      if (activeProvider.mode === "vehicles")
        enableVehicleLayer();

      activeProvider.search(searchQuery).then((results) => {
        setResults(results);
      });

      setLoading(false);
    } catch (error: unknown) {
      setError(error);
      setLoading(false);
    }
  }, [searchQuery, activeProvider]);

  /**
   *  Triggers when a search result is tapped
   * @param result
   */
  function onResultTapped(result: SearchResult): void {
    if (!map)
      return;

    activeProvider?.onResultTapped(result);
  }


  /**
   *  Toggle multiple layers that are toggled with the main "kermisinformatie" layer
   * @param isEnabled
   * @returns
   */
  function enableVehicleLayer() {
    // Check if the layer is already enabled
    if (("oiv_internal_vehicles" in sharedMapConfiguration.externalLayers && sharedMapConfiguration.externalLayers["oiv_internal_vehicles"])) {
      return;
    }

    const payload: Record<string, boolean> = {
      "oiv_internal_vehicles": true,
      "oiv_internal_active_vehicles": false
    };

    setSharedMapConfiguration(old => {
      return { ...old, externalLayers: { ...old.externalLayers, ...payload } };
    });
    userSettingsDispatch({ type: "UPDATE_LAYERS", payload });
  }

  /**
   *  Triggers when the search is closed
   */
  function onClose(): void {
    if (!map)
      return;

    activeProvider?.onCancel();
  }

  /**
   * Get the placeholder text for the search input
   */
  function placeholder(): string {
    switch (searchMode) {
      case "places":
        return "Zoeken in adressen/plaatsen";
      case "boi":
        return "Zoeken in BOI/planvorming";
      case "vehicles":
        return "Zoeken in voertuigen";
      default:
        return "Zoeken";
    }
  }

  return { results, error, placeholder, loading, searchMode, onResultTapped, onClose };
};

export default useSearch;