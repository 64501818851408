import { MapControls } from "../MapControls";
import { NightModeLayer } from "./NightModeLayer";
import { SatelliteLayer } from "./SatelliteLayer";
import { Building3DLayer } from "./Building3DLayer";
import { GenericGeoServerLayers } from "./GenericGeoServerLayers";
import { WaterwinningLayer, WaterwinningLayerName } from "./WaterwinningLayer";
import { VehicleLayer } from "./VehicleLayer";
import { SearchResultLayer } from "./SearchResultLayer";
import { ReplaceBuildingNumberLabelLayer } from "./ReplaceBuildingNumberLabelLayer";
import { ObjectInformatieLayer, ObjectInformatieLayerName } from "./ObjectInformatieLayer";
import { WegwerkzaamhedenLayer, WegwerkzaamhedenLayerName } from "./WegwerkzaamhedenLayer";
import { IncidentLocationLayer } from "./IncidentLocationLayer";
import { useMobileDetection } from "../../hooks/useMobileDetection";
import { MapPopupProvider } from "../../contexts/mapPopupContext";
import { MapboxPopup } from "../MapboxPopup";
import { ProtectedLayer } from "../ProtectedLayer";
import { DigitaleVerkenningLayer, DigitaleVerkenningLayerName } from "./DigitaleVerkenningLayer";
import { UserGeoLocationLayer } from "./UserGeoLocationLayer";
import { MutableRefObject, useContext } from "react";
import { MapContext, SharedMapConfigurationContext } from "app/contexts/mapContext";
import { VehicleMarker } from "app/models/vehicleMarker";
import { TilburgseKermisLayerManager } from "./TilburgseKermisLayer";

type Props = {
  vehicleMarkers: MutableRefObject<VehicleMarker[]>;
}

/**
 * Wrapper component to handle logic that is shared between layers
 * @constructor
 */
export function LayerManager({ vehicleMarkers }: Readonly<Props>) {
  const { isMobile } = useMobileDetection();
  const map = useContext(MapContext);
  const { sharedMapConfiguration } = useContext(SharedMapConfigurationContext);

  /** Only render layers whenever the map is ready to be used */
  if (!map)
    return <></>;

  if (isMobile)
    return (
      <MapPopupProvider>
        <>
          <IncidentLocationLayer />
          <ObjectInformatieLayer />
          <WegwerkzaamhedenLayer />
        </>
      </MapPopupProvider>
    );

  return (
    <MapPopupProvider>
      <>
        <MapboxPopup />
        <MapControls />
        <NightModeLayer />
        {sharedMapConfiguration.satelliteMode && <SatelliteLayer />}
        {sharedMapConfiguration.building3DMode && <Building3DLayer />}
        <UserGeoLocationLayer />
        {sharedMapConfiguration.externalLayers[WaterwinningLayerName] &&
          <ProtectedLayer layerName={WaterwinningLayerName}>
            <WaterwinningLayer />
          </ProtectedLayer>
        }
        {sharedMapConfiguration.externalLayers[DigitaleVerkenningLayerName] &&
          <ProtectedLayer layerName={DigitaleVerkenningLayerName}>
            <DigitaleVerkenningLayer />
          </ProtectedLayer>
        }
        <GenericGeoServerLayers />
        <TilburgseKermisLayerManager />
        <VehicleLayer vehicleMarkers={vehicleMarkers} isAnimated={false} />
        <SearchResultLayer />
        <ReplaceBuildingNumberLabelLayer />
        {sharedMapConfiguration.externalLayers[ObjectInformatieLayerName] &&
          <ProtectedLayer layerName={ObjectInformatieLayerName}>
            <ObjectInformatieLayer />
          </ProtectedLayer>
        }
        {sharedMapConfiguration.externalLayers[WegwerkzaamhedenLayerName] &&
          <ProtectedLayer layerName={WegwerkzaamhedenLayerName}>
            <WegwerkzaamhedenLayer />
          </ProtectedLayer>
        }
        <IncidentLocationLayer />
      </>
    </MapPopupProvider>
  );
}