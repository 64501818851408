import { Incident } from "app/models/incident";
import { OefenincidentIndicator } from "../../../IncidentDetailView/components/IncidentHeader/components/OefenincidentIndicator";
import style from "./style.module.scss";
import { Link } from "@mui/icons-material";

export type IncidentItemProps = {
  v: Incident,
  onClick: (v: Incident) => void,
}

export function IncidentItem({ incident, onClick }: Readonly<{ incident: Incident, onClick: (incident: Incident) => void }>) {
  return (
    <div role="button" onClick={() => onClick(incident)} onKeyDown={() => onClick(incident)}>
      <div className={"header"}>
        <div className={"date-wrapper"}>
          <div className={style.labelWrapper}>
            <span className={`${style.incidentLabel} ${style.prio}`}>PRIO {incident.prioriteitBrandweer}</span>
            {incident.prioriteitGrip && <span className={`${style.incidentLabel} ${style.grip}`}>GRIP {incident.prioriteitGrip}</span>}
            {incident.brwMeldingCl && <span className={`${style.incidentLabel} ${style.brwMeldingCl}`}>{incident.brwMeldingCl}</span>}
            {incident.brwMeldingCl1 && <span className={`${style.incidentLabel} ${style.brwMeldingCl1}`}>{incident.brwMeldingCl1}</span>}
            {incident.brwMeldingCl2 && <span className={`${style.incidentLabel} ${style.brwMeldingCl2}`}>{incident.brwMeldingCl2}</span>}
          </div>
          <span className={style.date}>{incident.startDateShortTime} - {incident.startDateShort.split(" ")[0]}</span>
        </div>
      </div>
      <span className={style.location}>{incident.plaatsNaam}, {incident.location}</span>
      <OefenincidentIndicator incident={incident} size={"small"} />
      {incident.isLeidendIncident && <div className={style.linkedIndicator}><Link /></div>}
      {incident.leidendIncidentId != null && <div className={`${style.linkedIndicator} ${style.hasLeidendIncident}`} > <Link /></div>}
    </div >
  );
}