import { SharedMapConfigurationContext, useMap } from "app/contexts/mapContext";
import { useContext } from "react";
import { Map } from "mapbox-gl";

export function MapControls() {
  const { sharedMapConfiguration, setSharedMapConfiguration } = useContext(SharedMapConfigurationContext);
  //  Set traffic layer whenever the config changes
  useMap(map => {
    setTrafficLayer(map, sharedMapConfiguration.trafficMode);
  }, [sharedMapConfiguration.trafficMode]);


  //  Reset bearing when requested
  useMap(map => {
    if (sharedMapConfiguration.resetBearing) {
      const camera = map.getFreeCameraOptions();
      camera.setPitchBearing(0, 0);

      map.setFreeCameraOptions(camera);
      setSharedMapConfiguration(old => {
        return { ...old, resetBearing: false };
      });
    }
  }, [sharedMapConfiguration.resetBearing]);

  function setTrafficLayer(map: Map, enabled: boolean): void {
    addTrafficLayer();

    map.on("style.load", () => {
      addTrafficLayer();
    });

    function addTrafficLayer() {
      if (!sharedMapConfiguration.trafficMode) {
        if (!map.getLayer("traffic-data"))
          return;

        map.removeLayer("traffic-data");
        return;
      }

      if(!map.getSource("mapbox-traffic")) 
        map.addSource("mapbox-traffic", {
          type: "vector",
          url: "mapbox://mapbox.mapbox-traffic-v1",
        });

      map.addLayer({
        id: "traffic-data",
        source: "mapbox-traffic",
        "source-layer": "traffic",      
        type: "line",
        paint: {
          "line-width": 1.5,
          "line-color": [
            "case",
            ["==", "low", ["get", "congestion"]],
            "#aab7ef",
            ["==", "moderate", ["get", "congestion"]],
            "#4264fb",
            ["==", "heavy", ["get", "congestion"]],
            "#ee4e8b",
            ["==", "severe", ["get", "congestion"]],
            "#b43b71",
            "#000000"
          ]
        }
      },
      map.getStyle().name == "Mapbox Dark" ? 
        "road-label-simple" :
        "road-label"
      );
    }
  }

  return <></>;
}

