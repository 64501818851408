import {useEnrichText} from "../../../hooks/useEnrichText";

interface Props {
  data: Record<string,string>[];
}

export function DefaultPopupTemplate({data}: Readonly<Props>) {

  const labelTranslations = [
    {value: "type_uitvo", display: "Type uitvoering"},
    {value: "omschrijvi", display: "Omschrijving"},
    {value: "m_nr", display: "M nr" },
    {value: "sirene_pos", display: "Sirene post"},
    {value: "regio_nr", display: "Regio nummer"},
  ];

  const defaultInfoFilter = [
    "uuid",
    "datum",
    "actualiteit",
    "regio",
    "bouwlaag_min",
    "bouwlaag_max",
    "symbool_id",
    "bouwlaag",
    "object_uuid",
    "object_fase",
    "object_actualiteit",
    "dreiging_id",
    "rotatie",
    "icon_svg",
    "x",
    "y",
    "x_rd",
    "y_rd",
    "x_cooord",
    "x_coord",
    "y_coord",
    "type_uitvo"
  ];

  const hideIfEmpty= [
    "optioneel1",
    "optioneel2",
    "optioneel3",
  ];
  return (
    <>
      {data.map((feature) => {
        return (
          <ul key={`layer-${crypto.randomUUID()}`} className="object-info-popup">
            {
              Object.entries(feature).filter(([key]) => !defaultInfoFilter.includes(key)).map(([key, value]) => {
                let label = labelTranslations.find(x => x.value === key)?.display ?? key;
                if(label.includes("_")){
                  label = label.replace("_", " ");
                }

                if (value && !hideIfEmpty.includes(key))
                  return (
                    <li className="object-info-property" key={crypto.randomUUID()}>
                      <p>{label.charAt(0).toUpperCase()}{label.slice(1)}</p>
                      <span>{useEnrichText(value || "-")}</span>
                    </li>
                  );
              })}
          </ul>
        );
      })}
    </>
  );
}

