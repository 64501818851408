import * as React from "react";
import { SVGProps } from "react";

const SvgUvIndex3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <clipPath id="uv-index-3_svg__a">
        <path fill="none" d="M64 64h384v192H328a72 72 0 0 0-72 72v120H64Z" />
      </clipPath>
      <symbol id="uv-index-3_svg__b" viewBox="0 0 375 375">
        <circle
          cx={187.5}
          cy={187.5}
          r={84}
          fill="none"
          stroke="#fbbf24"
          strokeMiterlimit={10}
          strokeWidth={15}
        />
        <path
          fill="none"
          stroke="#fbbf24"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={15}
          d="M187.5 57.2V7.5m0 360v-49.7m92.2-222.5 35-35M60.3 314.7l35.1-35.1m0-184.4-35-35m254.5 254.5-35.1-35.1M57.2 187.5H7.5m360 0h-49.7"
        />
      </symbol>
    </defs>
    <g clipPath="url(#uv-index-3_svg__a)">
      <use
        xlinkHref="#uv-index-3_svg__b"
        width={375}
        height={375}
        transform="translate(68.5 68.5)"
      />
      <path
        fill="none"
        stroke="#fbbf24"
        strokeMiterlimit={10}
        strokeWidth={15}
        d="M254 338v-10a74 74 0 0 1 74-74h10"
      />
    </g>
    <rect width={144} height={144} x={280} y={280} fill="#ffb800" rx={48} />
    <path
      fill="#fff"
      d="M365.5 349.2a17.8 17.8 0 0 1 10.8 5.7 16.4 16.4 0 0 1 3.8 11.2 20 20 0 0 1-7.2 15.7q-7.3 6.2-20.3 6.2-13.6 0-21-6.7t-7.8-18.6h16.5q.6 11.7 11.7 11.7a11.6 11.6 0 0 0 7.7-2.4 8.1 8.1 0 0 0 2.8-6.5 7.5 7.5 0 0 0-3-6.5 14 14 0 0 0-8.4-2.2h-4.9v-12h5.2c3 0 5.3-.6 7-2a7 7 0 0 0 2.4-5.7 7.2 7.2 0 0 0-2.4-5.8c-1.7-1.4-4-2-7-2s-5.2.7-6.8 2.1-2.4 3.9-2.7 7.2H326q1-22.6 26-22.6 11.8 0 18.5 5.2a16.1 16.1 0 0 1 6.6 13.3 14.9 14.9 0 0 1-3 9.3 15.2 15.2 0 0 1-8.6 5.4Z"
    />
  </svg>
);

export default SvgUvIndex3;
