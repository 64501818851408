import { TelemetryValue } from "./TelemetryValue";

/**
 * Component for displaying battery telemetry information
 * @constructor
 */
export function TelemetryAccu({ accuSpanning, laderStatus }: Readonly<{ accuSpanning: number | undefined, laderStatus?: number }>) {
  if (!accuSpanning)
    return <></>;

  return (
    <div className="telemetry-accu">
      <div className={"header"}>Accu:</div>
      <TelemetryValue value={accuSpanning.toFixed(1)} unit="V" />
      {laderStatus != undefined && <span>{laderStatus === 0 ? "Lader aangesloten" : "Lader ontkoppeld"}</span>}
    </div>
  );
}