/**
 * Component for displaying battery telemetry information
 * @component
 */
export function TelemetryHogedrukPomp({active, druk}: Readonly<{ active: number | undefined, druk : number | undefined }>) {
  return(
    <div className="telemetry-pomp">
      <div className="column">
        <div className={"header"}>Hogedrukpomp:</div>
      </div>
      <div className={"column"}>
        <span>{active ? "Actief" : "Inactief"}</span>
        <span>{druk ?? 0} bar</span>
      </div>
    </div>
  );
}