import { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import eventDispatcher, { CustomEvents } from "eventDispatcher";
import { Notification } from "./models/Notification";

/**
 * Notification bar component
 * @returns NotificationBar component
 */
export default function NotificationBar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const notification = useRef<Notification>();

  /**
   * Open the notification bar.
   */
  async function openNotificationBar(not: Notification) {
    if (!not?.content)
      return;

    if (not.content === notification.current?.content) return;

    await closeNotificationBar();
    setIsOpen(true);
    notification.current = not;
  }

  /**
   * Close the notification bar.
   */
  function closeNotificationBar() {
    return new Promise<void>((resolve) => {
      setIsOpen(false);
      notification.current = undefined;
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }

  useEffect(() => {
    if (notification.current?.autoClose) {
      const timeout = setTimeout(() => {
        closeNotificationBar();
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [notification.current?.autoClose]);


  /**
     * Initialize event listeners.
     */
  useEffect(() => {
    eventDispatcher.on(CustomEvents.OpenNotificationBar, openNotificationBar);
  }, []);

  function determinePrefix() {
    if (notification.current?.type === "info" || notification.current?.type === "success")
      return "Melding:";
    if (notification.current?.type === "warning")
      return "Waarschuwing:";
    if (notification.current?.type === "error")
      return "Fout:";
  }

  function determineClassName() {
    if (notification.current?.type === "info")
      return style.info;
    if (notification.current?.type === "warning")
      return style.warning;
    if (notification.current?.type === "error")
      return style.error;
  }

  function onClick() {
    if (notification.current?.onClick)
      notification.current?.onClick();

    setIsOpen(false);
    notification.current = undefined;
  }

  return (
    <div id={style.notificationBar} className={`${determineClassName()} ${isOpen ? style.open : style.closed}`} onClick={onClick}>
      <label className={style.type}>{determinePrefix()}</label>
      <label className={style.message}>{notification.current?.content}</label>
    </div>
  );
}