import * as React from "react";
import { SVGProps } from "react";

const SvgThunderstorms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="thunderstorms_svg__a" viewBox="0 0 359 231">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2 56 56 0 0 0-84.6 48.1 56.6 56.6 0 0 0 .8 9 60 60 0 0 0 11.2 119"
        />
      </symbol>
      <symbol id="thunderstorms_svg__b" viewBox="0 0 96 176">
        <path fill="#f6a823" d="M32 0 0 96h32l-16 80L96 64H48L80 0H32z" />
      </symbol>
    </defs>
    <use
      xlinkHref="#thunderstorms_svg__a"
      width={359}
      height={231}
      transform="translate(76.5 140.5)"
    />
    <use
      xlinkHref="#thunderstorms_svg__b"
      width={96}
      height={176}
      transform="translate(208 293)"
    />
  </svg>
);

export default SvgThunderstorms;
