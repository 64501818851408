import * as React from "react";
import { SVGProps } from "react";

const SvgOvercast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="overcast_svg__b" viewBox="0 0 214.3 140.1">
        <path
          fill="none"
          stroke="#94a3b8"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M7.5 100.2a32.4 32.4 0 0 0 32.4 32.4h129.8v-.1l2.3.1a34.8 34.8 0 0 0 6.5-68.9 32.4 32.4 0 0 0-48.5-33 48.6 48.6 0 0 0-88.6 37.1h-1.5a32.4 32.4 0 0 0-32.4 32.4Z"
        />
      </symbol>
      <symbol id="overcast_svg__c" viewBox="0 0 359 231">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2 56 56 0 0 0-84.6 48.1 56.6 56.6 0 0 0 .8 9 60 60 0 0 0 11.2 119"
        />
      </symbol>
      <symbol id="overcast_svg__d" viewBox="0 0 447.7 371.5">
        <g clipPath="url(#overcast_svg__a)">
          <use
            xlinkHref="#overcast_svg__b"
            width={214.3}
            height={140.1}
            transform="translate(195.51 165.01)"
          />
        </g>
        <use
          xlinkHref="#overcast_svg__c"
          width={359}
          height={231}
          transform="translate(0 140.5)"
        />
      </symbol>
      <clipPath id="overcast_svg__a">
        <path
          fill="none"
          d="M351.5 308a56 56 0 0 0-56-56l-2.5.1A83.7 83.7 0 0 0 211.5 148V0h236.2v308Z"
        />
      </clipPath>
    </defs>
    <use
      xlinkHref="#overcast_svg__d"
      width={447.7}
      height={371.5}
      transform="translate(64.34)"
    />
  </svg>
);

export default SvgOvercast;
