import { MapContext, useMap } from "app/contexts/mapContext";
import { AnyLayer } from "mapbox-gl";
import { useCallback, useContext } from "react";

const building3dLayerId = "add-3d-buildings";
const renderBeforeLayer = "country-label";
const layerDefinition: AnyLayer = {
  "id": building3dLayerId,
  "source": "composite",
  "source-layer": "building",
  "filter": [
    "any",
    ["==", "extrude", "true"]
  ],
  "type": "fill-extrusion",
  "minzoom": 15,
  "paint": {
    "fill-extrusion-color": "#aaa",
    "fill-extrusion-height": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "height"]
    ],
    "fill-extrusion-base": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "min_height"]
    ],
    "fill-extrusion-opacity": 0.6
  }
};

/**
 * Component for adding 3D buildings to the map
 * @returns 
 */
export function Building3DLayer() {
  const map = useContext(MapContext);

  /**
   * Add the layer to the map
   * @remarks This function is within a callback to prevent it from being "changed" on every render
   */
  const addLayer = useCallback(() => {
    if (!map)
      return;

    if (map.getLayer(building3dLayerId))
      return;

    map.addLayer(layerDefinition, renderBeforeLayer);
  }, []);

  /**
 * Remove the layer to the map
 * @remarks This function is within a callback to prevent it from being "changed" on every render
 */
  const removeLayer = useCallback(() => {
    if (!map)
      return;

    if (!map.getLayer(building3dLayerId))
      return;

    map.removeLayer(building3dLayerId);
  }, []);

  useMap((map) => {
    addLayer();

    /** Add the layer whenever the style of mapbox changes */
    map.on("styledata", addLayer);

    return () => {
      map.off("styledata", addLayer);
      removeLayer();
    };
  }, []);

  return <></>;
}
