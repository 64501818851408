import { MemoizedTimeLineImage } from "app/components/IncidentPanel/components/IncidentDetailView/components/IncidentTimelineImage";
import { Kladblokregel } from "../../../../../../../models/incident";
import { addCrashRecoveryAppLinks } from "../../../../../../../services/crashRecovery";
import { ValidateDate } from "../../../../../../../services/parseWithDate";
import style from "../style.module.scss";

/**
 * TimelineItem component
 * @param regel - the rule to display
 * @param incidentId - the incident id (used for retrieving images)
 * @constructor
 */
export function TimelineItem({ regel, incidentId }: Readonly<{ regel: Kladblokregel, incidentId: number }>) {
  const alteredContent = addCrashRecoveryAppLinks(regel.inhoud);
  const date = ValidateDate(regel.dateCreated).toLocaleDateString("nl-NL", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });

  return (
    <div className={style.item}>
      <div className={style.timelineHeader}>
        {regel.userName && <span className={style.username}>{regel.userName}</span>}
        <div className={style.dateWrapper}>
          <span className={style.date}>{date}</span>
          <span className={style.type}>{regel.type == "OIV" && "Alleen zichtbaar via OIV"}</span>
        </div>
      </div>
      <div className={style.timelineContent}>
        <p>{alteredContent}</p>
        {regel.type == "Image" &&
          <MemoizedTimeLineImage key={regel.fileGuid} incidentId={incidentId}
            fileGuid={regel.fileGuid} />}
      </div>
    </div>
  );
}
