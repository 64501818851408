interface Props {
  data: Record<string,string>[];
}

export function ToegangsleutelPopupTemplate({ data }: Readonly<Props>) {
  return(
    <div className="popup-category">
      <table>
        <tr><td>{data[0]["toegang_op"]}</td></tr>
      </table>
    </div>
  );
}