import { useSWRConfig } from "swr";
import { FormEvent, useState } from "react";
import style from "./style.module.scss";
import Send from "@mui/icons-material/Send";
import { useResourceFetch } from "app/hooks/useResourceFetch";
import { TextFormField, TextFormFieldData } from "app/components/FormFields/TextFormField";
import { DetermineApiUri } from "config";
import Button from "@mui/material/Button";
import CameraView from "app/panels/rightpanel/components/CameraComponent/CameraView";

/**
 * Input form for OIV Kladblokregels
 * @constructor
 */
export function IncidentKladblokInput({ incidentId }: Readonly<{ incidentId: number }>) {
  const protectedFetch = useResourceFetch();
  const { mutate } = useSWRConfig();
  const [kladblockEntry, setKladblockEntry] = useState<TextFormFieldData>(new TextFormFieldData(
    "",
    "kladContent",
    "",
    (event: React.ChangeEvent<HTMLInputElement>) => setKladblockEntry({ ...kladblockEntry, value: event.target.value })));

  /**
   * Handle form submit
   * @param e
   */
  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    const form = new FormData();
    form.append("kladContent", kladblockEntry.value);
    form.append("incidentId", incidentId.toString());

    await protectedFetch(`${DetermineApiUri()}/api/publishoivkladblok`, {
      method: "POST",
      body: form
    });

    // Invalidate cache:
    mutate(`${DetermineApiUri()}/api/media/oivkladblok/${incidentId}`);
    setKladblockEntry({ ...kladblockEntry, value: "" });
  }

  return (
    <form id={style.incidentKladblokInput} onSubmit={onSubmit}>
      <TextFormField field={kladblockEntry} className={style.inputText} multiline={false} />
      <Button variant="contained" size="large" disabled={kladblockEntry.value == ""} type="submit" ><Send /></Button>
      <CameraView incidentId={incidentId} />
    </form>
  );
}
