import { useEffect, useState } from "react";

/**
 * Hook to check if the user is online
 * @returns isOnline: boolean, indicates if the user is online or not
 * @returns isBackendReachable: boolean, indicates if the backend is reachable or not
 */
export function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  /**
   * Check if the user is online
   */
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return { isOnline };
}