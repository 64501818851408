import { useState } from "react";
import { UserSettingsModal } from "./UserSettingsModal";
import EventDispatcher, { CustomEvents } from "../../../eventDispatcher";
import style from "./style.module.scss";
import { GeolayersSelectionModal } from "./GeolayersSelectionModal";

export type Modals = "UserSettings" | "Feedback" | "GeolayersSelection";

/**
 * Manager for handling Modals within the viewer
 * @returns 
 */
export function ModalManager() {
  const [currentModal, setCurrentModal] = useState<Modals | null>(null);

  EventDispatcher.on(CustomEvents.OpenUserSettings, () => {
    setCurrentModal("UserSettings");
  });

  EventDispatcher.on(CustomEvents.OpenModal, (modal: Modals) => {
    setCurrentModal(modal);
  });

  if (currentModal == null) {
    return (<></>);
  }

  function onClose() {
    setCurrentModal(null);
  }

  return (
    <div className={style.modalOverlay}>
      {currentModal == "UserSettings" && <UserSettingsModal onClose={onClose} />}
      {currentModal == "GeolayersSelection" && <GeolayersSelectionModal onClose={onClose} />}
    </div>
  );
}