import { Dispatch, SetStateAction } from "react";

export enum VisualMode {
  "Dark",
  "Light",
  "OpenStreetMaps"
}

export interface MapConfiguration {
  externalLayers: Record<string, boolean>;
  visualMode: VisualMode;
  satelliteMode: boolean;
  building3DMode: boolean;
  trafficMode: boolean;
  trackGeoLocation: boolean;
  resetBearing: boolean;
  measurementDrawMode: boolean;
}

export interface SharedMapConfiguration {
  sharedMapConfiguration: MapConfiguration;
  setSharedMapConfiguration: Dispatch<SetStateAction<MapConfiguration>>;
}

export const InitMapConfiguration: MapConfiguration = {
  externalLayers: {},
  visualMode: VisualMode.Light,
  satelliteMode: false,
  trafficMode: false,
  building3DMode: false,
  trackGeoLocation: false,
  resetBearing: false,
  measurementDrawMode: false,
};

export const AzureMapsConfiguration = {
  satteliteLayer: {
    serviceUrl: "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_ortho25",
    serviceType: "WMTS",
    layerName: "Actueel_ortho25",
    minZoom: 10,
    minSourceZoom: 14,
    maxSourceZoom: 15
  }
};