import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ButtonProps = {
  onClick: () => void,
  disabled?: boolean
  label?: string;
  icon?: IconDefinition,
  variant: "contained" | "outlined",
  className?: string;
}

/**
 * Generic button
 */
export function Button({ label, disabled, className, onClick, variant, icon }: Readonly<ButtonProps>) {
  return (
    <button className={`${style.button} ${variant} ${className}`} disabled={disabled} onClick={onClick}>
      {label && <span>{label}</span>}
      {icon && <FontAwesomeIcon icon={icon} />}
    </button>
  );
}