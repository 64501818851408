import * as React from "react";
import { SVGProps } from "react";

const SvgWindBeaufort3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="wind-beaufort-3_svg__a" viewBox="0 0 342 234">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M264.2 21.3A40 40 0 1 1 293 89H9m139.2 123.7A40 40 0 1 0 177 145H9"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#wind-beaufort-3_svg__a"
      width={342}
      height={234}
      transform="translate(85 139)"
    />
    <path
      fill="#374251"
      d="M397.5 305.2a17.8 17.8 0 0 1 10.8 5.7 16.4 16.4 0 0 1 3.8 11.2 20 20 0 0 1-7.2 15.7q-7.3 6.2-20.3 6.2-13.6 0-21-6.7t-7.8-18.6h16.5q.6 11.7 11.7 11.7a11.6 11.6 0 0 0 7.7-2.4 8.1 8.1 0 0 0 2.8-6.5 7.5 7.5 0 0 0-3-6.5 14 14 0 0 0-8.4-2.2h-4.9v-12h5.2c3 0 5.3-.6 7-2a7 7 0 0 0 2.4-5.7 7.2 7.2 0 0 0-2.4-5.8c-1.7-1.4-4-2-7-2s-5.2.7-6.8 2.1-2.4 3.9-2.7 7.2H358q1-22.6 26-22.6 11.8 0 18.5 5.2a16.1 16.1 0 0 1 6.6 13.3 14.9 14.9 0 0 1-3 9.3 15.2 15.2 0 0 1-8.6 5.4Z"
    />
  </svg>
);

export default SvgWindBeaufort3;
