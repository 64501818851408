import mapboxgl from "mapbox-gl";
import MarkerAnimation from "./animation";
import { ClickableMarker } from "./clickableMarker";
import {logger} from "../services/logger";

const fps = 10;
const duration = 20000; // in milliseconds

/**
 * A moving marker that will slide towards destinations on the map
 */
export class MovingMarker {
  #HTMLElement: HTMLElement | undefined;

  /**
   * The mapbox map
   */
  map: mapboxgl.Map;

  /**
   * Id of the marker
   */
  id: string;

  /**
   * The mapbox marker
   */
  marker: ClickableMarker;

  /**
   * Current position of the marker
   */
  position: [number, number];

  /**
   * Destinations of the marker
   * @remarks Used to create animations.
   */
  destinations: [number, number][];

  /**
   * Current animation of the marker
   */
  currentAnimation: MarkerAnimation | undefined;

  /**
   * Interval id of the marker
   * @remarks Used to clear the interval.
   */
  intervalId: string | undefined;

  /**
   * If the marker should be animated (otherwise it will be teleported)
   */
  isAnimated: boolean;

  /**
   * Click handler for the marker
   */
  #onClick: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void;

  /**
   * Constructor
   * @param id  Id of the marker
   * @param position  Starting position of the marker
   * @param element  HTML element that will be rendered as "the marker"
   * @param map  Mapbox instance that the marker will be added to
   * @param onClick  Click handler for the marker
   * @param isAnimated If the marker should be animated (otherwise it will be teleported)
   */
  constructor(id: string,  position: [number, number], element: HTMLElement, map: mapboxgl.Map, onClick: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void, isAnimated: boolean) {
    this.id = id;
    this.#HTMLElement = element;
    this.map = map;
    this.#onClick = onClick;
    this.marker = new ClickableMarker(this.#HTMLElement, { draggable: false })
      .setLngLat(position)
      .onClick(onClick)
      .addTo(map);
    this.position = position;
    this.destinations = [];
    this.isAnimated = isAnimated;
    this.#setInterval();
  }

  /**
   * Queue a destination that the marker will move towards.
   * @param destination 
   */
  queueDestination(destination: [number, number]) {

    // If the new destination is the same as the current position, return
    if(destination[0] == this.position[0] && destination[1] == this.position[1])
      return;

    logger.verbose(`[Marker ${this.id}] Queuing destination ${destination[1]}, ${destination[0]}`);
    this.destinations.push(destination);
  }

  /**
   * Start the animation of the marker
   */
  #setInterval() {
    this.intervalId = setInterval(() => {
      if(this.isAnimated){
        this.animate();
      }else{
        this.teleport();
      }
    }, (1000 / fps)).toString();
  }

  /**
   * Remove the marker from the map
   */
  remove() {
    if(this.intervalId !== undefined)
      clearInterval(parseInt(this.intervalId));
    this.marker.remove();
  }

  /**
   * Teleport the marker to a new position
   */
  teleport(): void{
    const destination = this.destinations.shift();
    if(destination === undefined)
      return;

    this.marker.setLngLat(destination);
    this.marker.addTo(this.map);

    this.position = destination;
  }

  /**
   * Set the HTML element of the marker
   * @param element  HTML element that will be rendered as "the marker"
   */
  setElement(element: HTMLElement){
    this.#HTMLElement = element;
    this.marker.remove();
    this.marker = new ClickableMarker(this.#HTMLElement, { draggable: false })
      .setLngLat(this.position)
      .onClick(this.#onClick)
      .addTo(this.map);
  }

  /**
   * Animate the marker
   * @returns
   */
  animate(): void {

    // If there is no current animation, create one
    if(this.currentAnimation === undefined)
      this.currentAnimation = this.#createAnimation();

    // If there is no current animation, return
    if (this.currentAnimation === undefined)
      return;

    // If the current animation is finished, remove it and return
    if(this.currentAnimation.currentStep == this.currentAnimation.animationSteps){
      this.currentAnimation = undefined;
      return;
    }

    const newLat = this.currentAnimation.steps[this.currentAnimation.currentStep][0];
    const newLong = this.currentAnimation.steps[this.currentAnimation.currentStep][1];

    this.marker.setLngLat([newLat, newLong]);
    this.marker.addTo(this.map);

    this.position = [newLat, newLong];
    this.currentAnimation.currentStep++;
  }

  /**
   * Create an animation based on the next destination in queue.
   * @returns  Returns an animation or undefined if there are no destinations in queue.
   */
  #createAnimation(): MarkerAnimation | undefined {
    if(this.destinations.length === 0)
      return undefined;

    const destination = this.destinations.shift();

    if (!destination || this.position[0] == destination[0] && this.position[1] == destination[1])
      return undefined;

    return {
      currentStep: 0,
      animationSteps: this.#calculateAnimationSteps(),
      destination: destination,
      steps: this.#calculateIntermediatePoints(this.position, destination, this.#calculateAnimationSteps()),
    };
  }

  /**
   * Calculate the intermediate points between two points
   * @param start Starting position
   * @param end End position
   * @param numSteps Number of steps between the start and end position
   * @returns  Returns an array with positions in between the start and end position
   */
  #calculateIntermediatePoints(start:[number,number], end: [number,number], numSteps: number): Array<[number, number]> {
    const intermediatePoints: Array<[number, number]> = [];
    for (let i = 0; i <= numSteps; i++) {
      const ratio = i / numSteps;
      const intermediateLat = start[0] + ratio * (end[0] - start[0]);
      const intermediateLon = start[1] + ratio * (end[1] - start[1]);
      intermediatePoints.push([intermediateLat, intermediateLon]);
    }
    return intermediatePoints;
  }

  /**
   * Calculate how many steps there are needed for the animation
   * @returns total of steps the animation needs to be.
   */
  #calculateAnimationSteps(): number {
    const numIntervalsPerSecond = fps; // Number of intervals per second
    const animationSteps = duration / (1000 / numIntervalsPerSecond);
    return animationSteps;
  }
}

