import {MovingMarker} from "./movingMarker";
import {Vehicle} from "./vehicleLocation";
import {renderToStaticMarkup} from "react-dom/server";
import mapboxgl, {Popup} from "mapbox-gl";
import GenericVehicleMarker from "../components/Markers/GenericVehicleMarker";
import {MsalProvider} from "@azure/msal-react";
import {VehiclePopup} from "../components/Popups/VehiclePopup";
import {createRoot} from "react-dom/client";
import {IPublicClientApplication} from "@azure/msal-browser";

export class VehicleMarker extends MovingMarker {
  #vehicle : Vehicle;
  #msalInstance : IPublicClientApplication;

  /**
   * Constructor
   * @param vehicle Vehicle that is used to create the marker
   * @param map Map that the marker is added to
   * @param isAnimated Is the marker animated
   * @param instance
   */
  constructor(vehicle: Vehicle, map: mapboxgl.Map, isAnimated: boolean, instance: IPublicClientApplication) {
    const element = document.createElement("div");
    element.innerHTML = renderToStaticMarkup(<GenericVehicleMarker vehicle={vehicle} />);

    super(
      vehicle.roepnaamEenheid,
      [vehicle.location.longitude, vehicle.location.latitude],
      element,
      map,
      (e) => this.showTelemetryPopup(e),
      isAnimated
    );

    this.#vehicle = vehicle;
    this.#msalInstance = instance;
  }

  /**
   * Render the telemetry popup on the map based on the clicked marker
   * @param e
   * @param vehicleName
   * @param instance
   */
  showTelemetryPopup(e: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
    const popupContent = document.createElement("div");
    const root = createRoot(popupContent);

    // Msal provider is needed to ensure popup root can use api calls
    root.render(
      <MsalProvider instance={this.#msalInstance}>
        <VehiclePopup machine={this.#vehicle}/>
      </MsalProvider>
    );

    new Popup()
      .setLngLat(e.lngLat)
      .setMaxWidth("none")
      .setDOMContent(popupContent)
      .on("close", () => root.unmount())
      .addTo(e.target);
  }

  vehicleName(): string { 
    return this.#vehicle.roepnaamEenheid;
  }

  focusOnMap() {
    this.map.flyTo({
      center: this.marker.getLngLat(),
      zoom: 23
    });
  }

  /**
   * Update the marker on the map based on the vehicle
   * @param vehicle
   */
  update(vehicle: Vehicle) {
    this.#updateMarker(vehicle);
    this.queueDestination([vehicle.location.longitude, vehicle.location.latitude]);
    this.#vehicle = vehicle;
  }

  /**
   * Update the marker on the map based on the vehicle
   * @param vehicle
   * @private
   */
  #updateMarker(vehicle: Vehicle) {
    if(this.#vehicle.status?.tActStatusCodeExtBrw == vehicle.status?.tActStatusCodeExtBrw)
      return;

    const element = document.createElement("div");
    element.innerHTML = renderToStaticMarkup(<GenericVehicleMarker vehicle={vehicle} />);
    this.setElement(element);
  }
}