import { OpenViewerOptions, ViewerType } from "@cyclomedia/streetsmart-api";

export const defaultPanoramaViewerOptions: OpenViewerOptions = {
  viewerType: [ViewerType.PANORAMA_ZOOM],
  srs: "EPSG:28992",
  panoramaViewer: {
    navbarVisible: false,
    timeTravelVisible: false,
    closable: false,
    maximizable: false,
  }
};