import { Incident, Karakteristiek } from "app/models/incident";
import styles from "./style.module.scss";
import { useMobileDetection } from "app/hooks/useMobileDetection";
import { Incidentgroep } from "../IncidentGroep";

/**
 * Incident properties component
 * @param incident
 * @constructor
 */
export function IncidentProperties({ incident }: Readonly<{ incident: Incident }>) {
  const { isMobile } = useMobileDetection();

  /**
   * Get the grip karakteristiek from the incident
   * @param incident
   */
  function getGripKarakteristiek(incident: Incident): string {
    const gripKarakteristiek = incident.karakteristieken.filter(x => x.naamKarakteristiek == "GRIP")[0] || null;
    return gripKarakteristiek ? gripKarakteristiek.actueleKarWaarde : "-";
  }

  /**
   * Get the opschalingsniveau from the incident
   * @param incident
   */
  function getOpschalingsniveau(incident: Incident): string {
    const opschalingsTypes = [
      "ops br",
      "ops hv",
      "ops ibgs",
      "ops wo"
    ];

    /**
     * Sort the karakteristieken on date
     * @param a
     * @param b
     */
    function sortOnDate(a: Karakteristiek, b: Karakteristiek) {
      const aDate = new Date(a.dtgRegWaarde);
      const bDate = new Date(b.dtgRegWaarde);

      return aDate.getTime() - bDate.getTime();
    }

    const sortedKarakteristieken = [...incident.karakteristieken].sort(sortOnDate);

    for (const item of sortedKarakteristieken) {
      if (opschalingsTypes.includes(item.naamKarakteristiek.toLowerCase())) {
        return item.actueleKarWaarde;
      }
    }
    return "-";
  }

  return (
    <div className={`${styles.incidentPropertiesWrapper} ${isMobile ? styles.mobile : ""}`}>
      <div className={styles.incidentProperties}>
        <div>
          <p className={styles.propertyLabel}><b>INCIDENT NR.</b></p>
          <p>{incident.incidentNr}</p>
        </div>
        <div>
          <p className={styles.propertyLabel}><b>PRIO</b></p>
          <p>{incident.prioriteitBrandweer}</p>
        </div>
        <div>
          <Incidentgroep incident={incident} />
        </div>
        <div>
          <p className={styles.propertyLabel}><b>OPSCHALING</b></p>
          <p>{getOpschalingsniveau(incident)}</p>
        </div>
        <div>
          <p className={styles.propertyLabel}><b>GRIP</b></p>
          <p>{incident?.prioriteitGrip ?? "-"}</p>
        </div>
        <div>
          <p className={styles.propertyLabel}><b>DISCIPLINES</b></p>
          <p>{incident.disciplines}</p>
        </div>
      </div>
    </div>
  );
}
