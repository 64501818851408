import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis COPI layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/1200}
 */
export const CopiDefinition: ArcGISFeatureServerLayerDefinition = {
  layerId: LayerLabels.copi,
  layers: [
    {
      layerId: 1200,
      icons: [{
        name: "copi",
        path: "/assets/images/icons/tilburgse_kermis_icon_copi.png",
      }],
      layerDefinitions: [
        {
          "id": `${LayerLabels.copi}-symbol-layer`,
          "type": "symbol",
          "layout": {
            "icon-image": "copi",
            "icon-size": {
              "base": 1,
              "stops": [[1, 1], [22, 1]]
            }
          }
        },
        {
          "id": `${LayerLabels.copi}-label-layer`,
          "type": "symbol",
          "minzoom": 0,
          "maxzoom": 22,
          "filter": ["==", "CATEGORIE", "Evenement"],
          "layout": {
            "text-field": "{OPMERKING}",
            "text-font": ["Open Sans Semibold"],
            "text-size": 10,
            "text-transform": "none",
            "text-letter-spacing": 0,
            "text-max-width": 10,
            "text-padding": 2,
            "text-anchor": "top-right",
            "text-offset": [0, 0],
            "text-allow-overlap": false,
            "text-ignore-placement": false,
            "text-optional": true,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true
          },
          "paint": {
            "text-color": "#000000",
            "text-halo-color": "#FFFFFF",
            "text-halo-width": 2
          }
        }
      ]
    },
  ],
  isToggledByKermisInformatieLayer: true
};