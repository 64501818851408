import { MachineTelemetry } from "../../../../models/machineTelemetry";
import { TelemetryAccu } from "../components/TelemetryAccu";
import { TelemetryTanks } from "../components/TelemetryTanks";
import { TelemetryUtil } from "../../../../helpers/telemetryUtil";
import { TelemetryBoosterPomp } from "../components/TelemetryBoosterPomp";
import { TelemetryDompelPomp } from "../components/TelemetryDompelPomp";
import { TelemetryWaterflow } from "../components/TelemetryWaterflow";

const MeasurementKeys = {
  AccuSpanning: "value.Application.Chassis.AccuSpanning",
  NiveauBrandstof: "value.Application.Chassis.Brandstofniveau",
  BoosterpompActief: "value.Application.Opbouw.BoosterpompActief",
  DompelpompActief: "value.Application.Opbouw.DompelpompActief",
  IngaandeDrukBoosterpomp: "value.Application.Opbouw.IngaandeDrukBoosterpomp",
  UitgaandeDrukBoosterpomp: "value.Application.Opbouw.UitgaandeDrukBoosterpomp",
  ActueeleWaterflow: "value.Application.Opbouw.ActueeleWaterflow",
  TripVolumeWater: "value.Application.Opbouw.TripVolumeWater",
};

export function Kleinwatertransport({ telemetry }: Readonly<{ telemetry: MachineTelemetry }>) {
  const accuSpanning = TelemetryUtil.extractValue(telemetry, MeasurementKeys.AccuSpanning);
  const niveauBrandstof = TelemetryUtil.extractValue(telemetry, MeasurementKeys.NiveauBrandstof, 0);
  const boosterpompActief = TelemetryUtil.extractValue(telemetry, MeasurementKeys.BoosterpompActief);
  const dompelpompActief = TelemetryUtil.extractValue(telemetry, MeasurementKeys.DompelpompActief);
  const ingaandeDrukBoosterpomp = TelemetryUtil.extractValue(telemetry, MeasurementKeys.IngaandeDrukBoosterpomp, 1);
  const uitgaandeDrukBoosterpomp = TelemetryUtil.extractValue(telemetry, MeasurementKeys.UitgaandeDrukBoosterpomp, 0);
  const actueeleWaterflow = TelemetryUtil.extractValue(telemetry, MeasurementKeys.ActueeleWaterflow, 0);
  const tripVolumeWater = TelemetryUtil.extractValue(telemetry, MeasurementKeys.TripVolumeWater, 0);

  return (
    <>
      <TelemetryAccu accuSpanning={accuSpanning} />
      <TelemetryBoosterPomp active={boosterpompActief} ingaandedruk={ingaandeDrukBoosterpomp} uitgaandedruk={uitgaandeDrukBoosterpomp} />
      <TelemetryDompelPomp active={dompelpompActief} />
      <TelemetryWaterflow actueel={actueeleWaterflow} tripVolume={tripVolumeWater} />
      <TelemetryTanks
        brandstoftankNiveau={niveauBrandstof}
      />
    </>
  );
}
