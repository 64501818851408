/**
 * LayerLabels for the Tilburgse Kermis layer
 * @description These are dictated by the backend which is responsible for returning all possible selectable layers
 */
export class LayerLabels {
  static readonly prefix: Readonly<string> = "internal_tilburg_kermis";
  static readonly kermisInformatie: Readonly<string> = `${this.prefix}_kermisinformatie`;
  static readonly attracties: Readonly<string> = `${this.prefix}_attracties`;
  static readonly barries: Readonly<string> = `${this.prefix}_barries`;
  static readonly bomenGroenbakken: Readonly<string> = `${this.prefix}_bomen_groenbakken`;
  static readonly calamiteitenroute: Readonly<string> = `${this.prefix}_calamiteitenroute`;
  static readonly copi: Readonly<string> = `${this.prefix}_copi`;
  static readonly eva: Readonly<string> = `${this.prefix}_eva`;
  static readonly gasfles: Readonly<string> = `${this.prefix}_gasfles`;
  static readonly gridInformatie: Readonly<string> = `${this.prefix}_grid_informatie`;
  static readonly sectorenInformatie: Readonly<string> = `${this.prefix}_sectoren_informatie`;
  static readonly terras: Readonly<string> = `${this.prefix}_terras`;
}