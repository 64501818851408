import { CircularProgress } from "@mui/material";

export default function LoadingComponent() {
  return (
    <div className="vehicle-telemetry-info">
      <div className="centered">
        <CircularProgress />
      </div>
      <div className="vehicle-telemetry-info-text">
        <p>Telemetrie wordt opgehaald</p>
      </div>
    </div>
  );
}