export interface MelvinActivityPeriod {
  name: string;
  startDate: string, 
  endDate: string,
}

export interface MelvinLocation {
  city: string;
  road: string;
  district?: string;
  comment?: string;
}

export class MelvinActivity {
  activityType: string;
  addition: string;
  situationId: string;
  situationRecordId: string;
  periods: MelvinActivityPeriod[];
  location: MelvinLocation;
  remarks: string[];
  
  constructor(data: Record<string, string>) {
    this.activityType = data["activityType"];
    this.addition = data["addition"];
    this.situationId = data["situationId"];
    this.situationRecordId = data["situationRecordId"];
    this.periods = JSON.parse(data["periods"]);
    this.location = JSON.parse(data["location"]);
    this.remarks = JSON.parse(data["remarks"]);
  }

  currentActivePeriod(date: Date): MelvinActivityPeriod | undefined {
    this.periods.sort(this.sortByDate);

    for (const period of this.periods) {
      const endDate = new Date(period.endDate);
      if(date <= endDate)
        return period;
    }
    return undefined;
  }

  sortByDate(a:  MelvinActivityPeriod, b:  MelvinActivityPeriod): number {
    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
  }
}