import { LoadingComponent } from "app/components/LoadingComponent";
import style from "../style.module.scss";
import { useUser } from "app/hooks/useUser";
import { UserProfile } from "app/components/UserProfile";
import { useVersion } from "app/contexts/versionContext";

/**
 * Overview of the logged in user
 */
export function UserOverview() {
  const { user, isLoading } = useUser();
  const { version } = useVersion();

  if (!user || isLoading)
    return <LoadingComponent />;

  return (
    <>
      <div className={style.overviewHeader}>&nbsp;</div>
      <div className={style.profile}>
        <UserProfile user={user} hideRole={true} />
      </div>
      <div className={style.row}>
        <span className={style.label}>Account-type:</span>
        <span className={style.value}>{user.isEenheid ? "Voertuig/Eenheid" : "Persoonsgebonden"}</span>
      </div>
      <div className={style.row}>
        <span className={style.label}>Gebruikersrol:</span>
        <span className={style.value}>{user.jobTitle}</span>
      </div>
      <div className={style.footer}>
        <div className={style.logo}><img alt="VRMWB-Logo" src="assets/images/vrmwb-logo.png"></img></div>
        <span className={style.version}>Versie: {version}</span>
      </div>
    </>
  );
}