import useSWR from "swr";
import { User } from "../models/User";
import { DetermineApiUri } from "../../config";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { ROLE } from "app/components/PermissionsGate";

/**
 *  Hook for fetching the current user
 *  @returns User object
 */
export function useUser() {
  const { instance } = useMsal();
  const assignedApplicationRoles = instance.getActiveAccount()?.idTokenClaims?.roles ?? [];
  const { data, error } = useSWR<User>(`${DetermineApiUri()}/api/user`);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isManschap = assignedApplicationRoles.every(x => x?.toUpperCase() === ROLE.Manschap);

  useEffect(() => {
    if (data)
      setIsLoading(false);
  }, [data]);

  return {
    /**
     * User object of the currently logged in user
     */
    user: data,

    /**
     * Boolean indicating whether the user is still loading
     */
    isLoading,

    /**
     * Error object if the user could not be fetched
     */
    error,

    /**
     * Array of application roles assigned to the user (by Azure Entra)
     */
    assignedApplicationRoles,

    /**
     * Boolean indicating whether the user is a manschap
     */
    isManschap,
  };
}
