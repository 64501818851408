import { ArcGISFeatureServerLayerDefinition } from "../Models/ArcGISExportLayer";
import { LayerLabels } from "../Helpers/LayerLabels";

/**
 * Layer definitions for the Tilburgse Kermis Bomen/Groenbakken layer.
 * @remarks based on Esri Drawing Info of the layer
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/300}
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/600}
 * @see {@link https://services-eu1.arcgis.com/CQPBPtVdeDfydflM/ArcGIS/rest/services/KermisKermisinformatie__4_7_2024_RP/FeatureServer/700}
 */
export const BomenGroenbakkenDefinition: ArcGISFeatureServerLayerDefinition =
{
  layerId: LayerLabels.bomenGroenbakken,
  layers: [
    {
      layerId: 300,
      layerDefinitions: [
        {
          "id": `${LayerLabels.bomenGroenbakken}-boomkruin-layer`,
          "type": "fill",
          "paint": {
            "fill-color": "rgba(0,139,108,255)",
            "fill-outline-color": "rgba(0,100,79,255)"
          }
        }
      ]
    },
    {
      layerId: 600,
      layerDefinitions: [
        {
          "id": `${LayerLabels.bomenGroenbakken}-bloembak-layer`,
          "type": "fill",
          "paint": {
            "fill-color": "rgba(56,168,0,102)",
            "fill-outline-color": "rgba(0,139,108,255)"
          }
        }
      ]
    },
    {
      layerId: 700,
      layerDefinitions: [
        {
          "id": `${LayerLabels.bomenGroenbakken}-blokhagen-layer`,
          "type": "fill",
          "paint": {
            "fill-color": "rgba(56,168,0,102)",
            "fill-outline-color": "rgba(38,115,0,255)"
          }
        }
      ]
    }
  ],
  isToggledByKermisInformatieLayer: true
};