import * as React from "react";
import { SVGProps } from "react";

const SvgWindBeaufort8 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="wind-beaufort-8_svg__a" viewBox="0 0 342 234">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={18}
          d="M264.2 21.3A40 40 0 1 1 293 89H9m139.2 123.7A40 40 0 1 0 177 145H9"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#wind-beaufort-8_svg__a"
      width={342}
      height={234}
      transform="translate(85 139)"
    />
    <path
      fill="#374251"
      d="M405.4 338.1q-7.9 5.9-21.4 5.9t-21.4-5.9a19 19 0 0 1-7.9-16 16.2 16.2 0 0 1 3.5-10.7 17.7 17.7 0 0 1 9.9-5.9v-.2a14.8 14.8 0 0 1-7.5-5.6 15.3 15.3 0 0 1-2.7-9 16 16 0 0 1 6.8-13.7q7-5 19.3-5t19.3 5a16 16 0 0 1 7 13.7 15.4 15.4 0 0 1-2.8 9.1 14.3 14.3 0 0 1-7.4 5.5v.3a18.1 18.1 0 0 1 9.8 5.9 16.2 16.2 0 0 1 3.5 10.6 19 19 0 0 1-8 16Zm-21.3-7q5.6 0 9-2.5a8.5 8.5 0 0 0 3.2-7.1 8.5 8.5 0 0 0-3.2-7.1c-2.2-1.7-5.2-2.5-9-2.5s-7 .8-9.2 2.5a8.5 8.5 0 0 0-3.2 7 8.4 8.4 0 0 0 3.3 7.2 15 15 0 0 0 9 2.5Zm0-30.3q5 0 7.8-2.3a7.4 7.4 0 0 0 2.9-6.1 7 7 0 0 0-2.8-6 13 13 0 0 0-8-2q-10.7 0-10.7 8a7.4 7.4 0 0 0 2.8 6.2q2.8 2.1 8 2.1Z"
    />
  </svg>
);

export default SvgWindBeaufort8;
