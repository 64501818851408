import TimerComponent from "../Timer";
import { AccessAlarms } from "@mui/icons-material";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import eventDispatcher, { CustomEvents } from "eventDispatcher";


export default function FloatingTimer({ isMinimized }: Readonly<{ isMinimized: boolean }>) {
  const [startTime, setStartTime] = useState<Date>();
  const [endTime, setEndTime] = useState<Date>();

  useEffect(() => {
    eventDispatcher.on(CustomEvents.SetTimer, (startTime: Date, endTime?: Date) => {
      setStartTime(startTime);
      setEndTime(endTime);
    });

    eventDispatcher.on(CustomEvents.StopTimer, () => {
      setStartTime(undefined);
      setEndTime(undefined);
    });

    return () => {
      // cleanup
    };
  }, []);

  if (!startTime)
    return <></>;

  return (
    <div className={`${style.timer} ${style.floating} ${isMinimized ? style.isMinimized : ""}`}>
      <AccessAlarms className={style.stopwatchIcon} />
      <p>
        <TimerComponent startTime={startTime} endTime={endTime} />
      </p>
    </div>
  );
}