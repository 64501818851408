// Import necessary types from mapbox-gl
import mapboxgl from "mapbox-gl";

/**
 * Extends the mapboxgl.Marker class to add a click handler
 * @param element - The HTML element to use as a marker.
 * @param options - Options to use.
 * @returns A new ClickableMarker instance.
 * @remarks Based on: https://gist.github.com/chriswhong/8977c0d4e869e9eaf06b4e9fda80f3ab
 */
export class ClickableMarker extends mapboxgl.Marker {
  private _handleClick: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void;

  constructor(element?: HTMLElement | undefined, options?: mapboxgl.MarkerOptions) {
    super(element, options);
    this._handleClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      // Not implemented
    };
    this._onMapClick = this._onMapClick.bind(this);
  }

  /**
   * Adds the marker to the map and adds a click handler. 
   */
  onClick(handleClick: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void): this {
    this._handleClick = handleClick;
    return this;
  }

  _onMapClick(e: mapboxgl.MapMouseEvent & mapboxgl.EventData): void {
    const targetElement = e.originalEvent.target as HTMLElement;
    const element = this.getElement() ;

    if (this._handleClick && (targetElement === element || element.contains(targetElement))) {
      this._handleClick(e);
    }
  }
}