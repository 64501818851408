export function parseWithDate(key: string, value: any): unknown {
  // const reDateDetect = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?$/;

  // const parsedDate = Date.parse(value);
  // const isDate = isNaN(value) && !isNaN(parsedDate);
  

  // if (typeof value == "string" && isDate && key ) {
  //   return new Date(value);
  // }
  return value;
}

export function GetDate(value :string) : Date{
  const parsedDate = Date.parse(value);
  const isDate = !isNaN(parsedDate);

  if (typeof value == "string" && isDate) {
    return new Date(value);
  }
  throw "Date not parseable";
}

export function ValidateDate(value :Date) : Date{
  if (typeof value == "string") {
    const stringvalue = value as string;
    const parsedDate = Date.parse(stringvalue);
    const isDate = !isNaN(parsedDate);

    if (isDate)
      return new Date(stringvalue);
  }
  if (typeof value.getMonth === "function")
    return value; 

  throw `Date is not valid for value:${value}`;
}
