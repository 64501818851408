import { MapContext, useMap } from "app/contexts/mapContext";
import { useCallback, useContext } from "react";

const satelliteLayerId = "satellite-layer";
const satelliteLayerSourceId = "satellite-layer-source";
const satelliteTileUrl = "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_ortho25/EPSG:3857/{z}/{x}/{y}.jpeg";

/**
 * Component that adds a satellite layer to the map
 * @returns 
 */
export function SatelliteLayer() {
  const map = useContext(MapContext);

  /**
   * Add satellite layer to the map
   * @param map  Mapbox map
   * @returns void
   */
  const removeLayer = useCallback(() => {
    if (!map)
      return;

    if (!map.getLayer(satelliteLayerId))
      return;

    map.removeLayer(satelliteLayerId);
  }, []);

  const addLayer = useCallback(() => {
    if (!map)
      return;

    if (!map.getSource(satelliteLayerSourceId)) {
      map.addSource(satelliteLayerSourceId, {
        type: "raster",
        tiles: [satelliteTileUrl]
      });
    }

    if (!map.getLayer(satelliteLayerId))
      map.addLayer({
        id: satelliteLayerId,
        type: "raster",
        source: satelliteLayerSourceId
      }, "admin-0-boundary");

  }, []);

  /**
   * Add satellite layer to the map
   */
  useMap((map) => {
    addLayer();
    map.on("styledata", addLayer);

    return () => {
      map.off("styledata", addLayer);
      removeLayer();
    };
  }, []);

  return <></>;
}