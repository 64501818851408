import {useEffect, useRef} from "react";
import NoSleep from "@zakj/no-sleep";
import {Map} from "mapbox-gl";

interface State{
  isEnabled: boolean;
  registeredEvents: string[]
}

/**
 * Hook to enable no sleep on the device
 * @param map
 */
export function useNoSleep(map: Map | null){
  const noSleep = useRef(new NoSleep());
  const state = useRef<State>({
    isEnabled: false,
    registeredEvents: []
  });

  /**
   * Register events on document
   * @remarks Registering the enabling of noSleep on an event is required. If noSleep is enabled without user interaction the browser will block it.
   */
  useEffect(() => {
    if(state.current.registeredEvents.includes("document.click") && state.current.registeredEvents.includes("document.touchstart"))
      return;

    console.debug("[useNoSleep] setting enableNoSleep on click/touchstart event on document");

    document.addEventListener("click", () => enableNoSleep("document.click"), false);
    document.addEventListener("touchstart", () => enableNoSleep("document.touchstart"), false);
  }, []);

  /**
   * Register events on map
   * @remarks Registering the enabling of noSleep on an event is required. The Mapbox.Map will override the touchstart/touchend of a document. If noSleep is enabled without user interaction the browser will block it.
   */
  useEffect(() => {
    if(state.current.registeredEvents.includes("map.touchstart"))
      return;

    console.debug("[useNoSleep] setting enableNoSleep on touchstart event on map");

    map?.on("touchstart", () => enableNoSleep("map.touchstart"));
  }, [map]);

  /**
   * Enable no sleep
   */
  function enableNoSleep(event: string){
    if(state.current.isEnabled)
      return;

    console.debug("[useNoSleep] enabling no sleep on", event);

    noSleep.current.enable();

    state.current.isEnabled = true;
    state.current.registeredEvents.push(event);
  }
}