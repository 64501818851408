import { UserSettingsContext } from "app/contexts/userContext";
import { IncidentOpenFocus } from "./enums/incidentOpenFocus";
import { IncidentPriorityFilter } from "./enums/incidentPriorityFilter";
import { IncidentTypeFilter } from "./enums/incidentTypeFilter";
import { IncidentFilter } from "./incidentFilter";

/**
 * Object containing the settings for an user.
 */
export interface UserSettings {
  myEenheden: string;
  myPost: string;
  calculateRoute: boolean;
  autoOpenNewIncidentOnEenheid: boolean;
  autoOpenNewIncidentOnPost: boolean;
  incidentOpenFocus: IncidentOpenFocus;
  selectedLayers?: string[];
  incidentFilter: IncidentFilter;
}

/**
 * Default UserSettings that is used when their aren't any user settings defined in the backend.
 */
export const initialUserSettingsContext: UserSettingsContext = {
  userSettings: {
    myEenheden: "",
    myPost: "",
    calculateRoute: false,
    autoOpenNewIncidentOnEenheid: false,
    autoOpenNewIncidentOnPost: false,
    incidentOpenFocus: 0,
    selectedLayers: [],
    incidentFilter: {
      type: IncidentTypeFilter.Active,
      priority: IncidentPriorityFilter.HighPriority
    },
  },
  selectedLayers: {}
};
