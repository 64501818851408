import * as React from "react";
import { SVGProps } from "react";

const SvgPartlyCloudyNightSnow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <symbol id="partly-cloudy-night-snow_svg__b" viewBox="0 0 178 178">
        <path
          fill="none"
          stroke="#72b9d5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={10}
          d="M163.6 110.4a84.8 84.8 0 0 1-85.4-84.3A83.3 83.3 0 0 1 81 5 84.7 84.7 0 0 0 5 88.7 84.8 84.8 0 0 0 90.4 173a85.2 85.2 0 0 0 82.6-63.1 88 88 0 0 1-9.4.5Z"
        />
      </symbol>
      <symbol id="partly-cloudy-night-snow_svg__c" viewBox="0 0 359 231">
        <path
          fill="none"
          stroke="#e2e8f0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={15}
          d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2 56 56 0 0 0-84.6 48.1 56.6 56.6 0 0 0 .8 9 60 60 0 0 0 11.2 119"
        />
      </symbol>
      <symbol id="partly-cloudy-night-snow_svg__d" viewBox="0 0 435.5 371.5">
        <g clipPath="url(#partly-cloudy-night-snow_svg__a)">
          <use
            xlinkHref="#partly-cloudy-night-snow_svg__b"
            width={178}
            height={178}
            transform="translate(77 118)"
          />
        </g>
        <use
          xlinkHref="#partly-cloudy-night-snow_svg__c"
          width={359}
          height={231}
          transform="translate(76.5 140.5)"
        />
      </symbol>
      <symbol id="partly-cloudy-night-snow_svg__e" viewBox="0 0 155.2 138">
        <path
          fill="#86c3db"
          d="m41.2 30.5-5.8-3.3a13.7 13.7 0 0 0 0-6.4l5.8-3.3a4 4 0 0 0 1.5-5.5 4 4 0 0 0-5.6-1.5l-5.8 3.3a13.6 13.6 0 0 0-2.6-2 13.8 13.8 0 0 0-3-1.2V4a4 4 0 0 0-8.1 0v6.6a14.3 14.3 0 0 0-5.7 3.2l-5.8-3.3A4 4 0 0 0 .5 12 4 4 0 0 0 2 17.5l5.8 3.3a13.7 13.7 0 0 0 0 6.4L2 30.5A4 4 0 0 0 .5 36a4 4 0 0 0 3.6 2 4 4 0 0 0 2-.5l5.8-3.3a13.6 13.6 0 0 0 2.6 2 13.8 13.8 0 0 0 3 1.2V44a4 4 0 0 0 8 0v-6.6a14.2 14.2 0 0 0 5.8-3.2l5.8 3.3a4 4 0 0 0 2 .5 4 4 0 0 0 3.5-2 4 4 0 0 0-1.4-5.5Zm-22.6-1.3a6 6 0 0 1-2.3-8.2 6.1 6.1 0 0 1 5.3-3 6.2 6.2 0 0 1 3 .8A6 6 0 0 1 27 27a6.1 6.1 0 0 1-8.3 2.2Zm78.6 91.3-5.8-3.3a13.7 13.7 0 0 0 0-6.4l5.8-3.3a4 4 0 0 0 1.5-5.5 4 4 0 0 0-5.6-1.5l-5.8 3.3a13.6 13.6 0 0 0-2.6-2 13.8 13.8 0 0 0-3-1.2V94a4 4 0 0 0-8.1 0v6.6a14.3 14.3 0 0 0-5.7 3.2l-5.8-3.3a4 4 0 0 0-5.6 1.5 4 4 0 0 0 1.5 5.5l5.8 3.3a13.7 13.7 0 0 0 0 6.4l-5.8 3.3a4 4 0 0 0-1.5 5.5 4 4 0 0 0 3.6 2 4 4 0 0 0 2-.5l5.8-3.3a13.6 13.6 0 0 0 2.6 2 13.8 13.8 0 0 0 3 1.2v6.6a4 4 0 0 0 8 0v-6.6a14.2 14.2 0 0 0 5.8-3.2l5.8 3.3a4 4 0 0 0 2 .5 4 4 0 0 0 3.5-2 4 4 0 0 0-1.4-5.5Zm-22.6-1.3a6 6 0 0 1-2.3-8.2 6.1 6.1 0 0 1 5.3-3 6.2 6.2 0 0 1 3 .8 6 6 0 0 1 2.2 8.2 6.1 6.1 0 0 1-8.2 2.2Zm78.6-48.7-5.8-3.3a13.7 13.7 0 0 0 0-6.4l5.8-3.3a4 4 0 0 0 1.5-5.5 4 4 0 0 0-5.6-1.5l-5.8 3.3a13.6 13.6 0 0 0-2.6-2 13.8 13.8 0 0 0-3-1.2V44a4 4 0 0 0-8.1 0v6.6a14.3 14.3 0 0 0-5.7 3.2l-5.8-3.3a4 4 0 0 0-5.6 1.5 4 4 0 0 0 1.5 5.5l5.8 3.3a13.7 13.7 0 0 0 0 6.4l-5.8 3.3a4 4 0 0 0-1.5 5.5 4 4 0 0 0 3.6 2 4 4 0 0 0 2-.5l5.8-3.3a13.6 13.6 0 0 0 2.6 2 13.8 13.8 0 0 0 3 1.2V84a4 4 0 0 0 8 0v-6.6a14.2 14.2 0 0 0 5.8-3.2l5.8 3.3a4 4 0 0 0 2 .5 4 4 0 0 0 3.5-2 4 4 0 0 0-1.4-5.5Zm-22.6-1.3a6 6 0 0 1-2.3-8.2 6.1 6.1 0 0 1 5.3-3 6.2 6.2 0 0 1 3 .8 6 6 0 0 1 2.2 8.2 6.1 6.1 0 0 1-8.2 2.2Z"
        />
      </symbol>
      <clipPath id="partly-cloudy-night-snow_svg__a">
        <path
          fill="none"
          d="M288 148a83.8 83.8 0 0 0-71.4 40 56 56 0 0 0-84.6 48 56.6 56.6 0 0 0 .8 9A60 60 0 0 0 84 304H0V0h288Z"
        />
      </clipPath>
    </defs>
    <use
      xlinkHref="#partly-cloudy-night-snow_svg__d"
      width={435.5}
      height={371.5}
    />
    <use
      xlinkHref="#partly-cloudy-night-snow_svg__e"
      width={155.2}
      height={138}
      transform="translate(178.4 338)"
    />
  </svg>
);

export default SvgPartlyCloudyNightSnow;
